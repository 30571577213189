import React from "react";
import { Col, Modal, Row } from "react-bootstrap";
import { crose } from "../../assets/images/index";
import {
  EmailIcon,
  FacebookIcon,
  FacebookMessengerIcon,
  HatenaIcon,
  InstapaperIcon,
  LineIcon,
  LinkedinIcon,
  LivejournalIcon,
  MailruIcon,
  OKIcon,
  PinterestIcon,
  PocketIcon,
  RedditIcon,
  TelegramIcon,
  TumblrIcon,
  TwitterIcon,
  ViberIcon,
  VKIcon,
  WeiboIcon,
  WhatsappIcon,
  WorkplaceIcon,
  FacebookShareButton,
  TwitterShareButton,
  EmailShareButton,
  FacebookMessengerShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
} from "react-share";
import { useTranslation } from "react-i18next";

function SharePostModal({ show, onHide, PRODUCT_DETAIL, type }) {
  const { t } = useTranslation();
  let title = "";
  if (type === "INVITE") {
    title = t("Invite Friends");
  } else {
    title = t("SHARE_POST");
  }

  const shareUrl = PRODUCT_DETAIL?.shareLink;
  return (
    <div>
      <Modal show={show} onHide={() => false} className="change_passsord_modal">
        <Modal.Header>
          <h4 className="mb-0">{title}</h4>
          <button className="crose_btn" onClick={() => onHide()}>
            <img src={crose} alt="No_IMAGE" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              marginTop: "2rem",
              marginBottom: "2rem",
            }}
          >
            <Row>
              <Col lg={2} className="mb-2 mt-2">
                <FacebookShareButton url={shareUrl}>
                  <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
              </Col>
              <Col lg={2} className="mb-2 mt-2">
                <TwitterShareButton url={shareUrl}>
                  <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
              </Col>
              <Col lg={2} className="mb-2 mt-2">
                <EmailShareButton url={shareUrl}>
                  <EmailIcon size={32} round={true} />
                </EmailShareButton>
              </Col>{" "}
              <Col lg={2} className="mb-2 mt-2">
                <FacebookMessengerShareButton url={shareUrl}>
                  <FacebookMessengerIcon size={32} round={true} />
                </FacebookMessengerShareButton>
              </Col>{" "}
              <Col lg={2} className="mb-2 mt-2">
                <WhatsappShareButton url={shareUrl}>
                  <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
              </Col>{" "}
              <Col lg={2} className="mb-2 mt-2">
                <LinkedinShareButton url={shareUrl}>
                  <LinkedinIcon size={32} round={true} />
                </LinkedinShareButton>
              </Col>{" "}
            </Row>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SharePostModal;
