import { React, useEffect } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import Rating from "./../../Component/StarRating";
import {
  Heart_Fill,
  Heart,
  PhoneBadge,
  DofoBadge,
  EmailBadge,
  FacebookBadge,
  prd1,
  brnd_SVG,
} from "../../assets/images/index";
import {
  fetchUserProfileData,
  fetchPublicProfile,
} from "../../actions/userProfileAction";
const MyProfile = () => {
  const { t } = useTranslation();
  const { userProfileInfo, publicProfile } = useSelector((state) => ({
    userProfileInfo: state.userProfileInfo,
    publicProfile: state.publicProfile,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = () => {
      dispatch(fetchUserProfileData());
    };
    const fetchProfileData = () => {
      dispatch(fetchPublicProfile(userProfileInfo.userProfileInfo._id));
    };
    fetchData();
    fetchProfileData();
  }, [dispatch, userProfileInfo.userProfileInfo._id]);

  const activepostslider = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };
  // function dashsidebar() {
  //     document.getElementById("onscroll").classList.toggle("dashboard_sidebar");
  // }
  console.log(publicProfile.image, "testing");
  return (
    <>
      {undefined !== userProfileInfo.userProfileInfo &&
      userProfileInfo.userProfileInfo.length !== 0 ? (
        <div className="outline_wrapper">
          <h2>{t("PUBLIC_PROFILE")}</h2>
          <div className="profile_heading">
            <figure>
              <img
                src={undefined !== publicProfile && publicProfile.image}
                alt="user profile"
              />
            </figure>
            <div className="rating_star text-center">
              <h2>
                {userProfileInfo.userProfileInfo.first_name +
                  " " +
                  userProfileInfo.userProfileInfo.last_name}
              </h2>
              <p>{t("DOFFO_MEMBER")}</p>
              <div className="user_rating">
                <Rating rating={userProfileInfo.userProfileInfo.rating} />
                <small>
                  (
                  {userProfileInfo.userProfileInfo.totalRating > 200
                    ? `${userProfileInfo.userProfileInfo.totalRating} + Ratings`
                    : `${userProfileInfo.userProfileInfo.totalRating} Ratings`}
                  )
                </small>
              </div>
            </div>

            <div className="profile_item_rate">
              <ul>
                <li>
                  <h4>25</h4>
                  <span>{t("SOLD_ITEMS")}</span>
                </li>
                <li>
                  <h4>25</h4>
                  <span>{t("BROUGHT_ITEMS")}</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="badges_sec">
            <h2 className="profile_inner_heading">{t("MY_BADGES")}</h2>
            <ul>
              <li>
                <figure>
                  <img src={PhoneBadge} alt="phone icon" />
                </figure>{" "}
                {userProfileInfo.userProfileInfo.otpVerify ? (
                  <span>Phone Number Verified</span>
                ) : (
                  <span>Verification Pending</span>
                )}
              </li>
              <li>
                <figure>
                  <img src={DofoBadge} alt="dofo icon" />
                </figure>{" "}
                {userProfileInfo.userProfileInfo.doffoStatus ? (
                  <span>DOFFO Verified</span>
                ) : (
                  <span>Verification Pending</span>
                )}
              </li>
              <li>
                <figure>
                  <img src={EmailBadge} alt="email icon" />
                </figure>
                {userProfileInfo.userProfileInfo.emailVerify ? (
                  <span>Email Verified</span>
                ) : (
                  <span>Verification Pending</span>
                )}
              </li>
              <li>
                <figure>
                  <img src={FacebookBadge} alt="facebook icon" />
                </figure>
                {userProfileInfo.userProfileInfo.fbStatus ? (
                  <span>Facebook Verified</span>
                ) : (
                  <span>Verification Pending</span>
                )}
              </li>
            </ul>
          </div>

          <div className="profile_feedback">
            <h2 className="profile_inner_heading text-center">
              {t("FEEDBACK")}
            </h2>
            <ul>
              <li>
                <span>14</span>
                {t("COMMUNICATIVE")}
              </li>
              <li>
                <span>54</span>
                {t("COMMUNICATIVE")}
              </li>
              <li>
                <span>74</span>
                {t("FRIENDLY")}
              </li>
              <li>
                <span>4</span>
                {t("ON_TIME")}
              </li>
            </ul>
          </div>

          <div className="active_post_slider product_outer_main">
            <h2>{t("ACTIVE_POST")}</h2>
            <Slider {...activepostslider}>
              <div className="product_card">
                <figure>
                  <img src={prd1} alt="prod1" />
                </figure>
                <div className="overlay_top">
                  <span className="boost_badge">{t("DOFFO_BOOST")}</span>
                  <span className="heart">
                    <img src={Heart} alt="heart" />
                  </span>
                </div>

                <div className="overlay_bottom">
                  <h4>
                    <a href="#!">Samsung mobile</a>
                  </h4>
                  <div className="product_info">
                    <span className="price">15.24 SR</span>
                    <span className="brand">
                      <img src={brnd_SVG} alt="brand" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="product_card">
                <figure>
                  <img src={prd1} alt="product" />
                </figure>
                <div className="overlay_top">
                  <span className="sold_badge">Sold</span>
                  <span className="heart">
                    <img src={Heart_Fill} alt="Heart Fill" />
                  </span>
                </div>

                <div className="overlay_bottom">
                  <h4>
                    <a href="#!">Rolex watch</a>
                  </h4>
                  <div className="product_info">
                    <span className="price">15.24 SR</span>
                    <span className="brand">
                      <img src={brnd_SVG} alt="brand" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="product_card">
                <figure>
                  <img src={prd1} alt="brand" />
                </figure>
                <div className="overlay_top">
                  <span className="add_badge">Ad</span>
                  <span className="heart">
                    <img src={Heart} alt="Heart" />
                  </span>
                </div>

                <div className="overlay_bottom">
                  <h4>
                    <a href="#!">Peter england suit</a>
                  </h4>
                  <div className="product_info">
                    <span className="price">15.24 SR</span>
                    <span className="brand">
                      <img src={brnd_SVG} alt="brand" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="product_card">
                <figure>
                  <img src={prd1} alt="brand" />
                </figure>
                <div className="overlay_top">
                  <span className="heart">
                    <img src={Heart} alt="Heart" />
                  </span>
                </div>

                <div className="overlay_bottom">
                  <h4>
                    <a href="#!">iPhone 6s</a>
                  </h4>
                  <div className="product_info">
                    <span className="price">15.24 SR</span>
                    <span className="brand">
                      <img src={brnd_SVG} alt="brand" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="product_card">
                <figure>
                  <img src={prd1} alt="brand" />
                </figure>
                <div className="overlay_top">
                  <span className="heart">
                    <img src={Heart} alt="Heart" />
                  </span>
                </div>

                <div className="overlay_bottom">
                  <h4>
                    <a href="#!">Samsung mobile</a>
                  </h4>
                  <div className="product_info">
                    <span className="price">15.24 SR</span>
                    <span className="brand">
                      <img src={brnd_SVG} alt="brand" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="product_card">
                <figure>
                  <img src={prd1} alt="brand" />
                </figure>
                <div className="overlay_top">
                  <span className="heart">
                    <img src={Heart} alt="Heart" />
                  </span>
                </div>

                <div className="overlay_bottom">
                  <h4>
                    <a href="#!">Samsung mobile</a>
                  </h4>
                  <div className="product_info">
                    <span className="price">15.24 SR</span>
                    <span className="brand">
                      <img src={brnd_SVG} alt="brand" />
                    </span>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default MyProfile;
