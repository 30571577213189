import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function loginPopup(state = initialState.loginPopup, action) {
  switch (action.type) {
    case types.LOADED_LOGIN_POPUP:
      return action.loginPopup;
    default:
      return state;
  }
}
