import { React, useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import processingImg from "../../../assets/images/Processing.png";
import onRouteImg from "../../../assets/images/on_route.png";
import deliveredImg from "../../../assets/images/Delivered.png";
import productImg from "../../../assets/images/prd10.jpg";
import proFeesIcon from "../../../assets/images/pro_fees_icon.svg";
import cardDebitImg from "../../../assets/images/card_dabit.svg";
import {
  getOrderDetail,
  ratingFeedbackList,
  ratingSubmitData,
} from "../../../actions/SIdebarAction";
import { useTranslation } from "react-i18next";
import { TextFormate } from "../../../Component/TextFormate/TexFormate";
import {
  history1,
  proFees,
  crose,
  rate_graphic,
} from "../../../assets/images/index";
import { loadDialog, loadToasterData } from "../../../actions/baseActions";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";

function OrderTracking() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const orderId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  // All Selectors
  const { selectedLang, ORDER_LIST } = useSelector((state) => ({
    selectedLang: state.selectedLang,
    ORDER_LIST: state.sidebarReducer.orderTrackingData,
  }));

  useEffect(() => {
    const postJson = { id: orderId };
    const fetchOrderTracting = () => {
      dispatch(getOrderDetail(postJson));
    };
    fetchOrderTracting();
  }, []);
  console.log(ORDER_LIST);
  /* Rating*/
  // Rating Modal
  const [show6, setShow6] = useState(false);
  const handleClose6 = () => setShow6(false);

  //  const handleShow6 = () => setShow6(true);

  useEffect(() => {
    if (ORDER_LIST.review === false && ORDER_LIST.status === "Delivered") {
      const request = { order_id: ORDER_LIST._id };
      dispatch(ratingFeedbackList(request, returnDataFeedback));
      setShow6(true);
    }
  }, [dispatch, ORDER_LIST]);
  const [getFeedbackList, setFeedbackList] = useState("");
  const returnDataFeedback = (data) => {
    setFeedbackList(data);
  };
  console.log(getFeedbackList);
  /* Rating*/
  const [valueRating, setValueRating] = useState(2);

  const [getSelectedFeedback, setSelectedFeedback] = useState([]);
  const setCheckFeedback = (e, data) => {
    var checkId = userExists(data);
    if (checkId) {
      setSelectedFeedback(
        getSelectedFeedback.filter((item) => item.id !== data._id)
      );
    } else {
      setSelectedFeedback([
        ...getSelectedFeedback,
        {
          id: data._id,
        },
      ]);
    }
  };
  function userExists(data) {
    return getSelectedFeedback.some(function (el) {
      return el.id === data._id;
    });
  }
  //console.log(getSelectedFeedback);
  const defaultValues = (data) => {
    const index = getSelectedFeedback.findIndex((object) => object.id === data);
    if (index === -1) {
      return "";
    } else {
      return "selected-feedback";
    }
  };
  const handleSubmitRating = () => {
    if (valueRating === null) {
      dispatch(loadToasterData(t("PLEASE_SELECT_RATING"), "error", true));
    } else if (getSelectedFeedback.length <= 0) {
      dispatch(loadToasterData(t("PLEASE_SELECT_FEEDBACK"), "error", true));
    } else {
      var dataArray = [];
      for (var x = 0; x < getSelectedFeedback.length; x++) {
        dataArray.push(getSelectedFeedback[x].id);
      }
      setShow6(false);
      var rating = valueRating;
      var feedback_id = dataArray;
      var order_id = orderId;
      var seller_id = ORDER_LIST.seller_id;
      const request = { rating, feedback_id, order_id, seller_id };

      dispatch(ratingSubmitData(request));
      setTimeout(function () {
        //  history.push(`/manage`);
        window.location.reload();
      }, 1000);
      /* const postJson = { id: orderId };
      const fetchOrderTracting = () => {
        dispatch(getOrderDetail(postJson));
      };
      fetchOrderTracting();*/
    }
  };
  const handleShow6 = () => setShow6(true);
  return (
    <section className="confirm_order_section">
      <Container>
        <div className="breadcrumb">
          <ul>
            <li>
              <Link to={`${"/" + selectedLang}/`}>
                {t("HOME")}
                <i className="fas fa-chevron-right"></i>
              </Link>
            </li>
            <li>
              <Link to={`${"/" + selectedLang}/transaction-history`}>
                {t("PURCHASE_AND_SALE_HISTORY")}
                <i className="fas fa-chevron-right"></i>
              </Link>
            </li>
            <li>{t("ORDER_DETAIL")}</li>
          </ul>
        </div>
        <div className="confirm_order_wrapper">
          <Row>
            <Col lg={9}>
              <div className="outline_wrapper">
                <div className="order_tracking_info_list">
                  <div className="tracking_head">
                    <h2>{t("ORDER_TRACKING")}</h2>
                    <div className="status">
                      {t("STATUS")}:<span> {ORDER_LIST.status} </span>
                    </div>
                  </div>
                </div>
                <div className="processing_steps">
                  <ul>
                    <li
                      className={
                        ORDER_LIST.status == "On Processing" ? "active" : ""
                      }
                    >
                      <figure>
                        <img
                          // src="./assets/images/Processing.png"
                          src={processingImg}
                          alt="NO_IMAGE"
                        />
                      </figure>
                      <span>{t("PROCESSING")}</span>
                    </li>

                    <li
                      className={
                        ORDER_LIST.status == "On Route" ? "active" : ""
                      }
                    >
                      <figure>
                        <img
                          // src="./assets/images/on_route.png"
                          src={onRouteImg}
                          alt="NO_IMAGE"
                        />
                      </figure>
                      <span>{t("ON_ROUTE")}</span>
                    </li>

                    <li
                      className={
                        ORDER_LIST.status == "Delivered" ? "active" : ""
                      }
                    >
                      <figure>
                        <img
                          // src="./assets/images/Delivered.png"
                          src={deliveredImg}
                          alt="NO_IMAGE"
                        />
                      </figure>
                      <span>{t("DELIVERED")}</span>
                    </li>
                  </ul>
                </div>
                <div
                  className="product_item_horizantal"
                  style={{ borderTop: "1px solid #e2e2e2" }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{ paddingTop: "25px" }}
                  >
                    <figure>
                      <img
                        //   src="./assets/images/prd10.jpg"
                        src={ORDER_LIST?.item?.users.image}
                        alt="NO_IMAGE"
                        style={{
                          height: "100px",
                          width: "100px",
                          borderRadius: "50%",
                          border: "2px solid #E2E2E2",
                        }}
                      />
                    </figure>
                    <div className="offer_product_detail">
                      <h3>
                        {ORDER_LIST?.item?.users.first_name +
                          " " +
                          ORDER_LIST?.item?.users?.last_name}
                      </h3>
                    </div>
                  </div>
                </div>
                <div className="list_outer">
                  <ul>
                    <li>
                      {t("ORDER_NUMBER")}:
                      <span> {ORDER_LIST?.order_number}</span>
                    </li>
                    <li>
                      {t("ORDER_POST_NUMBER")}:
                      <span> {ORDER_LIST?.order_number}</span>
                    </li>

                    <li>
                      {t("ORDER_STATUS")}:&nbsp;
                      <span>{ORDER_LIST?.status}</span>
                    </li>
                    <li>
                      {t("ORDER_DATE")}:<span> {ORDER_LIST?.orderDate}</span>
                    </li>
                    <li>
                      {t("SHIPPING_DATE")}:<span> {ORDER_LIST?.orderDate}</span>
                    </li>
                    <li>
                      {t("DELIVERY_DATE")}:<span>{ORDER_LIST?.orderDate}</span>
                    </li>
                  </ul>
                </div>
                {ORDER_LIST?.shipping && (
                  <Row>
                    <Col sm={6}>
                      <div className="order_detail_box">
                        <div className="order_detail_head">
                          <h4>{t("SHIPPING_FROM")}</h4>
                        </div>

                        <div className="order_caption">
                          <strong>
                            {ORDER_LIST?.sellerData?.first_name +
                              " " +
                              ORDER_LIST?.sellerData?.last_name}
                          </strong>
                          <strong style={{ float: "right" }}>
                            {ORDER_LIST?.sellerData?.mobile_number}
                          </strong>
                          <p>
                            {`${
                              ORDER_LIST?.shipping_from?.address &&
                              ORDER_LIST?.shipping_from?.address + ","
                            } ${
                              ORDER_LIST?.shipping_from?.building &&
                              ORDER_LIST?.shipping_from?.building + ","
                            } ${
                              ORDER_LIST?.shipping_from?.street_name &&
                              ORDER_LIST?.shipping_from?.street_name + ","
                            } ${
                              ORDER_LIST?.shipping_from?.district &&
                              ORDER_LIST?.shipping_from?.district + ","
                            } ${
                              ORDER_LIST?.shipping_from?.postal_code &&
                              ORDER_LIST?.shipping_from?.postal_code + ","
                            } ${ORDER_LIST?.shipping_from?.city} `}
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="order_detail_box">
                        <div className="order_detail_head">
                          <h4>{t("SHIPPING_TO")}</h4>
                        </div>

                        <div className="order_caption">
                          <strong>
                            {ORDER_LIST?.buyerData?.first_name +
                              " " +
                              ORDER_LIST?.buyerData?.last_name}
                          </strong>{" "}
                          <strong style={{ float: "right" }}>
                            {ORDER_LIST?.buyerData?.mobile_number}
                          </strong>
                          <p>
                            {`${
                              ORDER_LIST?.shipping_to?.address &&
                              ORDER_LIST?.shipping_to?.address + ","
                            } ${
                              ORDER_LIST?.shipping_to?.building &&
                              ORDER_LIST?.shipping_to?.building + ","
                            } ${
                              ORDER_LIST?.shipping_to?.street_name &&
                              ORDER_LIST?.shipping_to?.street_name + ","
                            } ${
                              ORDER_LIST?.shipping_to?.district &&
                              ORDER_LIST?.shipping_to?.district + ","
                            } ${
                              ORDER_LIST?.shipping_to?.postal_code &&
                              ORDER_LIST?.shipping_to?.postal_code + ","
                            } ${ORDER_LIST?.shipping_to?.city} `}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                )}
                <div className="confirm_order_info">
                  <div className="product_item_horizantal">
                    <div className="d-flex align-items-center">
                      <figure>
                        <img
                          //   src="./assets/images/prd10.jpg"
                          src={ORDER_LIST?.item?.image}
                          alt="NO_IMAGE"
                          style={{
                            height: "100px",
                            width: "100px",
                            borderRadius: "8px",
                            border: "2px solid #E2E2E2",
                          }}
                        />
                      </figure>
                      <div className="offer_product_detail">
                        <h3>{ORDER_LIST?.item?.title}</h3>
                        <small>{t("ITEM_PRICE")}</small>
                        <span className="price">
                          {TextFormate(ORDER_LIST?.price, 2)} {t("SR")}
                          {/* <span className="off_price">28.99</span> */}
                        </span>
                      </div>
                    </div>
                    <div className="order_payment_method">
                      <span>{t("PAYMENT_METHODS")}</span>
                      <small className="d-block">
                        <img
                          src={ORDER_LIST?.payment_type?.cardBrand}
                          alt="NO_Image"
                          style={{ height: "20px", width: "30px" }}
                        />
                        {ORDER_LIST?.payment_type?.maskedCardNo}
                      </small>
                    </div>
                  </div>
                  <div className="listing_brands_new">
                    <div className="listing_brand_cat">
                      <ul>
                        <li>
                          {t("ITEM_CONDITION")}:
                          <span>{ORDER_LIST?.item?.condition}</span>
                        </li>
                        <li>
                          {t("CATEGORY")}:
                          <span>{ORDER_LIST?.item?.category}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="listing_brand_cat">
                    <ul>
                      {ORDER_LIST?.item?.brand && (
                        <li>
                          {t("BRAND")}:<span>{ORDER_LIST?.item?.brand}</span>
                        </li>
                      )}
                      {ORDER_LIST?.item?.model && (
                        <li>
                          {t("MODAL")}:<span>{ORDER_LIST?.item?.model}</span>
                        </li>
                      )}
                    </ul>
                  </div>
                  <div className="listing_brand_cat">
                    <ul className="d-flex flex-wrap point-list">
                      {ORDER_LIST?.item?.item_information?.weight && (
                        <li>
                          {t("WEIGHT")}:&nbsp;
                          <span>
                            {ORDER_LIST?.item?.item_information?.weight &&
                              ORDER_LIST?.item?.item_information?.weight +
                                " gm"}
                          </span>
                        </li>
                      )}
                      {ORDER_LIST?.item?.item_information?.height && (
                        <li>
                          {t("HEIGHT")}:&nbsp;
                          <span>
                            {ORDER_LIST?.item?.item_information?.height &&
                              ORDER_LIST?.item?.item_information?.height +
                                " cm"}
                          </span>
                        </li>
                      )}

                      {ORDER_LIST?.item?.item_information?.length && (
                        <li>
                          {t("LENGTH")}:&nbsp;
                          <span>
                            {ORDER_LIST?.item?.item_information?.length &&
                              ORDER_LIST?.item?.item_information?.length +
                                " cm"}
                          </span>
                        </li>
                      )}

                      {ORDER_LIST?.item?.item_information?.width && (
                        <li>
                          {t("WIDTH")}:&nbsp;
                          <span>
                            {ORDER_LIST?.item?.item_information?.width &&
                              ORDER_LIST?.item?.item_information?.width + " cm"}
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>

                  <div className="listing_condition">
                    <div className="description">
                      <h3 className="seller_titles">{t("DESCRIPTION")}</h3>
                      <p>{ORDER_LIST?.item?.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col lg={3}>
              <div className="order_summary_sidebar">
                <h3>{t("ORDER_SUMMARY")}</h3>
                <ul className="order_summary_list">
                  <li>
                    <span>{t("ITEM_PRICE")}:</span>
                    <strong>
                      {TextFormate(ORDER_LIST?.price, 2)} {t("SR")}
                    </strong>
                  </li>
                  {ORDER_LIST?.discount_price > 0 && (
                    <li>
                      <span>
                        {t("DISCOUNT")}
                        <small>({t("COUPON_APPLIED")})</small>
                      </span>
                      <strong>
                        {TextFormate(ORDER_LIST?.discount_price, 2)} {t("SR")}
                      </strong>
                    </li>
                  )}
                  <li>
                    <span>{t("SHIPPING_FEE")}:</span>
                    <strong>
                      {TextFormate(ORDER_LIST?.shipping_fee, 2)} {t("SR")}
                    </strong>
                  </li>
                  <li>
                    <span>
                      {ORDER_LIST?.shipping
                        ? t("PROCESSING_FEE")
                        : t("PROCESSING_FEE")}
                      <span className="d-inline-block px-2">
                        <img src={proFeesIcon} alt="NO_IMAGE" />
                      </span>
                    </span>
                    <strong>
                      {TextFormate(ORDER_LIST?.Processing_fee, 2)} {t("SR")}
                    </strong>
                  </li>
                </ul>

                <div className="final_price">
                  <div className="final_costing_head">
                    <strong>{t("TOTAL_PAYOUT")}</strong>
                    <span className="price">
                      {TextFormate(ORDER_LIST?.total, 2)} {t("SR")}
                    </span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default OrderTracking;
