import React, { lazy } from "react";
import { useSelector, useDispatch } from "react-redux";
import {} from "react-router-dom";
import {
  camerUsericon,
  accountIcon,
  transIcon,
  notify,
  languageIcon,
  logoutIcon,
  customerIcon,
  otherIcon,
} from "../../assets/images/index";
import Rating from "./../../Component/StarRating";
import {
  pushEmailNotify,
  pushNotify,
} from "../../actions/updateNotificationAction";
import { changeLanguage } from "../../actions/languageAction";
import {
  Link,
  useHistory,
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { userLogout } from "../../actions/userActions";
import { loadDialog } from "../../actions/baseActions";
import AccountInfo from "./AccountInfo";
import MyProfile from "./Profile";
import AddressManager from "./AddressManager";
const Dialog = lazy(() => import("../../Component/Dialog"));

const ProfileSidebar = (props) => {
  const dispatch = useDispatch();
  const { push } = useHistory();
  const { userdata } = props;
  const emailtToggle =
    undefined !== userdata.userProfileInfo &&
    userdata.userProfileInfo &&
    userdata.userProfileInfo.email_notification;
  const pushStatus =
    undefined !== userdata.userProfileInfo &&
    userdata.userProfileInfo &&
    userdata.userProfileInfo.notification;
  const languageType =
    undefined !== userdata.userProfileInfo && userdata.userProfileInfo
      ? userdata.userProfileInfo.language
      : "";
  const { t } = useTranslation();
  const { dialog, publicProfile } = useSelector((state) => ({
    dialog: state.dialog,
    publicProfile: state.publicProfile,
  }));
  const handleEmailNotification = () => {
    dispatch(
      pushEmailNotify(
        {
          email_notification: !emailtToggle,
        },
        push
      )
    );
  };

  const handlePushNotification = () => {
    dispatch(
      pushNotify(
        {
          notification: !pushStatus,
        },
        push
      )
    );
  };
  const handleLanguage = () => {
    dispatch(
      changeLanguage(
        {
          language: languageType === "en" ? "ar" : "en",
        },
        push
      )
    );
  };
  const handleLogoutClick = () => {
    dispatch(
      loadDialog({
        open: true,
        message: t("LOGOUT_MESSAGE"),
        title: t("LOGOUT_TITLE"),
      })
    );
  };
  const handleLogoutAction = () => {
    dispatch(
      loadDialog({
        open: false,
        message: t("LOGOUT_MESSAGE"),
        title: t("LOGOUT_TITLE"),
      })
    );
    dispatch(userLogout());
  };
  return (
    <>
      <BrowserRouter>
        <div className="sidebar_outer">
          {undefined !== userdata.userProfileInfo &&
          userdata.userProfileInfo.length !== 0 ? (
            <div className="sidebar_main">
              {" "}
              <div className="sidebar">
                <div className="profile_detail edit_Pic camera_icon_banner">
                  <figure>
                    <img
                      src={undefined !== publicProfile && publicProfile.image}
                      alt="User Profile"
                    />{" "}
                    <input type="file" />{" "}
                    <img
                      className="camera_icon"
                      src={camerUsericon}
                      alt="Camera Icon"
                    />{" "}
                  </figure>

                  <div className="rating_star text-center">
                    <h2>
                      {userdata.userProfileInfo.first_name +
                        " " +
                        userdata.userProfileInfo.last_name}
                    </h2>
                    <div className="user_rating">
                      <Rating rating={userdata.userProfileInfo.rating} />
                      <small>
                        (
                        {userdata.userProfileInfo.totalRating > 200
                          ? `${userdata.userProfileInfo.totalRating} + Ratings`
                          : `${userdata.userProfileInfo.totalRating} Ratings`}
                        )
                      </small>
                    </div>
                  </div>
                </div>

                <div className="sidebar_sie_menu">
                  <h5>
                    <span>
                      <img src={accountIcon} alt="Account Icon" />
                    </span>
                    {t("ACCOUNT")}
                  </h5>
                  <ul>
                    <li>
                      <Link className="active" to="/my-account/info">
                        {t("ACCOUNT_INFO")}
                      </Link>
                    </li>
                    <li>
                      <Link to="/my-account/profile">Public Profile </Link>
                    </li>
                    <li>
                      <Link to="/my-account/manage-address">
                        Manage Addresses
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="sidebar_sie_menu">
                  <h5>
                    <span>
                      <img src={transIcon} alt="Transaction Icon" />
                    </span>
                    {t("TRANSACTIONS")}
                  </h5>
                  <ul>
                    <li>
                      <Link to="#">Purchase and Sale History</Link>
                    </li>
                    <li>
                      <Link to="#">Payment Method </Link>
                    </li>
                    <li>
                      <Link to="#">
                        Cash Out<span>{userdata.userProfileInfo.cash} SR</span>
                      </Link>
                    </li>
                  </ul>
                </div>

                <div className="sidebar_notify sidebar_sie_menu">
                  <h5>
                    <span>
                      <img src={notify} alt="Notify" />
                    </span>
                    {t("NOTIFICATION")}
                  </h5>
                  <span className="email_notify">
                    {t("EMAIL")}{" "}
                    <input
                      type="checkbox"
                      id="switch"
                      name="email_notification"
                      onChange={handleEmailNotification}
                      checked={emailtToggle}
                    />
                    <label for="switch" className="nlabel"></label>
                  </span>
                  <span className="email_notify">
                    {t("PUSH")}{" "}
                    <input
                      type="checkbox"
                      id="switch1"
                      onChange={handlePushNotification}
                      checked={pushStatus}
                    />
                    <label for="switch1" className="nlabel"></label>
                  </span>
                </div>

                <div className="sidebar_notify sidebar_sie_menu">
                  <h5>
                    <span>
                      <img src={languageIcon} alt="Language Icon" />
                    </span>
                    {t("LANGUAGE")}
                  </h5>

                  <input
                    type="radio"
                    name="radio1"
                    id="radiostyle1"
                    onChange={handleLanguage}
                    checked={languageType === "en" ? true : false}
                  />
                  <label for="radiostyle1" className="email_notify">
                    <span>English</span>
                  </label>

                  <input
                    type="radio"
                    name="radio1"
                    id="radiostyle2"
                    onChange={handleLanguage}
                    value="PM"
                    checked={languageType === "ar" ? true : false}
                  />
                  <label for="radiostyle2" className="email_notify">
                    <span>Arabic</span>
                  </label>
                </div>

                <div className="sidebar_sie_menu">
                  <h5>
                    <span>
                      <img src={customerIcon} alt="Customer Icon" />
                    </span>
                    {t("CUSTOMER_SUPPORT")}
                  </h5>
                  <ul>
                    <li>
                      <Link to="#">Live Chat</Link>
                    </li>
                    <li>
                      <Link to="#">Support Center </Link>
                    </li>
                    <li>
                      <Link to="#">Contact Us</Link>
                    </li>
                  </ul>
                </div>

                <div className="sidebar_sie_menu">
                  <h5>
                    <span>
                      <img src={otherIcon} alt="Other Icon" />
                    </span>
                    {t("OTHERS")}
                  </h5>
                  <ul>
                    <li>
                      <Link to="#">Invite Friends</Link>
                    </li>
                    <li>
                      <Link to="#">About Us </Link>
                    </li>
                    <li>
                      <Link to="#">FAQ's</Link>
                    </li>
                    <li>
                      <Link to="#">Terms & Conditions</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy Policy </Link>
                    </li>
                  </ul>
                </div>

                <div className="sidebar_sie_menu">
                  <h5>
                    <span onClick={handleLogoutClick}>
                      <img src={logoutIcon} alt="Logout Icon" />
                    </span>
                    {t("LOGOUT")}
                  </h5>
                </div>
              </div>{" "}
            </div>
          ) : (
            ""
          )}
          {dialog.open && <Dialog action={handleLogoutAction} />}
          <div className="dashboard_main">
            <Switch>
              <Route exact path="/my-account">
                <AccountInfo />
              </Route>
              <Route path="/my-account/info">
                <AccountInfo />
              </Route>
              <Route path="/my-account/profile">
                <MyProfile />
              </Route>
              <Route path="/my-account/manage-address">
                <AddressManager />
              </Route>
            </Switch>
          </div>
        </div>
      </BrowserRouter>
    </>
  );
};

export default ProfileSidebar;
