import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadPageData } from "../../actions/homePageActions";
import { Container } from "react-bootstrap";
import Breadcrumb from "../../Component/Breadcrumb";
import FooterApp from "../../Component/FooterApp";

const Index = (props) => {
  var slug = props?.location?.pathname;
  slug = slug.replace("/en/", "/");
  slug = slug.replace("/ar/", "/");
  const dispatch = useDispatch();
  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  const pageData = useSelector((state) => state?.homePageData?.pageData);

  useEffect(() => {
    if (slug) {
      dispatch(loadPageData(slug));
    }
  }, [slug]);

  return (
    <Fragment>
      <p style={{ color: "#000" }}></p>
      {pageData?.translate && (
        <Fragment>
          <section className="inner_sec">
            <Container>
              <h2>{pageData?.translate?.title}</h2>
            </Container>
          </section>
          <Breadcrumb title={pageData?.translate?.title} />
          <section className="about_us_sec">
            <Container>
              <div
                className="about-page-text"
                dangerouslySetInnerHTML={prepareHtml(
                  pageData?.translate?.description
                )}
              ></div>
            </Container>
          </section>
        </Fragment>
      )}
      <FooterApp />
    </Fragment>
  );
};

export default Index;
