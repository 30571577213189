import { React, useEffect } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { loadChangePasswordPop } from "../../actions/baseActions";
import { fetchUserProfileData } from "../../actions/userProfileAction";
import ChangePassword from "../Auth/ChangePassword";
import { useTranslation } from "react-i18next";
import { tick } from "../../assets/images/index";
const AccountInfo = () => {
  var slug = "/about-us";
  slug = slug.replace("/en/", "/");
  slug = slug.replace("/ar/", "/");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { userProfileInfo } = useSelector((state) => ({
    userProfileInfo: state.userProfileInfo,
  }));
  useEffect(() => {
    const fetchUserInfoData = () => {
      dispatch(fetchUserProfileData());
    };

    fetchUserInfoData();
  }, [dispatch, slug]);

  const handleChangePass = (event) => {
    event.preventDefault();
    dispatch(loadChangePasswordPop(true));
  };
  return (
    <>
      {undefined !== userProfileInfo.userProfileInfo &&
      userProfileInfo.userProfileInfo.length !== 0 ? (
        <div className="outline_wrapper">
          <h2>{t("ACCOUNT_INFO")}</h2>
          <div className="my_account_sec">
            <Form>
              <Row>
                <Col lg={7}>
                  <div className="account_step">
                    <div className="order_detail_head border-0">
                      <h4>{t("ACCOUNT_INFO")}</h4>
                      <Button className="edit">
                        <i className="fas fa-pen"></i>
                        {t("EDIT")}
                      </Button>
                    </div>
                    <div className="myaccount_group">
                      <Form.Group className="mb-3 form_field" controlId="">
                        <Form.Control
                          type="text"
                          value={userProfileInfo.userProfileInfo.first_name}
                        />
                      </Form.Group>

                      <Form.Group
                        className="mb-3 ml-3 form_field last_name_user"
                        controlId=""
                      >
                        <Form.Control
                          type="text"
                          value={userProfileInfo.userProfileInfo.last_name}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="account_step">
                    <div className="order_detail_head border-0">
                      <h4>{t("EMAIL_ADDRESS")}</h4>
                      <Button className="edit">
                        <i className="fas fa-pen"></i>
                        {t("EDIT")}
                      </Button>
                      <Button className="edit" onClick={handleChangePass}>
                        {t("CHANGE_PASSWORD")}
                      </Button>
                    </div>
                    <div className="myaccount_group">
                      <Form.Group className="mb-3 form_field" controlId="">
                        <Form.Control
                          type="email"
                          value={userProfileInfo.userProfileInfo.email}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="account_step">
                    <div className="order_detail_head border-0">
                      <h4>{t("MOBILE_NUMBER")}</h4>
                      <Button className="edit">
                        <i className="fas fa-pen"></i>
                        {t("EDIT")}
                      </Button>
                    </div>
                    <div className="myaccount_group">
                      <Form.Group className="mb-3 form_field" controlId="">
                        <Form.Control
                          type="text"
                          value={userProfileInfo.userProfileInfo.mobile_number}
                        />
                      </Form.Group>
                    </div>
                  </div>

                  <div className="account_infor_verfiy">
                    <ul>
                      <li>
                        {" "}
                        {t("DOFFO_VERIFIED")}
                        <span>
                          {userProfileInfo.userProfileInfo.doffoStatus && (
                            <img src={tick} alt="Tick Icon" />
                          )}
                        </span>
                      </li>
                      <li>
                        {t("FACEBOOK_CONNECTS")}
                        <span>
                          {userProfileInfo.userProfileInfo.fbStatus && (
                            <img src={tick} alt="Tick Icon" />
                          )}
                        </span>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      ) : (
        ""
      )}
      <ChangePassword />
    </>
  );
};
export default AccountInfo;
