import * as types from "../actions/actionsTypes";
//import initialState from "./initialState";

const initialState = {
  bankData: [],
  cardData: {},
};

export default function bankDataReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_BANK_DATA_SUCCESS:
      return {
        ...state,
        bankData: action.bankData,
      };
    case types.LOAD_CARD_DATA_SUCCESS:
      return {
        ...state,
        cardData: action.cardData,
      };
    default:
      return state;
  }
}
