import React from "react";
import { ClipLoader } from "react-spinners";

const CommonLoader = ({ isCenter }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: isCenter ? "50vh" : "",
        }}
        className="common_loader"
      >
        <ClipLoader color={"#3c44b1"} loading={true} size={35} />
      </div>
    </>
  );
};

export default CommonLoader;
