import React, { lazy, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchUserProfile,
  IS_EMAIL_CHECK,
  IS_PUSH_CHECK,
  updateEmailNOtification,
  updatePushNotification,
  updateUseImage,
} from "../../actions/SIdebarAction";
import { loadDialog, loadToasterData } from "../../actions/baseActions";
import { userLogout } from "../../actions/userActions";
import { useTranslation } from "react-i18next";
import ReactStartRating from "../../Component/StarRating/reactStartRating";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CheckIcon from "@mui/icons-material/Check";
import SharePostModal from "../Home/SharePostModal";
import SheduleMeetup from "../Home/SheduleMeetup";

const Dialog = lazy(() => import("../../Component/Dialog"));

function Sidebar() {
  const location = useLocation();
  const [showShareModal, setShowShareModal] = useState(false);
  const [showSheduleMeetupModal, setShowSheduleMeetupModal] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();
  const fileInput = useRef();

  // All Selectors
  const {
    // selectedLang,
    dialog,
    GET_USER_PROFILE,
    CHANGE_PROFILE_LOADER,
    IS_EMAIL,
    IS_PUSH_NOTIFICATION,
  } = useSelector((state) => ({
    // selectedLang: state.selectedLang,
    dialog: state.dialog,
    GET_USER_PROFILE: state?.sidebarReducer?.userProfileData,
    CHANGE_PROFILE_LOADER: state?.sidebarReducer?.changeImageLoading,
    IS_EMAIL: state?.sidebarReducer?.emailNotificationCheck,
    IS_PUSH_NOTIFICATION: state?.sidebarReducer?.pushNotificationCheck,
  }));
  //console.log("GET_USER_PROFILE", GET_USER_PROFILE.referral_code);
  // All State
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  // const [emailNotification, setEmailNotification] = useState(false);

  useEffect(() => {
    const fetchProfileData = () => {
      dispatch(fetchUserProfile());
    };

    fetchProfileData();
  }, [dispatch]);

  const handleLogoutClick = () => {
    dispatch(
      loadDialog({
        open: true,
        message: t("LOGOUT_MESSAGE"),
        title: t("LOGOUT_TITLE"),
      })
    );
  };

  const handleLogoutAction = () => {
    dispatch(
      loadDialog({
        open: false,
        message: t("LOGOUT_MESSAGE"),
        title: t("LOGOUT_TITLE"),
      })
    );
    dispatch(userLogout());
  };

  const changeLanguage = (event) => {
    setAnchorEl(null);
    var lng = event;
    i18n.changeLanguage(lng);
    var str = pathname;
    if (str.search("en") === 1) {
      const newUrl = pathname.replace("en", "ar");
      // window.location.href = newUrl;
    } else if (str.search("ar") === 1) {
      const newUrl = pathname.replace("ar", "en");
      // window.location.href = newUrl;
    } else if (str.search("en") === -1) {
      var url = lng + pathname;
      // window.location.href = url;
    }
    document.documentElement.setAttribute("lang", lng);

    // if (lng === "ar") {
    //   document.getElementsByTagName("HTML")[0].setAttribute("dir", "rtl");
    //   document.getElementsByTagName("HTML")[0].classList.add("arabic");
    // } else {
    //   document.getElementsByTagName("HTML")[0].setAttribute("dir", "ltr");
    //   document.getElementsByTagName("HTML")[0].classList.remove("arabic");
    // }
  };

  const imageHandelChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size > 10485760) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_IS_TOO_BIG"
            )}`,
            "error",
            true
          )
        );
      } else if (
        e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "image/png"
      ) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_NOT_SUPPORTED"
            )}`,
            "error",
            true
          )
        );
      } else {
        const fileArray = Array.from(e.target.files).map(
          (file) =>
            // URL.createObjectURL(file)
            file
        );
        if (fileArray.length > 0) {
          setSelectedFile((prevImages) => prevImages.concat(fileArray));
          const formData = new FormData();
          fileArray.map((e) => {
            formData.append("image", e);
          });
          formData.append("type", "PROFILE");
          dispatch(updateUseImage(formData));
        }
      }
    }
  };

  useEffect(() => {
    dispatch(IS_EMAIL_CHECK(GET_USER_PROFILE?.email_notification));
    dispatch(IS_PUSH_CHECK(GET_USER_PROFILE?.notification));
  }, [GET_USER_PROFILE]);

  const handelToggleEmail = () => {
    const data = {
      email_notification: !IS_EMAIL,
    };
    dispatch(updateEmailNOtification(data));
  };

  const handelTogglePush = () => {
    const data = { notification: !IS_PUSH_NOTIFICATION };
    dispatch(updatePushNotification(data));
  };

  const selectedLang = i18n.language;

  // console.log("i18n.changeLanguage", i18n.language);

  const toggleShareModal = () => {
    setShowShareModal(!showShareModal);
  };

  const toggleSheduleMeetupModal = () => {
    setShowSheduleMeetupModal(!showSheduleMeetupModal);
  };

  const openChat = () => {
    window.fcWidget.open();
    window.fcWidget.show();
  };

  return (
    <div className={selectedLang == "ar" ? "is-arabic " : ""}>
      <>
        <div className="sidebar">
          <div className="profile_detail edit_Pic camera_icon_banner">
            <figure>
              <img
                src={
                  selectedFile.length !== 0
                    ? URL.createObjectURL(
                        selectedFile[selectedFile?.length - 1]
                      )
                    : GET_USER_PROFILE?.image
                }
                alt=""
                style={{ width: "100%" }}
              />{" "}
              <div
                style={
                  CHANGE_PROFILE_LOADER
                    ? { cursor: "not-allowed" }
                    : { cursor: "pointer" }
                }
              >
                <input
                  type="file"
                  name="profilePicture"
                  accept="image/jpeg, image/png"
                  style={{ display: "none" }}
                  ref={fileInput}
                  onChange={(e) => imageHandelChange(e)}
                  // multiple
                  onClick={(e) => (e.target.value = null)}
                />
                {CHANGE_PROFILE_LOADER ? (
                  <span className="camera_icon">
                    <i
                      className="fa fa-spinner fa-spin "
                      style={{
                        color: "#fff",
                        fontSize: "14px",
                        marginLeft: "1px",
                        background: "#33A7CC",
                        padding: "5px",
                        borderRadius: "50%",
                        border: "2px solid #ffffff",
                      }}
                    />
                  </span>
                ) : (
                  <CameraAltIcon
                    onClick={() => fileInput.current.click()}
                    className="camera_icon"
                    style={{
                      background: "#33A7CC",
                      color: "#ffffff",
                      padding: "5px",
                      borderRadius: "50%",
                      border: "2px solid #ffffff",
                    }}
                  />
                )}
              </div>
            </figure>

            <div className="rating_star text-center">
              <h2>
                {GET_USER_PROFILE?.first_name} {GET_USER_PROFILE?.last_name}
              </h2>

              <div className="user_rating">
                <ReactStartRating rating={GET_USER_PROFILE?.rating} />

                <small>
                  ({GET_USER_PROFILE?.totalRating} {t("RATINGS")})
                </small>
              </div>
            </div>
          </div>

          <div className="sidebar_sie_menu">
            <h5>
              <span>
                <img src="../assets/images/icon1.svg" alt="No_IMG" />
              </span>
              {t("ACCOUNT")}
            </h5>
            <ul>
              <li
                className={location.pathname == "/account-info" ? "active" : ""}
              >
                <Link to="/account-info">{t("ACCOUNT_INFO")}</Link>
              </li>
              <li
                className={
                  location.pathname == "/public-profile" ? "active" : ""
                }
              >
                <Link to="/public-profile">{t("PUBLIC_PROFILE")} </Link>
              </li>
              <li
                className={
                  location.pathname == "/manage-address" ? "active" : ""
                }
              >
                <Link to="/manage-address">{t("MANAGE_ADDRESSES")}</Link>
              </li>
              {/* <li>
                <Link to="#" onClick={() => toggleSheduleMeetupModal()}>
                  Shedule MeetUp
              </Link>
              </li>*/}
            </ul>
          </div>

          <div className="sidebar_sie_menu">
            <h5>
              <span>
                <img src="../assets/images/language_icon.svg" alt="No_IMG" />
              </span>
              {t("POST")}
            </h5>
            <ul>
              {/* <li
                className={
                  location.pathname == "/manage-post-list" ? "active" : ""
                }
              >
                <Link to="/manage-post-list">{t("MANAGE_POST_LIST")}</Link>
              </li> */}
              <li className={location.pathname == "/manage" ? "active" : ""}>
                <Link to="/manage">{t("MANAGE")}</Link>
              </li>
              <li
                className={
                  location.pathname == "/manage-archive-list" ? "active" : ""
                }
              >
                <Link to="/manage-archive-list">{t("ARCHIVE_POST_LIST")} </Link>
              </li>
              <li className={location.pathname == "/like" ? "active" : ""}>
                <Link to="/like">{t("LIKE")} </Link>
              </li>
            </ul>
          </div>

          <div className="sidebar_sie_menu">
            <h5>
              <span>
                <img src="../assets/images/trans_icon.svg" alt="No_IMG" />
              </span>
              {t("TRANSACTIONS")}
            </h5>
            <ul>
              <li>
                <Link to="/transaction-history">
                  {t("PURCHASE_AND_SALE_HISTORY")}
                </Link>
              </li>
              <li>
                <Link to="/payment-method">{t("PAYMENT_METHOD")} </Link>
              </li>
              <li>
                <Link to="/cash-out">
                  {t("CASH_OUT")}
                  <span>
                    {GET_USER_PROFILE.cash} {t("SR")}
                  </span>
                </Link>
              </li>
            </ul>
          </div>

          <div className="sidebar_notify sidebar_sie_menu">
            <h5>
              <span>
                <img src="../assets/images/notify_icon.svg" alt="No_IMG" />
              </span>
              {t("NOTIFICATION")}
            </h5>
            <ul>
              <li
                className={
                  location.pathname == "/notifications" ? "active" : ""
                }
              >
                <Link to="/notifications">Notifications</Link>
              </li>
            </ul>
            <span className="email_notify">
              {t("EMAIL")}{" "}
              <input
                type="checkbox"
                id="switch"
                onChange={() => handelToggleEmail()}
                checked={IS_EMAIL ? 1 : 0}
                // checked={IS_EMAIL}
              />
              <label htmlFor="switch" className="nlabel"></label>
            </span>
            <span className="email_notify">
              {t("PUSH")}{" "}
              <input
                type="checkbox"
                id="switch1"
                onChange={() => handelTogglePush()}
                // checked={IS_PUSH_NOTIFICATION}
                checked={IS_PUSH_NOTIFICATION ? 1 : 0}
              />
              <label htmlFor="switch1" className="nlabel"></label>
            </span>
          </div>

          <div className="sidebar_notify sidebar_sie_menu">
            <h5>
              <span>
                <img src="../assets/images/language_icon2.svg" alt="No_IMG" />
              </span>
              {t("LANGUAGE")}
            </h5>

            {/* <input
              type="radio"
              name="radio1"
              id="radiostyle1"
              defaultChecked={selectedLang == "en" ? 1 : 0}
            /> */}
            <div style={{ position: "relative" }}>
              {selectedLang == "en" && (
                <CheckIcon
                  style={{
                    position: "absolute",
                    right: "23px",
                    top: "10px",
                    color: "#33A7CC",
                  }}
                />
              )}
              <label
                htmlFor="radiostyle1"
                className="email_notify"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (selectedLang == "ar") {
                    changeLanguage("en");
                  }
                }}
              >
                <span>{t("ENGLISH")}</span>
              </label>
            </div>

            {/* <input
              type="radio"
              name="radio1"
              id="radiostyle2"
              defaultChecked={selectedLang == "ar" ? 1 : 0}
            /> */}

            <div style={{ position: "relative" }}>
              {selectedLang == "ar" && (
                <CheckIcon
                  style={{
                    position: "absolute",
                    right: "23px",
                    top: "10px",
                    color: "#33A7CC",
                  }}
                />
              )}
              <label
                htmlFor="radiostyle2"
                className="email_notify"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  if (selectedLang == "en") {
                    changeLanguage("ar");
                  }
                }}
              >
                <span>{t("ARABIC")}</span>
              </label>
            </div>
          </div>

          <div className="sidebar_sie_menu">
            <h5>
              <span>
                <img src="../assets/images/customner_icon.svg" alt="No_IMG" />
              </span>
              {t("CUSTOMER_SUPPORT")}
            </h5>
            <ul>
              <li>
                <a
                  target="_black"
                  href="https://doffo-team.myfreshworks.com/login/auth/1656934654868?client_id=41441690836303948&redirect_uri=https%3A%2F%2Fdoffotradingcomp-help.freshdesk.com%2Ffreshid%2Fcustomer_authorize_callback"
                >
                  {t("MY_TICKET")}
                </a>
              </li>
              <li>
                <Link to="#" onClick={openChat}>
                  {t("LIVE_CHAT")}
                </Link>
              </li>
              <li>
                <Link to="#">{t("KNOWLEDGE_BASE")} </Link>
              </li>
              <li>
                <Link to={`/${selectedLang}/contact-us`}>
                  {t("CONTACT_US")}
                </Link>
              </li>
            </ul>
          </div>

          <div className="sidebar_sie_menu">
            <h5>
              <span>
                <img src="../assets/images/other_icon.svg" />
              </span>
              {t("OTHERS")}
            </h5>
            <ul>
              <li>
                <Link to="#" onClick={() => toggleShareModal()}>
                  {t("INVITE_FRIENDS")}
                </Link>
              </li>
              <li>
                <Link to={`/${selectedLang}/about-us`}>{t("ABOUT_US")} </Link>
              </li>
              <li>
                <Link to={`/${selectedLang}/faq`}>{t("FAQ")}</Link>
              </li>
              <li>
                <Link to={`/${selectedLang}/terms-condition`}>
                  {t("TERMS_CONDITIONS")}
                </Link>
              </li>
              <li>
                <Link to={`/${selectedLang}/privacy-policy`}>
                  {t("PRIVACY_POLICY")}{" "}
                </Link>
              </li>
            </ul>
          </div>

          <div className="sidebar_sie_menu">
            <h5 onClick={handleLogoutClick} style={{ cursor: "pointer" }}>
              <span>
                <img src="../assets/images/logout.svg" alt="NO_IMG" />
              </span>
              {t("LOGOUT")}
            </h5>
          </div>
        </div>
      </>
      {dialog.open && <Dialog action={handleLogoutAction} />}

      {showShareModal && (
        <SharePostModal
          show={showShareModal}
          onHide={() => toggleShareModal()}
          PRODUCT_DETAIL={{
            shareLink: `${process.env.REACT_APP_PUBLIC_URL}/${GET_USER_PROFILE.referral_code}`,
          }}
          type="INVITE"
        />
      )}

      {showSheduleMeetupModal && (
        <SheduleMeetup
          show={showSheduleMeetupModal}
          onHide={() => toggleSheduleMeetupModal()}
        />
      )}
    </div>
  );
}

export default Sidebar;
