import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as facebookConnectAction from "../../actions/userActions";
import { useHistory } from "react-router-dom";
import "./facebookConnect.css";
import FacebookLogin from "react-facebook-login";
import config from "../../config";
const SocialLogin = (props) => {
  const { actions, isToken } = props;
  const { push } = useHistory();
  const responseFacebook = (response) => {
    const data = response;
    //console.log(response);
    if (response.name) {
      var name = data.name.split(" ");
      let params = {};
      /* params.device_type = "web";
      params.device_token = isToken;
      params.first_name = name[0];
      params.last_name = name[1];
      params.email = data.email;
      params.social_id = data.id;
      params.social_type = "Facebook";
      params.role_id = 3;*/
      actions.facebookConnectAction(params);
    }
  };
  return (
    <>
      <FacebookLogin
        appId={config.FACEBOOK_CLIENT_ID}
        textButton="Facebook Connects"
        fields="name,email,picture"
        callback={responseFacebook}
        cssClass="fb fb-connect"
        //icon="fab fa-facebook-f mr-3"
      />
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(Object.assign(facebookConnectAction), dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SocialLogin);
