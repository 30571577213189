import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function deviceTokenRequest(
  state = initialState.device_token,
  action
) {
  switch (action.type) {
    case types.LOADED_DEVICE_TOKEN_SUCCESS:
      return action.device_token;
    default:
      return state;
  }
}
