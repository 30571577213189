import { React, useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Dropdown,
  Modal,
  Button as BTN,
} from "react-bootstrap";
import Button from "../../../Component/Button";
import { useSelector, useDispatch } from "react-redux";
import Sidebar from "../Sidebar";
import { useTranslation } from "react-i18next";

import {
  userWallet,
  userCashOut,
  userAutomaticallyCashOut,
} from "./../../../actions/userActions";
import LottiLoader from "../../../Component/lottiAnimation/LottiLoader";
import moment from "moment";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#33a7cc",
    },
    "& .Mui-focused": {
      color: "#33a7cc",
    },
  },
});

function CashOut() {
  const { push } = useHistory();
  const dispatch = useDispatch();

  const classes = useStyles();

  function dashsidebar() {
    document.getElementById("onscroll").classList.toggle("dashboard_sidebar");
  }

  const [cash, cashshow] = useState(false);

  const cash1 = () => cashshow(false);
  const cash2 = () => cashshow(true);

  const { t } = useTranslation();

  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm();

  // console.log("errors", errors);

  // All Selectors
  const { GET_USER_PROFILE } = useSelector((state) => ({
    GET_USER_PROFILE: state?.sidebarReducer?.userProfileData,
  }));

  const {
    walletData,
    walletTotalAmount,
    walletLoading,
    walletPagination,
    walletLoadMoreLoading,
  } = useSelector((state) => ({
    walletData: state?.walletData?.walletData,
    walletTotalAmount: state?.walletData?.walletTotalAmount,
    walletLoading: state?.walletData?.walletLoading,
    walletPagination: state?.walletData?.walletPagination,
    walletLoadMoreLoading: state?.walletData?.walletLoadMoreLoading,
  }));

  useEffect(() => {
    const fetchData = () => {
      dispatch(userWallet());
    };
    fetchData();
  }, [dispatch]);

  const cashOutSubmit = (data) => {
    dispatch(userCashOut({ amount: data.cashout_amount }, cash1));
    dispatch(userWallet());
  };

  const [page, setPage] = useState(1);

  const [automaticallyBankAccount, setAutomaticallyBankAccount] = useState(0);

  useEffect(() => {
    setAutomaticallyBankAccount(GET_USER_PROFILE.automaticallyBackAccount);
  }, [GET_USER_PROFILE]);

  //console.log("automaticallyBankAccount", automaticallyBankAccount);

  useEffect(() => {
    if (
      automaticallyBankAccount !== undefined &&
      automaticallyBankAccount !== 0 &&
      GET_USER_PROFILE.automaticallyBackAccount !== automaticallyBankAccount
    ) {
      dispatch(userAutomaticallyCashOut({ status: automaticallyBankAccount }));
      dispatch(userWallet());
    }
  }, [automaticallyBankAccount]);

  const handelLoadMore = () => {
    setPage(page + 1);
    let data = {};
    data = {
      page: page + 1,
    };
    dispatch(userWallet(data));
  };

  return (
    <>
      <section
        className="middle_banner_sec"
        style={{
          backgroundImage: `url(${GET_USER_PROFILE?.backgroundImage})`,
        }}
      ></section>

      <div className="toggle_main">
        <div className="togggle_dashboard" onClick={dashsidebar}>
          <img src="../assets/images/toggle.png" alt="" />
        </div>
      </div>
      <section className="manage_address">
        <Container>
          <div className="sidebar_outer">
            <div className="sidebar_main">
              <Sidebar />
            </div>

            <div className="dashboard_main outline_wrapper">
              <div className="outline_wrapper border-0 p-0">
                <div className="remain_balance">
                  <span>
                    Available Balance:
                    <small>{walletTotalAmount} SR</small>
                  </span>
                </div>

                <div className="transcation_history_sec">
                  <h2>Transactions History</h2>
                  <span className="email_notify d-flex">
                    Transfer money automatically to bank account
                    <input
                      type="checkbox"
                      id="cash"
                      checked={automaticallyBankAccount ? "checked" : ""}
                      onChange={() => {
                        setAutomaticallyBankAccount(
                          automaticallyBankAccount ? false : true
                        );
                      }}
                    />
                    <label for="cash" className="nlabel"></label>
                  </span>
                </div>

                <div className="trans_history_money cashout_money">
                  <ul className="money_add">
                    {!walletLoading && walletData?.length === 0 ? (
                      <Row>
                        <LottiLoader
                          message="No products found"
                          height={250}
                          width={250}
                        />
                      </Row>
                    ) : (
                      <>
                        {walletData &&
                          walletData?.length !== 0 &&
                          walletData?.map((wallet) => (
                            <li>
                              <div className="manage_post_ul">
                                <div className="trans_date">
                                  <div
                                    className={`trans_date_list ${
                                      wallet.type === "CREDIT"
                                        ? "success_border"
                                        : "danger_border"
                                    }`}
                                  >
                                    <span>
                                      <strong>
                                        {moment(wallet.date).format("DD")}
                                      </strong>
                                      {moment(wallet.date).format("MMM")}
                                    </span>
                                  </div>

                                  <div className="trans_title">
                                    <h3>{wallet.remark}</h3>
                                    <span>
                                      <i class="far fa-clock"></i>{" "}
                                      {moment(wallet.date).format("HH:mm A")}
                                    </span>
                                    <span>
                                      {moment(wallet.date).format(
                                        "DD MMM YYYY"
                                      )}
                                    </span>
                                  </div>
                                </div>

                                <span
                                  className={`amount_price ${
                                    wallet.type === "CREDIT"
                                      ? "success"
                                      : "danger"
                                  }`}
                                >
                                  {wallet.type === "CREDIT" ? "+" : "-"}{" "}
                                  {wallet.amount} SR
                                </span>
                              </div>
                            </li>
                          ))}
                      </>
                    )}

                    {walletLoadMoreLoading ? (
                      <LottiLoader loader={true} height={120} width={120} />
                    ) : (
                      <>
                        {!walletLoading &&
                          walletData?.length !== 0 &&
                          walletData.length !== walletPagination?.total && (
                            <Button
                              className="outline-btn theme_btn"
                              onClick={() => handelLoadMore()}
                            >
                              Load More
                            </Button>
                          )}
                      </>
                    )}

                    {/* <li>
                      <div className="manage_post_ul">
                        <div className="trans_date">
                          <div className="trans_date_list success_border">
                            <span>
                              <strong>20</strong>MAY
                            </span>
                          </div>

                          <div className="trans_title">
                            <h3>Money added to the Account</h3>
                            <span>
                              <i class="far fa-clock"></i> 10:30 AM
                            </span>
                            <span>20 May 2021</span>
                          </div>
                        </div>

                        <span className="amount_price success">+15.24 SR </span>
                      </div>
                    </li>
                    <li>
                      <div className="manage_post_ul">
                        <div className="trans_date">
                          <div className="trans_date_list danger_border">
                            <span>
                              <strong>20</strong>MAY
                            </span>
                          </div>

                          <div className="trans_title">
                            <h3>Money Paid to the Account</h3>
                            <span>
                              <i class="far fa-clock"></i> 10:30 AM
                            </span>
                            <span>20 May 2021</span>
                          </div>
                        </div>

                        <span className="amount_price danger">+15.24 SR </span>
                      </div>
                    </li>
                    <li>
                      <div className="manage_post_ul">
                        <div className="trans_date">
                          <div className="trans_date_list danger_border">
                            <span>
                              <strong>20</strong>MAY
                            </span>
                          </div>

                          <div className="trans_title">
                            <h3>Money Paid to the Account</h3>
                            <span>
                              <i class="far fa-clock"></i> 10:30 AM
                            </span>
                            <span>20 May 2021</span>
                          </div>
                        </div>

                        <span className="amount_price danger">+15.24 SR </span>
                      </div>
                    </li>
                    <li>
                      <div className="manage_post_ul">
                        <div className="trans_date">
                          <div className="trans_date_list success_border">
                            <span>
                              <strong>20</strong>MAY
                            </span>
                          </div>

                          <div className="trans_title">
                            <h3>Money added to the Account</h3>
                            <span>
                              <i class="far fa-clock"></i> 10:30 AM
                            </span>
                            <span>20 May 2021</span>
                          </div>
                        </div>

                        <span className="amount_price success">+15.24 SR </span>
                      </div>
                    </li>
                    <li>
                      <div className="manage_post_ul">
                        <div className="trans_date">
                          <div className="trans_date_list danger_border">
                            <span>
                              <strong>20</strong>MAY
                            </span>
                          </div>

                          <div className="trans_title">
                            <h3>Money Paid to the Account</h3>
                            <span>
                              <i class="far fa-clock"></i> 10:30 AM
                            </span>
                            <span>20 May 2021</span>
                          </div>
                        </div>

                        <span className="amount_price danger">+15.24 SR </span>
                      </div>
                    </li>
                    <li>
                      <div className="manage_post_ul">
                        <div className="trans_date">
                          <div className="trans_date_list success_border">
                            <span>
                              <strong>20</strong>MAY
                            </span>
                          </div>

                          <div className="trans_title">
                            <h3>Money added to the Account</h3>
                            <span>
                              <i class="far fa-clock"></i> 10:30 AM
                            </span>
                            <span>20 May 2021</span>
                          </div>
                        </div>

                        <span className="amount_price success">+15.24 SR </span>
                      </div>
                    </li> */}

                    <div className="add_new_btn text-end">
                      <BTN className="theme_btn" onClick={cash2}>
                        Cash Out
                      </BTN>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>

      {/* cashout_modal */}

      <Modal show={cash} onHide={cash2} className="add_card cash_out_modal">
        <button className="crose_btn" onClick={cash1}>
          <img src="./assets/images/crose.png" />
        </button>
        <Modal.Body>
          <div className="add_new_card cashout_modal">
            <h4>Cash Out</h4>
            <div className="cashout_balance">
              <figure>
                <img src="./assets/images/money-bag.png" alt="" />
              </figure>
              <div>
                <h2>{walletTotalAmount} SR</h2>
                <span>Cash Out Balance</span>
              </div>
            </div>
            <Form
              className="addcard_form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(cashOutSubmit)}
            >
              <Row>
                <Col md={12}>
                  <Form.Group
                    className="mb-3 position-relative"
                    controlId="formGroupCashout"
                  >
                    <Form.Control
                      className={
                        !errors.cashout_amount ? classes.root : "w-100"
                      }
                      type="number"
                      name="cashout_amount"
                      placeholder="Enter Amount"
                      {...register("cashout_amount", {
                        required: "Please enter amount.",

                        min: {
                          value: 1,
                          message: "Min amount should be atleast 1.",
                        },
                        max: {
                          value: walletTotalAmount,
                          message: `Max amount less then ${walletTotalAmount} .`,
                        },
                      })}
                      autoFocus={true}
                    />
                    <span className="sr">SR</span>
                    <ErrorMessage
                      errors={errors}
                      name="cashout_amount"
                      render={({ message }) => (
                        <p className="error-message">{message}</p>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button className="theme_btn" title="Bank Transfer" />
            </Form>
          </div>
        </Modal.Body>
      </Modal>

      {/* cashout_modal */}
    </>
  );
}

export default CashOut;
