import { agent } from "../utils/agent";
import * as types from "./actionsTypes";
import * as API from "../utils/apiPath";
import toasterStatusAction from "./toasterStatusAction";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";

export function getUserProfileData(userProfileInfo) {
  return { type: types.LOAD_USER_PROFILE_DATA_SUCCESS, userProfileInfo };
}

export function getPublicProfile(publicProfile) {
  return { type: types.LOAD_PUBLIC_PROFILE, publicProfile };
}


export const fetchUserProfileData = () => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(API.LOAD_PROFILE_INFO);
    dispatch(getUserProfileData(response.data.data));
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

export const fetchPublicProfile = (id) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.get(`${API.LOAD_PUBLIC_PROFILE_URL}?id=${id}`);
    dispatch(getPublicProfile(response.data.data));
    console.log(response.data.data, 'from api');
    dispatch(toggleNetworkRequestStatus(false));
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};
