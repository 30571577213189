import React, { useEffect, useState } from "react";
import {
  Container,
  Button,
  Modal,
  Col,
  Row,
  Form,
  InputGroup,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getBuyingHistory,
  getSellingHistory,
} from "../../../actions/SIdebarAction";
import CustomTabs from "../../../Component/Tabs/Tabs";
import Sidebar from "../Sidebar";
import moment from "moment";
import LottiLoader from "../../../Component/lottiAnimation/LottiLoader";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import Pagination from "@mui/material/Pagination";
import { Divider, Tooltip } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
function TransactionHistory() {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  // All Selectors
  const {
    GET_USER_PROFILE,
    GET_BUYING__SELLING_LIST,
    GET_BUYING_AND_SELLING_PAGINATION,
    GET_BUYING_AND_SELLING_LOADER,
    selectedLang,
  } = useSelector((state) => ({
    GET_USER_PROFILE: state?.sidebarReducer?.userProfileData,
    GET_BUYING__SELLING_LIST: state?.sidebarReducer?.buyingAndSellingList,
    GET_BUYING_AND_SELLING_PAGINATION:
      state?.sidebarReducer?.buyingAndSellingPagination,
    GET_BUYING_AND_SELLING_LOADER:
      state?.sidebarReducer?.buyingAndSellingLoading,
    selectedLang: state.selectedLang,
  }));

  // All state
  const Tabs = [
    { eventKey: "buying", title: t("BUYING") },
    { eventKey: "selling", title: t("SELLING") },
  ];
  const [activeDefaultTab, setActiveDefaultTab] = useState("buying");
  const [page, setPage] = useState(1);

  const onSelectTab = (e) => {
    history.push({
      pathname: "/transaction-history",
    });
    setActiveDefaultTab(e);
    if (e == "buying") {
      dispatch(getBuyingHistory());
    }
    if (e == "selling") {
      dispatch(getSellingHistory());
    }
  };

  useEffect(() => {
    dispatch(getBuyingHistory());
  }, []);

  const pagination = (e, value) => {
    // console.log("value sdasd", value);
    const params = new URLSearchParams(location.search);
    params.set("page", value);
    history.push({
      pathname: "/transaction-history",
      search: "?" + params,
    });
    setPage(value);

    if (activeDefaultTab == "buying") {
      dispatch(getBuyingHistory());
    }
    if (activeDefaultTab == "selling") {
      dispatch(getSellingHistory());
    }
  };

  return (
    <>
      <section
        className="middle_banner_sec"
        style={{
          backgroundImage: `url(${GET_USER_PROFILE?.backgroundImage})`,
        }}
      ></section>

      <section className="manage_address">
        <Container>
          <div className="sidebar_outer">
            <div className="sidebar_main">
              <Sidebar />
            </div>
            <div className="dashboard_main outline_wrapper p-0">
              <div
                className="outline_wrapper border-0 "
                style={{
                  height: "1372px",
                  overflow: "auto",
                  padding: "24px 30px 0px",
                }}
              >
                <CustomTabs
                  tabArr={Tabs}
                  defaultActiveKey={activeDefaultTab}
                  onSelect={onSelectTab}
                  content={
                    <>
                      {GET_BUYING_AND_SELLING_LOADER ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <LottiLoader loader={true} height={100} width={100} />
                        </div>
                      ) : (
                        <div className="trans_history_money sale_history_sec">
                          <ul className="money_add">
                            {GET_BUYING__SELLING_LIST &&
                              GET_BUYING__SELLING_LIST.length !== 0 &&
                              GET_BUYING__SELLING_LIST.map((item) => {
                                return (
                                  <li
                                    style={{ cursor: "pointer" }}
                                    key={item?._id}
                                    onClick={() =>
                                      history.push({
                                        pathname: `${
                                          "/" + selectedLang
                                        }/order-tacking/${item?._id}`,
                                        state: { item },
                                      })
                                    }
                                  >
                                    <div className="manage_post_ul">
                                      <div className="trans_date">
                                        <div className="trans_date_list shadow-none border-0">
                                          <figure>
                                            <img src={item?.image} alt="" />
                                          </figure>
                                        </div>

                                        <div className="trans_title">
                                          <h3>{item?.title}</h3>
                                          <span>
                                            <ShoppingCartIcon
                                              style={{ fontSize: "14px" }}
                                            />{" "}
                                            {t("ORDER_NUMBER")}:{" "}
                                            {item?.orderNumber}
                                          </span>
                                          <span>
                                            <i className="far fa-clock"></i>{" "}
                                            {t("ORDER_DATE")}:{" "}
                                            {moment(item.date).format(
                                              "DD-MM-YYYY"
                                            )}
                                          </span>
                                        </div>
                                      </div>

                                      <div className="d-flex">
                                        {item?.status === "On Processing" ? (
                                          <small className="onroute theme_btn bg-warning">
                                            {t("ON_PROCESSING")}
                                          </small>
                                        ) : item?.status === "On Route" ? (
                                          <small className="onroute theme_btn">
                                            {t("ON_ROUTE")}
                                          </small>
                                        ) : item?.status === "Delivered" ? (
                                          <small className="onroute theme_btn bg-success">
                                            {t("DELIVERED")}
                                          </small>
                                        ) : item?.status === "Return" ? (
                                          <small className="onroute theme_btn bg-danger">
                                            {t("RETURN")}
                                          </small>
                                        ) : null}
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}
                    </>
                  }
                />
              </div>
              <>
                {!GET_BUYING_AND_SELLING_LOADER &&
                  GET_BUYING__SELLING_LIST?.length !== 0 && (
                    <>
                      <Divider />
                      <div
                        className="d-flex justify-content-between"
                        style={{ padding: "20px 24px 13px" }}
                      >
                        <p>
                          <strong>{"Total Item"}</strong> :{" "}
                          <span>
                            {GET_BUYING_AND_SELLING_PAGINATION?.total}
                          </span>
                        </p>
                        <Pagination
                          page={page}
                          count={GET_BUYING_AND_SELLING_PAGINATION?.total_pages}
                          color="primary"
                          variant="outlined"
                          onChange={(e, value) => pagination(e, value)}
                        />
                      </div>
                    </>
                  )}
              </>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default TransactionHistory;
