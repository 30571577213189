const initialState = {
  homeProductList: [],
  homeProductLoading: false,
  homeProductPagination: {},
  homeProductLoadMoreLoading: false,
  homeProductDetails: {},
  reportList: [],
  reportListLoader: false,
  submitReasonLoader: false,
};
const HomeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "HOME_PRODUCT_LIST":
      return {
        ...state,
        homeProductList: action.payload,
      };

    case "HOME_PRODUCT_LOADING":
      return {
        ...state,
        homeProductLoading: action.payload,
      };

    case "HOME_PRODUCT_LOAD_MORE_LIST":
      const temp = [...state.homeProductList, ...action.payload];

      return {
        ...state,
        homeProductList: temp,
      };

    case "HOME_PRODUCT_PAGINATION":
      return {
        ...state,
        homeProductPagination: action.payload,
      };

    case "HOME_PRODUCT_LOAD_MORE_LOADING":
      return {
        ...state,
        homeProductLoadMoreLoading: action.payload,
      };

    case "HOME_PRODUCT_DETAILS":
      return {
        ...state,
        homeProductDetails: action.payload,
      };

    case "REPORT_LIST":
      return {
        ...state,
        reportList: action.payload,
      };

    case "REPORT_LIST_LOADER":
      return {
        ...state,
        reportListLoader: action.payload,
      };

    case "SUBMIT_REASON_LOADER":
      return {
        ...state,
        submitReasonLoader: action.payload,
      };

    default:
      return state;
  }
};

export default HomeReducer;
//
