import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function addUserAddress(
  state = initialState.createpost,
  action
) {
  switch (action.type) {
    case types.CREATE_POST:
      return action.createpost;
    default:
      return state;
  }
}
