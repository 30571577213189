import React, { Fragment, useRef, useState } from "react";
import "./DoffoVarificationModal.css";
import {
  Form,
  Button,
  Modal,
  Tooltip,
  Overlay,
  Row,
  Col,
} from "react-bootstrap";
import frontImg from "../../assets/images/FrontFace.jpg";
import sideImg from "../../assets/images/SideFace.jfif";
import semiSideImg from "../../assets/images/semiSide.png";
import { IconButton } from "@mui/material";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { loadToasterData } from "../../actions/baseActions";
import IdCardImage from "../../assets/images/IdCard.png";
import { doffoVerify } from "../../actions/SIdebarAction";

function DoffoVarificationModal({ show, onHide }) {
  const fileInput = useRef();
  const idFileInput = useRef();

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // All Selectors
  const { DOFFO_VERIFY_LOADER } = useSelector((state) => ({
    DOFFO_VERIFY_LOADER: state?.sidebarReducer?.doffoVerifyLoader,
  }));

  //   All State
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedIdProofFile, setSelectedIdProofFile] = useState([]);
  const [switchModal, setSwitchModal] = useState(false);
  const [userImageError, setUserImageError] = useState("");
  const [userIdError, setUserIdError] = useState("");

  const imageHandelChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size > 10485760) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_IS_TOO_BIG"
            )}`,
            "error",
            true
          )
        );
      } else if (
        e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "image/png"
      ) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_NOT_SUPPORTED"
            )}`,
            "error",
            true
          )
        );
      } else {
        const fileArray = Array.from(e.target.files).map(
          (file) =>
            // URL.createObjectURL(file)
            file
        );

        if (fileArray.length > 0) {
          setSelectedFile((prevImages) => prevImages.concat(fileArray));
          Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
        }
      }
    }
  };
  const deleteUploadedImages = (index) => {
    const copyPostArray = Object.assign([], selectedFile);
    copyPostArray.splice(index, 1);
    setSelectedFile(copyPostArray);
  };
  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      // source.map((photo, index) => {
      return (
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            borderRadius: "16px",
          }}
          key={index}
        >
          <IconButton
            style={{
              position: "absolute",
              top: "3px",
              right: "14px",
              fontSize: "14px",
              backgroundColor: "#ffffff",
            }}
            onClick={(e) => deleteUploadedImages(index)}
          >
            <CloseIcon style={{ fontSize: "18px", color: "#000" }} />
          </IconButton>
          <img
            src={URL.createObjectURL(photo)}
            key={photo}
            alt="NO_IMG"
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "16px",
            }}
          />
        </div>
      );
    });
  };

  const IdHandelChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size > 10485760) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_IS_TOO_BIG"
            )}`,
            "error",
            true
          )
        );
      } else if (
        e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "image/png"
      ) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_NOT_SUPPORTED"
            )}`,
            "error",
            true
          )
        );
      } else {
        const fileArray = Array.from(e.target.files).map(
          (file) =>
            // URL.createObjectURL(file)
            file
        );

        if (fileArray.length > 0) {
          setSelectedIdProofFile((prevImages) => prevImages.concat(fileArray));
          Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
        }
      }
    }
  };
  const deleteUploadedIdImages = (index) => {
    const copyPostArray = Object.assign([], selectedIdProofFile);
    copyPostArray.splice(index, 1);
    setSelectedIdProofFile(copyPostArray);
  };
  const renderIdPhotos = (source) => {
    return source.map((photo, index) => {
      // source.map((photo, index) => {
      return (
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
            borderRadius: "16px",
          }}
          key={index}
        >
          <IconButton
            style={{
              position: "absolute",
              top: "3px",
              right: "14px",
              fontSize: "14px",
              backgroundColor: "#ffffff",
            }}
            onClick={(e) => deleteUploadedIdImages(index)}
          >
            <CloseIcon style={{ fontSize: "18px", color: "#000" }} />
          </IconButton>
          <img
            src={URL.createObjectURL(photo)}
            key={photo}
            alt="NO_IMG"
            style={{
              height: "100%",
              width: "100%",
              borderRadius: "16px",
            }}
          />
        </div>
      );
    });
  };

  const toggleSwitchModal = () => {
    const isValid = validationForImage();
    if (isValid) {
      setSwitchModal(!switchModal);
    }
  };

  const validationForImage = () => {
    let userImageError = "";

    if (selectedFile?.length === 0) {
      userImageError = t("PLEASE_UPLOAD_YOUR_IMAGE");
    }

    if (userImageError) {
      setUserImageError(userImageError);
      return false;
    } else {
      return true;
    }
  };

  const closeModal = () => {
    onHide();
    setSelectedFile([]);
    setSelectedIdProofFile([]);
    setSwitchModal(false);
    setUserImageError("");
    setUserIdError("");
  };

  const handelDoffoVerify = () => {
    const isValid = validationForId();

    if (isValid) {
      setUserIdError("");
      const formData = new FormData();
      if (selectedFile.length > 0) {
        selectedFile.map((e) => {
          formData.append("photo", e);
        });
      }
      if (selectedIdProofFile.length > 0) {
        selectedIdProofFile.map((e) => {
          formData.append("id_proof", e);
        });
      }
      dispatch(doffoVerify(formData, closeModal));
    }
  };

  const validationForId = () => {
    let userIdError = "";

    if (selectedIdProofFile?.length === 0) {
      userIdError = t("PLEASE_UPLOAD_YOUR_ID_PROOF");
    }
    if (userIdError) {
      setUserIdError(userIdError);
      return false;
    } else {
      return true;
    }
  };

  return (
    <Modal
      show={show}
      onHide={() => false}
      className="change_passsord_modal"
      // size="lg"
    >
      <Modal.Header>
        <h4 className="mb-0">{t("DOFFO_VERIFICATION")}</h4>
        <button
          className="crose_btn"
          onClick={() => {
            onHide();
            setSelectedFile([]);
            setSelectedIdProofFile([]);
            setSwitchModal(false);
            setUserImageError("");
            setUserIdError("");
          }}
        >
          <img src="./assets/images/crose.png" alt="No_IMAGE" />
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="change_password_main">
          {!switchModal ? (
            <Fragment>
              <p className="mb-0 example-main-para">{t("UPLOAD_YOUR_IMAGE")}</p>
              <p className="mb-0 fw-bold ">{t("EXAMPLE")}</p>
              <div className="example-images">
                <span className="example-image-icon">
                  <img src={frontImg} alt="NO_IMG" className="example-image" />
                  <i className="fa fa-check example-icon-green" />
                </span>
                <span className="example-image-icon">
                  <img
                    src={semiSideImg}
                    alt="NO_IMG"
                    className="example-image"
                  />
                  <i className="fa fa-times example-icon" />
                </span>
                <span className="example-image-icon">
                  <img src={sideImg} alt="NO_IMG" className="example-image" />
                  <i className="fa fa-times example-icon" />
                </span>
              </div>
              <div className="mt-3">
                <p className="example-para">
                  {" "}
                  <i className="fa fa-check success para-icon" />
                  {t("TAKE_A_SELFIE_OF_YOURSELF_WITH_A_NATURAL_EXPRESSION")}
                </p>
                <p className="example-para">
                  {" "}
                  <i className="fa fa-check success para-icon" />
                  {t("MAKE_SURE_WHOLE_FACE_IS_VISIBLE")}.
                </p>
                <p className="example-para">
                  {" "}
                  <i className="fa fa-times danger para-icon" />
                  {t("DO_NOT_CROP_YOUR_ID_OR_USE_SCREENSHOT")}
                </p>
                <p className="example-para">
                  {" "}
                  <i className="fa fa-times danger para-icon" />
                  {t("DO_NOT_HIDE_OR_ALTER_PART_OF_YOUR_FACE")}
                </p>
              </div>
              <div>
                <section className="create_post_sec">
                  <div className="post_form" noValidate autoComplete="off">
                    <Row>
                      <Col lg={12} md={12}>
                        <div
                          className="upload_post"
                          style={{ position: "relative", height: "261px" }}
                        >
                          <input
                            type="file"
                            name="profilePicture"
                            accept="image/jpeg, image/png"
                            style={{ display: "none" }}
                            ref={fileInput}
                            onChange={(e) => imageHandelChange(e)}
                            // multiple
                            onClick={(e) => (e.target.value = null)}
                          />
                          {selectedFile.length === 0 ? (
                            <span
                              onClick={() => fileInput.current.click()}
                              style={{ cursor: "pointer" }}
                            >
                              <AddAPhotoIcon
                                color="primary"
                                fontSize="medium"
                              />{" "}
                              {t("UPLOAD_YOUR_IMAGE")}
                            </span>
                          ) : (
                            <>{renderPhotos(selectedFile)}</>
                          )}
                        </div>
                        {userImageError && (
                          <p
                            className="danger mb-0 mt-1"
                            style={{ fontSize: "14px" }}
                          >
                            {userImageError}
                          </p>
                        )}
                      </Col>
                    </Row>
                  </div>
                </section>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <section className="create_post_sec">
                <div className="post_form" noValidate autoComplete="off">
                  <Row>
                    <p className="mb-0 example-main-para">
                      {t("UPLOAD_A_PHOTO_OF_YOUR_ID_CARD")}
                    </p>
                    <div className="example-images">
                      <span className="example-image-icon">
                        <img
                          src={IdCardImage}
                          alt="NO_IMG"
                          className="example-image-id"
                        />
                        <i className="fa fa-check example-icon-green" />
                      </span>
                      <span className="example-image-icon">
                        <img
                          src={IdCardImage}
                          alt="NO_IMG"
                          className="example-image-id"
                        />
                        <i className="fa fa-times example-icon" />
                      </span>
                      <span className="example-image-icon">
                        <img
                          src={IdCardImage}
                          alt="NO_IMG"
                          className="example-image-id"
                        />
                        <i className="fa fa-times example-icon" />
                      </span>
                      <span className="example-image-icon">
                        <img
                          src={IdCardImage}
                          alt="NO_IMG"
                          className="example-image-id"
                        />
                        <i className="fa fa-times example-icon" />
                      </span>
                    </div>
                    <div className="mt-3">
                      <p className="example-para">
                        {" "}
                        <i className="fa fa-check success para-icon" />
                        {t("GOVERNMENT_ISSUED")}
                      </p>
                      <p className="example-para">
                        {" "}
                        <i className="fa fa-check success para-icon" />
                        {t("ORIGINAL_FILL_SIZE_UNEDITED_DOCS")}
                      </p>
                      <p className="example-para">
                        {" "}
                        <i className="fa fa-check success para-icon" />
                        {t(
                          "PLACE_DOCUMENTS_AGAINST_SINGLE_COLOURED_BACKGROUND"
                        )}
                        .
                      </p>
                      <p className="example-para">
                        {" "}
                        <i className="fa fa-check success para-icon" />
                        {t("READABLE_WELL_LIT_COLOURED_IMAGES")}.
                      </p>
                      <p className="example-para">
                        {" "}
                        <i className="fa fa-times danger para-icon" />
                        {t("NO_BLACK_AND_WHITE_IMAGES")}
                      </p>
                      <p className="example-para">
                        {" "}
                        <i className="fa fa-times danger para-icon" />
                        {t("NO_EDITED_OR_EXPIRED_DOCUMENTS")}
                      </p>
                    </div>
                    <Col lg={12} md={12}>
                      <div
                        className="upload_post"
                        style={{ position: "relative", height: "261px" }}
                      >
                        <input
                          type="file"
                          name="IdProof"
                          accept="image/jpeg, image/png"
                          style={{ display: "none" }}
                          ref={idFileInput}
                          onChange={(e) => IdHandelChange(e)}
                          // multiple
                          onClick={(e) => (e.target.value = null)}
                        />
                        {selectedIdProofFile?.length === 0 ? (
                          <span
                            onClick={() => idFileInput.current.click()}
                            style={{ cursor: "pointer" }}
                          >
                            <AddAPhotoIcon color="primary" fontSize="medium" />{" "}
                            {t("UPLOAD_YOUR_ID_PROOF")}
                          </span>
                        ) : (
                          <>{renderIdPhotos(selectedIdProofFile)}</>
                        )}
                      </div>
                      {userIdError && (
                        <p
                          className="danger mb-0 mt-1"
                          style={{ fontSize: "14px" }}
                        >
                          {userIdError}
                        </p>
                      )}
                    </Col>
                  </Row>
                </div>
              </section>
            </Fragment>
          )}
        </div>
        <div className="login_form">
          {!switchModal ? (
            <Button className="theme_btn" onClick={() => toggleSwitchModal()}>
              {t("NEXT")}
            </Button>
          ) : (
            <Row>
              <Col md={6}>
                <Button
                  className="theme_btn"
                  onClick={() => {
                    setUserImageError("");
                    setSwitchModal(false);
                    setUserIdError("");
                  }}
                >
                  {t("BACK")}
                </Button>
              </Col>
              <Col md={6}>
                <Button
                  className="theme_btn"
                  onClick={() => handelDoffoVerify()}
                  disabled={DOFFO_VERIFY_LOADER}
                  style={DOFFO_VERIFY_LOADER ? { cursor: "not-allowed" } : {}}
                >
                  {t("SUBMIT")}{" "}
                  {DOFFO_VERIFY_LOADER && (
                    <i
                      className="fa fa-spinner fa-spin"
                      style={{
                        color: "#fff",
                        fontSize: "14px",
                        marginLeft: "1px",
                      }}
                    />
                  )}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default DoffoVarificationModal;
