import { React, useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import {
  productReview,
  getHomeProductDetail,
  loadCouponData,
  getAddressList,
  setDefaultAddress,
} from "../../actions/HomeAction";
import { getCard } from "../../actions/UserAction";

import { TextFormate } from "../../Component/TextFormate/TexFormate";
import { loadToasterData } from "../../actions/baseActions";
import { useForm } from "react-hook-form";
import {
  imageNotAvailable,
  proFees,
  crose,
  mapImg,
} from "../../assets/images/index";
import { ErrorMessage } from "@hookform/error-message";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@material-ui/core/IconButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormHelperText from "@mui/material/FormHelperText";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import SubmitButton from "../../Component/Button";
import { checkSpace } from "../../utils/helpers";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import CryptoJS from "crypto-js";
import axios from "axios";
import { useFormik } from "formik";

import config from "../../Component/Urway/index.json";
import moment from "moment";

const generateHashSHA256 = (hashSequence) => {
  // hashSequence = trackid | terminalId | password | secret | amount | currency
  let hash = CryptoJS.SHA256(hashSequence).toString();
  return hash;
};
const emailRegexp = new RegExp(
  "^[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*@[a-zA-Z0-9]+([a-zA-Z0-9_.\\$&-]+)*\\.([a-zA-Z0-9]{2,4})$"
);
const ipRegexp = new RegExp(
  "^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?).(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$"
);
const amountRegexp = new RegExp("^[0-9]?((.[0-9]+)|([0-9]+(.[0-9]+)?))$");

let resParameter = {};
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#0aaacb",
    },
    "& .Mui-focused": {
      color: "#0aaacb",
    },
  },
});

const { REACT_APP_PUBLIC_URL } = process.env;

function ProductReview(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const productId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  const [currentImg, setCurrentImg] = useState({});

  const [productLocation, setProductLocation] = useState([]);
  // All Selectors
  const { PRODUCT_DETAIL, USER_INFO, userInfo, setting } = useSelector(
    (state) => ({
      dialog: state.dialog,
      selectedLang: state.selectedLang,
      PRODUCT_DETAIL: state.homeReducer.homeProductDetails,
      USER_INFO: state.userInfo,
      LOADING_REPORT: state?.homeReducer?.submitReasonLoader,
      userInfo: state.userInfo,
      setting: state.setting,
    })
  );

  useEffect(() => {
    const data = { id: productId };
    dispatch(getHomeProductDetail(data, setCurrentImg, setProductLocation));
  }, [dispatch, productId]);

  useEffect(() => {
    var post_id = productId;
    const request = {
      post_id,
    };
    dispatch(productReview(request, returnData));
  }, [dispatch, productId]);
  const [getReviewData, setReviewData] = useState("");
  const returnData = (data) => {
    setReviewData(data);
    localStorage.setItem("addressID", data.shippingTo._id);
  };

  const confirmPurchase = () => {
    if (!getReviewData.shippingTo._id) {
      dispatch(loadToasterData(t("PRODUCT_REVIEW_SHIPPING_TO"), "error", true));
    }

    if (couponData !== 0) {
      localStorage.setItem("amount", getCouponData.discountedAmount);
    } else {
      localStorage.setItem("amount", getReviewData.item.total);
    }

    if (couponData !== 0) {
      localStorage.setItem("coupon", couponData);
    } else {
      localStorage.setItem("coupon", "");
    }
    localStorage.setItem(
      "directDelivery",
      PRODUCT_DETAIL?.seller?.allowDirectDelivery
    );
    setShowModelCard(true);
  };

  /* Coupon Code */
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [opencoupon, setCoupon] = useState(false);
  const handleClickcoupon = () => {
    setCoupon(true);
  };

  const handleClosecoupon = () => {
    setCoupon(false);
  };

  localStorage.setItem("coupon_code", "");
  const [couponData, couponCodeapply] = useState(0);
  const [couponData1, couponCodeapply1] = useState(0);
  const onSubmit = (data) => {
    couponCodeapply1(data.coupon);
    sessionStorage.setItem("coupon", data.coupon);
    var type = "APPLY";
    var processing_fee = getReviewData.item.Processing_fee;
    var shipping_fee = getReviewData.item.shipping_fee;
    var amount = getReviewData.item.price;
    var code = data.coupon;
    const request = { type, processing_fee, shipping_fee, amount, code };

    dispatch(loadCouponData(request, returnCouponData));
  };
  const [getCouponData, setCouponData] = useState(0);
  const [getDiscountAfterAmount, setDiscountTotalAmount] = useState(0);
  const [verificationFees, setVerificationFees] = useState(0);
  const [couponDatacharge, couponCodeamount] = useState(0);
  const returnCouponData = (data) => {
    couponCodeamount(data.discountAmount);
    setDiscountTotalAmount(data.discountedAmount);
    let datacoup = sessionStorage.getItem("coupon");
    couponCodeapply(datacoup);
    setCouponData(data);
    setCoupon(false);
  };
  const couponCoderemove = () => {
    couponCodeapply(0);
    couponCodeamount(0);
    setCouponData(0);
    setDiscountTotalAmount(0);
  };

  /* Coupon Code */
  /* Address */
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [getAddAddressModal, setAddAddressModal] = useState(false);
  useEffect(() => {
    const request = {};
    dispatch(getAddressList(request, returnDataAddress));
  }, [dispatch]);
  const [getAddressListData, setAddressListData] = useState("");
  const returnDataAddress = (data) => {
    setAddressListData(data);
  };
  const handleAddressSelect = (data) => {
    var id = data;
    const params = { id };
    dispatch(setDefaultAddress(params));

    var post_id = productId;
    const request = { post_id };
    dispatch(productReview(request, returnData));
    setShowAddressModal(false);
  };
  /* Address */
  /* Card */
  /* Card List */
  const [showModelCard, setShowModelCard] = useState(false);
  const handleCloseCard = () => {
    setShowModelCard(false);
  };

  useEffect(() => {
    const request = {};
    dispatch(getCard(request, returnDataCard));
  }, [dispatch, setShowModelCard]);

  useEffect(() => {
    if (getReviewData?.item?.verification_fee) {
      setVerificationFees(getReviewData?.item?.verification_fee);
    } else {
      setVerificationFees(0);
    }
  }, [getReviewData?.item?.verification_fee]);

  const [getCardList, setCardList] = useState("");
  const returnDataCard = (data) => {
    setCardList(data);
  };

  const [getCardActiveState, setCardActiveState] = useState(
    localStorage.getItem("cardId")
  );

  const handleActiveCard = (data) => {
    setCardActiveState(data._id);
    localStorage.setItem("card_token", data.cardToken);
    localStorage.setItem("cardId", data._id);
  };

  const handleCardBoostApply = () => {
    if (localStorage.getItem("card_token") === null) {
      dispatch(loadToasterData(t("PLEASE_SELECT_CARD"), "error", true));
    } else {
      history.push(`/buy-now/${productId}`);
    }
  };
  /* Card List */
  /* Add Card */
  const [double, setDouble] = useState(false);
  let index = 0;
  let count = 0;
  let queryParam = "";
  let trackId = moment().unix();
  const initialValues = {
    firstName: userInfo.first_name,
    lastName: userInfo.last_name,
    address: "",
    city: "",
    state: "",
    zipCode: "",
    phoneNumber: userInfo.mobile_number,
    trackid: trackId.toString(),
    terminalId: config.terminalId,
    customerEmail: userInfo.email,
    action: "12",
    merchantIp: "111.93.58.10",
    password: config.password,
    currency: "SAR",
    country: "SA",
    transid: "",
    amount: "1",
    tokenOperation: "A",
    cardToken: "",
    tokenizationType: "1",
    requestHash: "",
    udf1: "",
    udf2: REACT_APP_PUBLIC_URL,
    udf3: "",
    udf4: "",
    udf5: "",
  };

  const validate = (values) => {
    let errors = {};
    if (!values.country) {
      errors.errorCountry = "Country Code Is Required";
      // isDisabel=false;
    } else if ((values.country + "").length !== 2) {
      errors.errorCountry = "Enter Valid Country Code";
      // isDisabel=false;
    }
    if (!emailRegexp.test(values.customerEmail)) {
      errors.errorEmail = "Invalid email";
      // isDisabel=false;
    }
    if (!ipRegexp.test(values.merchantIp)) {
      errors.errorMerchantIp = "Invalid Merchant IP";
      // isDisabel=false;
    }
    if (!amountRegexp.test(values.amount)) {
      errors.errorAmount = "Invalid Amount";
      // isDisabel=false;
    }
    if (!values.trackid) {
      errors.errorTrackid = "Track ID Is Required";
      // isDisabel=false;
    }
    if (!values.currency) {
      errors.errorCurrency = "Currency Is Required";
      // isDisabel=false;
    } else if (values.currency.length < 3) {
      errors.errorCurrency = "Enter Valid Currency";
      // isDisabel=false;
    }
    if (!values.action) {
      errors.errorAction = "Please Select Action";
      // isDisabel=false;
    }
    if (values.action + "" === "12")
      if (values.tokenOperation + "" === "") {
        errors.errorTokenOperation = "Please Select Token Operation";
        // isDisabel=false;
      }
    if (
      (values.action + "" === "2") |
      (values.action + "" === "5") |
      (values.action + "" === "3") |
      (values.action + "" === "9") |
      (values.action + "" === "10") |
      (values.action + "" === "6")
    ) {
      if (!values.transid) {
        errors.errorTransid = "Transaction ID Is Required";
        // isDisabel=false;
      }
    }

    return errors;
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      localStorage.setItem("pageTypeSet", "buy-now");
      localStorage.setItem("productId", productId);

      // if(!isDisabel)
      //     setDouble(false)
      console.log("values", values);
      setDouble(true);
      values.country = (values.country + "").toUpperCase();
      values.currency = (values.currency + "").toUpperCase();
      //console.log("dropdown value : ",values.action);
      localStorage.setItem("getCurrency", (values.currency + "").toUpperCase());
      localStorage.setItem("isDirectReceipt", "false");
      let hashSequence = generateHashSHA256(
        values.trackid +
          "|" +
          values.terminalId +
          "|" +
          config.password +
          "|" +
          config.merchantKey +
          "|" +
          values.amount +
          "|" +
          values.currency
      );

      values.requestHash = hashSequence;
      //console.log("values : ",values)
      axios
        .post(config.service_url, values)
        .then((res) => {
          //console.log("targetUrl "+res.data.targetUrl)
          //console.log(res.data)
          resParameter = res.data;
          //console.log("resParameter ",resParameter)
          if (resParameter.targetUrl + "" === "null") {
            for (let [key, value] of Object.entries(resParameter)) {
              index = ++index;
              console.log(`${key} ${value}`);
            }
            for (let [key, value] of Object.entries(resParameter)) {
              count = ++count;
              queryParam = queryParam + key + "=" + value;
              if (count < index) queryParam = queryParam + "&";
            }
            // console.log("index : ", index, queryParam);
            window.location.assign(
              window.location.origin.toString() + "/paymentStatus?" + queryParam
            );
            setDouble(true);
          } else {
            console.log("res.data.targetUrl", res.data);
            // handleAddCard(res.data);
            window.location.assign(
              res.data.targetUrl.replace("?", "") +
                "?paymentid=" +
                res.data.payid
            );
            setDouble(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    validate,
  });

  const handleVerificationChange = (event) => {
    if (event.target.checked) {
      if (getDiscountAfterAmount != 0) {
        let verificationFees =
          getDiscountAfterAmount * (setting.verification_fee / 100);
        setVerificationFees(verificationFees);
      } else {
        let verificationFees =
          getReviewData.item.price * (setting.verification_fee / 100);
        setVerificationFees(verificationFees);
      }
    } else {
      setVerificationFees(0);
    }
  };

  /* Add Card */
  return (
    <div>
      <section className="confirm_order_section">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">
                  {t("HOME")}
                  <i className="fas fa-chevron-right"></i>
                </Link>
              </li>
              <li>
                <span>{PRODUCT_DETAIL?.title}</span>
              </li>
              {/* <li>
                <a href="#">
                  Nike Joyride Shoes<i className="fas fa-chevron-right"></i>
                </a>
              </li>
              <li>
                <span>Make Offer</span>
              </li> */}
            </ul>
          </div>

          <div className="confirm_order_wrapper">
            <Row>
              <Col lg={9}>
                <div className="outline_wrapper">
                  <h4 className="mb-2 mb-md-3">{t("REVIEW_CONFIRM_ORDER")}</h4>
                  <Row>
                    <Col sm={6}>
                      <div className="order_detail_box">
                        <div className="order_detail_head">
                          <h4>
                            <strong>{t("SHIPPING_FROM_REVIEW")} </strong>
                          </h4>
                        </div>

                        <div className="order_caption">
                          {getReviewData &&
                            getReviewData.item &&
                            getReviewData.item.shippingFrom &&
                            getReviewData.item.shippingFrom.address}
                        </div>
                      </div>
                    </Col>
                    <Col sm={6}>
                      <div className="order_detail_box">
                        <div className="order_detail_head">
                          <h4>
                            {" "}
                            <strong>{t("SHIPPING_TO_REVIEW")}</strong>
                          </h4>
                          <Button
                            className="theme_btn edit-address"
                            onClick={() => setShowAddressModal(true)}
                          >
                            <EditIcon />
                            <span>Edit</span>
                          </Button>
                        </div>

                        <div className="order_caption">
                          <strong>
                            {USER_INFO.first_name} {USER_INFO.last_name}
                          </strong>
                          <p>
                            {getReviewData &&
                              getReviewData.shippingTo &&
                              getReviewData.shippingTo.address &&
                              getReviewData.shippingTo.address}
                          </p>
                        </div>
                      </div>
                    </Col>
                    {/*   <Col sm={6}>
                      <div className="order_detail_box">
                        <div className="order_detail_head">
                          <h4>Shipping To</h4>
                        </div>

                        <div className="order_caption">
                          <strong>Mohammed Ali</strong>
                          <p>
                            4256 Ibn Katheer, Street, Rawdah District, Unit
                            number 2, Jeddah 22230{" "}
                          </p>
                        </div>
                      </div>
                    </Col>

                    <Col sm={6}>
                      <div className="order_detail_box">
                        <div className="order_detail_head">
                          <h4>Shipping To</h4>
                        </div>

                        <div className="order_caption">
                          <strong>Mohammed Ali</strong>
                          <p>
                            4256 Ibn Katheer, Street, Rawdah District, Unit
                            number 2, Jeddah 22230{" "}
                          </p>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <div className="order_detail_box">
                        <div className="order_detail_head">
                          <h4>
                            <strong>{t("APPLY_COUPON_CODE")} </strong>
                          </h4>
                        </div>

                        {couponData !== 0 ? (
                          <Row className="applied_coupon">
                            <Col md={12}>
                              <span>
                                <strong>Applied Coupon : {couponData}</strong>{" "}
                              </span>{" "}
                              <IconButton
                                edge="end"
                                className="remove-button"
                                name={couponData}
                                onClick={couponCoderemove}
                                aria-label="delete"
                              >
                                <DeleteIcon />{" "}
                              </IconButton>{" "}
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col md={12}>
                              <Button
                                variant="outlined"
                                className="theme_btn btn btn-primary"
                                color="primary"
                                onClick={handleClickcoupon}
                              >
                                <span></span>Apply Coupon
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </div>
                    </Col>
                    {PRODUCT_DETAIL?.seller?.allowDirectDelivery &&
                      PRODUCT_DETAIL?.seller
                        ?.allowDirectDeliveryVerification && (
                        <Col sm={6}>
                          <div className="order_detail_box">
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                onChange={handleVerificationChange}
                                label={t("ITEM_VERIFICATION")}
                              />
                            </FormGroup>
                            <FormHelperText>
                              {t("ITEM_VERIFICATION_MESSAGE")}
                            </FormHelperText>
                            <FormHelperText>
                              {t("ITEM_VERIFICATION_INFO")}
                            </FormHelperText>
                          </div>
                        </Col>
                      )}
                  </Row>

                  <div className="confirm_order_info">
                    <div className="product_item_horizantal">
                      <div className="d-flex align-items-center">
                        <figure>
                          {PRODUCT_DETAIL &&
                          PRODUCT_DETAIL.image &&
                          PRODUCT_DETAIL.image.length > 0 ? (
                            <a href="#/">
                              <img
                                src={PRODUCT_DETAIL.image[0].originalUrl}
                                alt={PRODUCT_DETAIL?.title}
                              />
                            </a>
                          ) : (
                            <a href="#/">
                              <img
                                src={imageNotAvailable}
                                alt={PRODUCT_DETAIL?.title}
                              />
                            </a>
                          )}
                        </figure>
                        <div className="offer_product_detail">
                          <h3>{PRODUCT_DETAIL?.title}</h3>
                          <small>Item Price</small>
                          <span className="price">
                            {TextFormate(PRODUCT_DETAIL?.price, 2)} {t("SR")}
                            <span className="off_price">
                              {PRODUCT_DETAIL?.discountPrice > 0 &&
                                PRODUCT_DETAIL?.discountPrice}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="listing_brands_new">
                      <div className="listing_brand_cat">
                        <ul>
                          <li>
                            {t("ITEM_CONDITION")}:
                            <span>{PRODUCT_DETAIL?.conditions}</span>
                          </li>
                          <li>
                            {t("CATEGORY")}:
                            <span>{PRODUCT_DETAIL?.categories}</span>
                          </li>
                        </ul>
                      </div>
                      <div className="listing_brand_cat">
                        <ul>
                          {PRODUCT_DETAIL?.brands && (
                            <li>
                              {t("BRAND")}:<span>{PRODUCT_DETAIL?.brands}</span>
                            </li>
                          )}
                          {PRODUCT_DETAIL?.models && (
                            <li>
                              {t("MODAL")}:<span>{PRODUCT_DETAIL?.models}</span>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="listing_brand_cat">
                        <ul>
                          {PRODUCT_DETAIL?.item_information?.weight && (
                            <li>
                              {t("WEIGHT")}:
                              <span>
                                {" "}
                                {PRODUCT_DETAIL?.item_information?.weight}
                                {t("KG")}
                              </span>
                            </li>
                          )}
                          {PRODUCT_DETAIL?.item_information?.height && (
                            <li>
                              {t("HEIGHT")}:
                              <span>
                                {PRODUCT_DETAIL?.item_information?.height}
                                {t("cm")}
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="listing_brand_cat">
                        <ul>
                          {PRODUCT_DETAIL?.item_information?.length && (
                            <li>
                              {t("LENGTH")}:
                              <span>
                                {" "}
                                {PRODUCT_DETAIL?.item_information?.length}
                                {t("CM")}
                              </span>
                            </li>
                          )}
                          {PRODUCT_DETAIL?.item_information?.width && (
                            <li>
                              {t("WIDTH")}
                              <span>
                                {" "}
                                {PRODUCT_DETAIL?.item_information?.width}
                                {t("CM")}
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    {PRODUCT_DETAIL?.description && (
                      <div className="listing_condition">
                        <div className="description">
                          <h3 className="seller_titles"> {t("DESCRIPTION")}</h3>
                          <p>
                            {PRODUCT_DETAIL?.description !== null
                              ? PRODUCT_DETAIL?.description
                              : t("NO_DESCRIPTION")}
                            .{" "}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Col>

              <Col lg={3}>
                <div className="order_summary_sidebar">
                  <h3>{t("ORDER_SUMMERY")}</h3>
                  <ul className="order_summary_list">
                    <li>
                      <span>{t("ITEM_PRICE_REVIEW")}:</span>
                      <strong>
                        {getReviewData &&
                          getReviewData.item &&
                          TextFormate(getReviewData.item.price, 2)}{" "}
                        {t("SR")}
                      </strong>
                    </li>
                    <li>
                      <span>
                        Discount<small>(coupon applied)</small>
                      </span>
                      {couponDatacharge !== 0 ? (
                        <strong> {TextFormate(couponDatacharge, 2)} SR</strong>
                      ) : (
                        <strong>0.00 SR</strong>
                      )}
                    </li>
                    <li>
                      <span>{t("SHIPPING_FEE_REVIEW")}:</span>
                      <strong>
                        {getReviewData &&
                          getReviewData.item &&
                          TextFormate(getReviewData.item.shipping_fee, 2)}{" "}
                        {t("SR")}
                      </strong>
                    </li>

                    <li>
                      <span>
                        {t("VERIFICATION_FEES")}
                        <span className="d-inline-block px-2">
                          <img src={proFees} alt="processing_fees" />
                        </span>
                      </span>
                      <strong>
                        {TextFormate(verificationFees ?? 0, 2)}
                        &nbsp;{t("SR")}
                      </strong>
                    </li>
                  </ul>

                  <div className="final_price">
                    <div className="final_costing_head">
                      <strong>{t("FINAL_PRICE")}</strong>
                      <span className="price">
                        {getDiscountAfterAmount !== 0
                          ? TextFormate(
                              getDiscountAfterAmount + verificationFees,
                              2
                            )
                          : getReviewData &&
                            getReviewData.item &&
                            TextFormate(getReviewData.item.total, 2)}
                        &nbsp;{t("SR")}
                      </span>
                    </div>
                    <div className="description">
                      <p>{t("TERMS_TEXT")}</p>
                      <a href="#/">{t("TERMS_SERVICES_REVIEW")}</a>
                    </div>
                  </div>

                  <Button className="theme_btn" onClick={confirmPurchase}>
                    {t("CONFIRM_PURCHASE")}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>

      {/*  Cupon Code */}
      <Dialog
        open={opencoupon}
        onClose={handleClosecoupon}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <strong>Apply Coupon</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <Form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
            <Row>
              <Col md={12}>
                <Form.Group controlId="formBasicTitle">
                  <TextField
                    required
                    label="Enter Coupon Code"
                    defaultValue=""
                    autoFocus={true}
                    fullWidth
                    className={!errors.coupon ? classes.root : "w-100"}
                    error={errors.coupon ? true : false}
                    name="coupon"
                    {...register("coupon", {
                      required: "Please enter coupon code.",
                      minLength: {
                        value: 3,
                        message:
                          "Coupon code should contain at least 3 characters.",
                      },
                      maxLength: {
                        value: 10,
                        message: "Title should not exceed 10 characters.",
                      },
                      validate: {
                        isSpace: (value) =>
                          checkSpace(value) ||
                          "Remove trailing spaces from question.",
                      },
                    })}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="coupon"
                    render={({ message }) => (
                      <p className="error-message">{message}</p>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md={2}></Col>
              <Col md={5}>
                <SubmitButton title="Apply" />
              </Col>
              <Col md={5}>
                <Button
                  onClick={handleClosecoupon}
                  variant="outlined"
                  className="cancel-button"
                  color="secondary"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </DialogContent>
      </Dialog>
      {/*  Cupon Code */}
      {/* Address Models */}
      <Dialog
        open={showAddressModal}
        onClose={() => setShowAddressModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="with-crose">
          {" "}
          Select Address
          <button
            className="crose_btn"
            onClick={() => setShowAddressModal(false)}
          >
            <img src={crose} alt="img" />
          </button>
        </DialogTitle>

        <DialogContent className="pt-2">
          {getAddressListData &&
            getAddressListData.map((row, key) => (
              <div className="address-list-div rounded-3 p-lg-3 p-1" key={key}>
                <Row>
                  <Col md={8}>
                    <Row>
                      <Col md={4}>
                        <img src={mapImg} alt="img" className="rounded-3 p-0" />
                      </Col>
                      <Col md={8} className="address-col pt-0">
                        <h5>{row.address}</h5>
                        <p className="mb-0">
                          {" "}
                          {row.building} {row.city} {row.district}{" "}
                          {row.postal_code}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={4} className="address-actions">
                    {/*<Button className="">

                        <span>Edit</span>
              </Button> */}
                    {row.is_default !== true && (
                      <Button
                        className=""
                        onClick={() => handleAddressSelect(row._id)}
                      >
                        <span>Select</span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </div>
            ))}
        </DialogContent>
      </Dialog>

      {/* Add Address */}
      <Modal
        show={getAddAddressModal}
        onHide={() => setAddAddressModal(false)}
        className="add_card"
      >
        <button className="crose_btn" onClick={() => setAddAddressModal(false)}>
          <img src={crose} alt="img" />
        </button>
        <Modal.Body>
          <div className="add_new_card">
            <h4>Add Shipping Details</h4>
          </div>
        </Modal.Body>
      </Modal>

      {/* Address Models */}
      {/* Card */}
      <Dialog
        open={showModelCard}
        onClose={handleCloseCard}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="with-crose">
          {" "}
          {t("SELECT_CARD")}
          <button className="crose_btn" onClick={handleCloseCard}>
            <img src={crose} alt="img" />
          </button>
        </DialogTitle>

        <DialogContent className="pt-2">
          {getCardList.card && getCardList.card.length > 0 ? (
            <ul className="remaining-boost work_list p-0 m-0">
              {getCardList.card &&
                getCardList.card.map((item, key) => (
                  <li
                    key={key}
                    className={`${
                      getCardActiveState === item._id ? "active" : ""
                    }`}
                  >
                    <input
                      type="radio"
                      className="radio-remaining"
                      onClick={() => handleActiveCard(item)}
                      value={item.title}
                      name="remaining_boost"
                    />
                    <span className="title-r-boost ms-0">
                      <img src={item.cardBrand} alt={key} />{" "}
                      <strong>{item.maskedCardNo}</strong>
                    </span>
                  </li>
                ))}
            </ul>
          ) : (
            <h3 className="boost-h3s">{t("CURRENTLY_CARD_NOT_AVAILABLE")}</h3>
          )}

          <div className="d-flex align-items-center mt-3 mt-md-4 subscription-mod">
            <a
              href="#/"
              onClick={handleCardBoostApply}
              className="theme_btn btn rounded text-white  p-2 p-md-3 h-auto"
            >
              {t("CONFIRM_PURCHASE")}
            </a>
            <Button
              type="submit"
              disabled={double}
              className="btn rounded text-white  ms-2 p-2 p-md-3 h-auto card-add-cs text-white"
              onClick={() => formik.handleSubmit()}
            >
              {t("ADD_CARD")}
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {/* Card */}
    </div>
  );
}

export default ProductReview;
