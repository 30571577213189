import React, { lazy, Suspense, Fragment } from "react";
import { Route, Switch, Redirect, useHistory } from "react-router-dom";
import SelectLang from "../Component/SelectLang";
import { RoutesPage, RoutesUser, MobilePage } from "../Route/route";
import Loading from "../Component/PreLoader";
import { useDispatch, useSelector } from "react-redux";
import { loadLoginPop } from "../actions/baseActions";
const Header = lazy(() => import("./Partial/Header"));
const Footer = lazy(() => import("./Partial/Footer"));

const RouteWithHeader = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Header />
          <Component {...props} title={rest.title} />
          <Footer />
        </>
      )}
    />
  );
};

const openLoginPage = (history, dispatch) => {
  history.push("/");
  dispatch(loadLoginPop(true));
};
const RouteWithUser = ({ component: Component, isAuth: auth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <>
            <div className="backend-page-contant">
              <Header />
              <Component {...props} title={rest.title} />
              <Footer />
            </div>
          </>
        ) : (
          <div>{openLoginPage(rest.history, rest.dispatch)}</div>
        )
      }
    />
  );
};

const RouteWithMobile = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          <Component {...props} title={rest.title} />
        </>
      )}
    />
  );
};
const Index = (props) => {
  const { selectedLang } = useSelector((state) => ({
    selectedLang: state.selectedLang,
  }));
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <Fragment>
      <Suspense fallback={<Loading />}>
        <SelectLang selectedLang={selectedLang} />
        <Switch>
          {RoutesPage.map((route, index) => (
            <RouteWithHeader
              key={index}
              exact
              path={`/:locale(ar|en)?${route.path}`}
              component={route.component}
              title={route.title}
            />
          ))}
          {RoutesUser.map((route, index) => (
            <RouteWithUser
              key={index}
              exact
              path={`/:locale(ar|en)?${route.path}`}
              component={route.component}
              title={route.title}
              isAuth={props.isAuth}
              dispatch={dispatch}
              history={history}
            />
          ))}
          {MobilePage.map((route, index) => (
            <RouteWithMobile
              key={index}
              exact
              path={`/:locale(ar|en)?${route.path}`}
              component={route.component}
              title={route.title}
              isAuth={props.isAuth}
            />
          ))}
          <Redirect to={`/${selectedLang}/404`} />
        </Switch>
      </Suspense>
    </Fragment>
  );
};

export default Index;
