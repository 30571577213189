import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Modal, Tooltip, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getReportList } from "../../actions/HomeAction";
import { postReportItem } from "../../actions/SIdebarAction";
import { crose } from "../../assets/images/index";
import { useTranslation } from "react-i18next";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import { loadToasterData } from "../../actions/baseActions";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { messageListing } from "../../utils/Socket";
import toggleNetworkRequestStatus from "../../actions/toggleNetworkRequestStatus";

function ReportItemModal({ orderId, show, onHide, roomId, userId }) {
  const dispatch = useDispatch();
  const fileInput = useRef();
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState([]);
  const [onEditSelectedFile, SetOnEditSelectedFile] = useState([]);
  const [deleteImagesFile, setDeletedImageFile] = useState([]);
  const [reportId, setReportId] = useState(null);
  const [description, setDescription] = useState(null);

  const { REPORT_LIST } = useSelector((state) => ({
    selectedLang: state.selectedLang,
    REPORT_LIST: state?.homeReducer?.reportList,
  }));

  useEffect(() => {
    dispatch(getReportList());
  }, []);

  const imageHandelChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size > 10485760) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_IS_TOO_BIG"
            )}`,
            "error",
            true
          )
        );
      } else if (
        e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "image/png"
      ) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_NOT_SUPPORTED"
            )}`,
            "error",
            true
          )
        );
      } else {
        const fileArray = Array.from(e.target.files).map(
          (file) =>
            // URL.createObjectURL(file)
            file
        );

        const tempArr = fileArray.concat(selectedFile);

        if (tempArr.length > 6) {
          dispatch(
            loadToasterData(
              `${t("CAN_NOT_ALLOW_TO_ADD_MORE_THEN_SIX")}`,
              "error",
              true
            )
          );
        } else {
          setSelectedFile((prevImages) => prevImages.concat(fileArray));
          Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
        }
      }
    }
  };

  const deletePreviousUploadedImages = (item, index) => {
    const temp = [];
    temp.push(...deleteImagesFile, { id: item?.id });
    setDeletedImageFile(temp);
    const copyPostArray = Object.assign([], onEditSelectedFile);
    copyPostArray.splice(index, 1);
    SetOnEditSelectedFile(copyPostArray);
  };

  const deleteUploadedImages = (index) => {
    const copyPostArray = Object.assign([], selectedFile);
    copyPostArray.splice(index, 1);
    setSelectedFile(copyPostArray);
  };

  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      // source.map((photo, index) => {
      return (
        <div style={{ position: "relative" }} key={index}>
          <IconButton
            style={{
              position: "absolute",
              top: "3px",
              right: "14px",
              fontSize: "14px",
              backgroundColor: "#ffffff",
            }}
            onClick={(e) => deleteUploadedImages(index)}
          >
            <CloseIcon style={{ fontSize: "18px", color: "#000" }} />
          </IconButton>
          <img
            src={URL.createObjectURL(photo)}
            key={photo}
            alt="NO_IMG"
            style={{
              height: "150px",
              width: "150px",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          />
        </div>
      );
    });
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("order_id", orderId);
    if (reportId === null) {
      dispatch(loadToasterData(t("SELECT_REPORT"), "error", true));
      return false;
    }
    formData.append("report_id", reportId);
    formData.append("description", description ? description : "");
    if (selectedFile.length > 0) {
      selectedFile.map((e) => {
        formData.append("image", e);
      });
    } else {
      dispatch(loadToasterData(t("SELECT_IMAGES"), "error", true));
      return false;
    }
    dispatch(postReportItem(formData, toggleLoadMessage));
  };

  const toggleLoadMessage = () => {
    dispatch(toggleNetworkRequestStatus(true));
    setTimeout(function () {
      messageListing(roomId, userId);
      dispatch(toggleNetworkRequestStatus(false));
      onHide(false);
    }, 2000);
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={() => onHide()}
        className="change_passsord_modal report-items"
      >
        <Modal.Header>
          <h4 className="mb-0">{t("REPORT_ITEM_MODAL_TITLE")}</h4>
          <button className="crose_btn" onClick={() => onHide()}>
            <img src={crose} alt="No_IMAGE" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="change_password_main">
            <div>
              <div className="mb-4">
                <Row>
                  <Col lg={12}>
                    <h5>{t("SELECT_REASON")}</h5>

                    <div className="trans_history_money sale_history_sec">
                      <ul className="money_add">
                        {REPORT_LIST.length > 0 &&
                          REPORT_LIST.map((reportitem) => (
                            <li>
                              <div className="matherd_select">
                                <div className="manage_post_ul">
                                  <label className="radio_btn">
                                    <input
                                      type="radio"
                                      name="report_item"
                                      value={reportitem._id}
                                      checked={
                                        reportId === reportitem._id
                                          ? "checked"
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setReportId(e.target.value)
                                      }
                                    />
                                    <span className="custome_radio"></span>
                                    <span>
                                      <strong>
                                        {reportitem.translate.title}
                                      </strong>
                                    </span>
                                  </label>
                                </div>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>
                    <Col lg={12} className="mt-4">
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <Form.Label>
                          <strong>{t("ADD_DESECRATION")}</strong>
                        </Form.Label>
                        <Form.Control
                          as="textarea"
                          rows={3}
                          placeholder={t("ADD_DESECRATION_LIMIT")}
                        />
                      </Form.Group>
                    </Col>
                    <Col lg={12}>
                      <Form.Label>{t("ADD_PHOTOS_IMAGES")}</Form.Label>

                      <div
                        className="upload_post"
                        style={{ position: "relative" }}
                      >
                        {selectedFile.concat(onEditSelectedFile)?.length !==
                          0 &&
                          selectedFile.concat(onEditSelectedFile)?.length <
                            6 && (
                            <div
                              style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                              }}
                              className="add-more-main"
                            >
                              <Tooltip title="Add More Images">
                                <AddAPhotoIcon
                                  color="primary"
                                  fontSize="medium"
                                  onClick={() => fileInput.current.click()}
                                />
                              </Tooltip>
                            </div>
                          )}

                        <input
                          type="file"
                          name="profilePicture"
                          accept="image/jpeg, image/png"
                          style={{ display: "none" }}
                          ref={fileInput}
                          onChange={(e) => imageHandelChange(e)}
                          multiple
                          onClick={(e) => (e.target.value = null)}
                        />

                        {selectedFile.length === 0 &&
                          onEditSelectedFile?.length === 0 && (
                            <span
                              onClick={() => fileInput.current.click()}
                              style={{ cursor: "pointer" }}
                            >
                              <AddAPhotoIcon
                                color="primary"
                                fontSize="medium"
                              />{" "}
                              {t("UPLOAD_IMAGE")}
                            </span>
                          )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          {onEditSelectedFile?.length !== 0 &&
                            onEditSelectedFile?.map((photo, index) => {
                              return (
                                <div
                                  style={{ position: "relative" }}
                                  key={photo?.id}
                                >
                                  <IconButton
                                    style={{
                                      position: "absolute",
                                      top: "3px",
                                      right: "14px",
                                      fontSize: "14px",
                                      backgroundColor: "#ffffff",
                                    }}
                                    onClick={(e) =>
                                      deletePreviousUploadedImages(photo, index)
                                    }
                                  >
                                    <CloseIcon
                                      style={{
                                        fontSize: "18px",
                                        color: "#000",
                                      }}
                                    />
                                  </IconButton>
                                  <img
                                    src={photo?.url}
                                    alt={t("NO_IMG")}
                                    style={{
                                      height: "150px",
                                      width: "150px",
                                      marginRight: "10px",
                                      marginBottom: "10px",
                                    }}
                                  />
                                </div>
                              );
                            })}

                          {renderPhotos(selectedFile)}
                        </div>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col lg={12}>
                  <Button
                    className="theme_btn mt-2 mb-2"
                    style={{ width: "100%" }}
                    type="button"
                    onClick={() => handleSubmit()}
                  >
                    {t("SAVE")}
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ReportItemModal;
