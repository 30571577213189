import { agent } from "../utils/agent";
import * as types from "./actionsTypes";
import * as API from "../utils/apiPath";
import submittingRequestStatus from "./submittingRequestStatusAction";
import { loadToasterData } from "./baseActions";
import toasterStatusAction from "./toasterStatusAction";

export function updateEmailNotification(emailStatus) {
  return { type: types.UPDATE_EMAIL_NOTIFICATION, emailStatus };
}

export function updatePushNotification(emailStatus) {
  return { type: types.UPDATE_PUSH_NOTIFICATION, emailStatus };
}

export function pushEmailNotify(params, push) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.UPDATE_EMAIL_NOTIFY, params)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
        dispatch(
          updateEmailNotification()
        );
        dispatch(toasterStatusAction({ open: true, message: response.data.message }))
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
        dispatch(submittingRequestStatus(false));
      });
  };
}


export function pushNotify(params, push) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.UPDATE_PUSH_NOTIFY, params)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
        dispatch(
          updatePushNotification()
        );
        dispatch(toasterStatusAction({ open: true, message: response.data.message }))
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
        dispatch(submittingRequestStatus(false));
      });
  };
}