import React, { useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import AccordianComponent from "./../../Component/AccordianComponent";
import { fetchFaqData } from "./../../actions/faqAction";
import { useTranslation } from "react-i18next";
const Faq = () => {
  const { t } = useTranslation();
  const { faqData } = useSelector((state) => ({
    faqData: state.faqData,
  }));
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchData = () => {
      dispatch(fetchFaqData());
    };
    fetchData();
  }, [dispatch]);

  return (
    <>
      <section className="inner_sec">
        <Container>
          <h2> {t("FAQ_TITLE")}</h2>
        </Container>
      </section>
      <section className="main_contant">
        <Container>
          <Row>
            <Col md={12}>
              {undefined !== faqData.faqData && faqData.faqData.length !== 0
                ? faqData.faqData.map((item) => (
                    <AccordianComponent
                      key={item._id}
                      id={item.id}
                      title={item.translate.title}
                      desc={item.translate.description}
                    />
                  ))
                : "No data found"}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Faq;
