import { Fragment, lazy, React, useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { purchaseDetailsSave } from "../../actions/HomeAction";
import { loadDialog, loadToasterData } from "../../actions/baseActions";
const queryString = require("query-string");
function Purchase(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const queryStringParsed = queryString.parse(location.search);
  console.log("queryString", queryStringParsed);
  var productId = localStorage.getItem("post_id");
  var addressID = localStorage.getItem("addressID");
  var couponCode = localStorage.getItem("coupon");
  var amountDiscount = localStorage.getItem("amount");
  console.log(localStorage.getItem("coupon"));
  // All Selectors
  useEffect(() => {
    var post_id = productId;
    var code = couponCode;
    var address_id = addressID;
    var tranId = queryStringParsed.TranId;
    var paymentId = queryStringParsed.PaymentId;
    var cardId = "";
    var paymentData = queryStringParsed;
    const request = {
      post_id,
      address_id,
      tranId,
      paymentId,
      cardId,
      code,
      paymentData,
    };

    if (queryStringParsed.Result === "Failure") {
      dispatch(loadToasterData(t("PAYMENT_FAILED"), "error", true));
      setTimeout(function () {
        history.push(`/product-review/${post_id}`);
        //  window.href.location = "/manage";
      }, 100);
    } else {
      dispatch(purchaseDetailsSave(request, returnOrderId));
      setTimeout(function () {
        history.push(`/thank-You/${queryStringParsed.TranId}`);
      }, 1000);
    }
  }, [dispatch]);
  const [getOrderID, setOrderID] = useState("");
  const returnOrderId = (data) => {
    setOrderID(data);
  };
  return (
    <div>
      <section className="section-b-space light-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="success-text">
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                <h2>thank you</h2>
                <p>
                  Payment is successfully processsed and your order is on the
                  way
                </p>
                <p>Transaction ID:{queryStringParsed.TranId}</p>
                {/* <p>Order ID:{getOrderID.order_id}</p>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="section-b-space">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="product-order">
                <h3>your order details</h3>
                <div class="total-sec">
                  <ul>
                    <li>
                      subtotal <span>$0</span>
                    </li>
                  </ul>
                </div>
                <div class="final-total">
                  <h3>
                    total <span>$0</span>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="order-success-sec row">
                <div class="col-sm-6">
                  <h4>summery</h4>
                  <ul class="order-detail">
                    <li>order ID: 5563853658932</li>
                    <li>Order Date: October 22, 2021</li>
                    <li>Order Total: $907.28</li>
                  </ul>
                </div>
                <div class="col-sm-6">
                  <h4>shipping address</h4>
                  <ul class="order-detail">
                    <li>gerg harvell</li>
                    <li>568, suite ave.</li>
                    <li>Austrlia, 235153</li>
                    <li>Contact No. 987456321</li>
                  </ul>
                </div>
                <div class="payment-mode col-sm-12">
                  <h4>payment method</h4>
                  <p>
                    Pay on Delivery (Cash/Card). Cash on delivery (COD)
                    available. Card/Net banking acceptance subject to device
                    availability.
                  </p>
                </div>
                <div class="col-md-12">
                  <div class="delivery-sec">
                    <h3>expected date of delivery</h3>
                    <h2>october 22, 2021</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Purchase;
