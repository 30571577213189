import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function contactUsSubmit(state = initialState.contactUs, action) {
  switch (action.type) {
    case types.SUBMIT_CONTACTUS_REQUEST:
      return {
        ...state,
        contactUs: action.contactUs,
      };
    default:
      return state;
  }
}

