import React from "react";
import { Tab, Tabs } from "react-bootstrap";

function CustomTabs({
  content,
  tabArr,
  defaultActiveKey,
  onSelect,
  homeActive,
}) {
  return (
    <span>
      <>
        <Tabs
          defaultActiveKey={defaultActiveKey}
          id="uncontrolled-tab-example"
          className="mb-3"
          onSelect={(e) => {
            if (onSelect) {
              onSelect(e);
            }
          }}
        >
          {tabArr &&
            tabArr.length !== 0 &&
            tabArr.map((item, index) => {
              return (
                <Tab
                  key={index}
                  eventKey={item?.eventKey}
                  title={
                    homeActive ? (
                      <span>
                        {<img src={item?.image} alt="NO_IMAGE" />} {item.title}
                        <span className="home_tab_checkbox">
                          <span className="checkbox_home_tab"></span>
                        </span>
                      </span>
                    ) : (
                      item.title
                    )
                  }
                >
                  {content ?? ""}
                </Tab>
              );
            })}
        </Tabs>
      </>
    </span>
  );
}

export default CustomTabs;
