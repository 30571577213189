import React, { useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import Geocode from "react-geocode";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

const containerStyle = {
  height: "300px",
};
const google_map = "AIzaSyAbf5BAe6gnaQ30XpKkKCry50bsu8_G5ko";
Geocode.setApiKey(google_map);
const GoogleMapsComponent = (props) => {
  const [address, setAddress] = React.useState(
    props.address ? props.address : ""
  );
  const [area, setArea] = React.useState("");
  const [places, setPlaces] = React.useState(undefined);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: google_map,
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onMapDragEnd = () => {
    let newLat = map.getCenter().lat();
    let newLng = map.getCenter().lng();
    console.log("drand end", address);
    Geocode.fromLatLng(newLat, newLng).then((response) => {
      const address = response.results[0].formatted_address,
        addressArray = response.results[0].address_components,
        area = getArea(addressArray);
      console.log("address", address);
      console.log("area", area);
      props.setLat(newLat);
      props.setLng(newLng);
      props.setArea && props.setArea(address);
      props.setAddress && props.setAddress(address);
      setAddress(address);
    });
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const error = (newLat = 26.8634705, newLng = 75.81852) => {
    Geocode.fromLatLng(newLat, newLng).then((response) => {
      const address = response.results[0].formatted_address,
        addressArray = response.results[0].address_components,
        area = getArea(addressArray);

      props.setLat(newLat);
      props.setLng(newLng);
      props.setArea && props.setArea(address);
      props.setAddress && props.setAddress(address);
      setAddress(address);
    });
  };

  const getArea = (addressArray) => {
    let area = "";
    for (let index = 0; index < addressArray.length; index++) {
      if (addressArray[index].types[0]) {
        for (let j = 0; j < addressArray.length; j++) {
          if (
            "sublocality_level_1" === addressArray[index].types[j] ||
            "locality" === addressArray[index].types[j]
          ) {
            area = addressArray[index].long_name;
            return area;
          }
        }
      }
    }
  };
  useEffect(() => {
    if (!props.area && props.lat && props.lng) {
      Geocode.fromLatLng(props.lat, props.lng).then((response) => {
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          area = getArea(addressArray);

        setArea(address);
      });
    }
  }, [props.lat, props.lng, props.area]);

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setPlaces(value);
    error(latLng.lat, latLng.lng);
  };

  const handleSuggestions = () => {
    return (
      <PlacesAutocomplete
        value={places}
        onChange={setPlaces}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="map-overlay">
            <div className="map-search">
              <i className="fas fa-search" style={{ color: "grey" }} />
              <input
                className="form-control"
                {...getInputProps({ placeholder: "Type Address" })}
              />
            </div>
            <div className="suggestion-list">
              {loading ? <div>...loading</div> : null}
              {suggestions.map((suggestion) => {
                const style = {
                  background: suggestion.active ? "aliceblue" : "",
                  cursor: "pointer",
                  border: "1px solid gainsboro",
                  padding: "7px",
                  overflow: "visible",
                };
                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    <i className="fas fa-map-marker-alt small-grey-icon" />{" "}
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  };

  return isLoaded ? (
    <div>
      <PlacesAutocomplete
        value={places}
        onChange={setPlaces}
        onSelect={handleSelect}
      >
        {handleSuggestions}
      </PlacesAutocomplete>
      <GoogleMap
        mapContainerStyle={containerStyle}
        zoom={18}
        center={{ lat: props.lat, lng: props.lng }}
        position={{ lat: props.lat, lng: props.lng }}
        zoomControl={true}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onDragEnd={onMapDragEnd}
        options={{
          fullscreenControl: false,
        }}
      >
        <Marker
          position={{
            lat: props.lat,
            lng: props.lng,
          }}
        />
      </GoogleMap>

      {/*<div className="map_address">
        <img src={mapMarker} height={30} alt={""} /> {getTrimedAddress()}
      </div>*/}
    </div>
  ) : (
    <></>
  );
};
export default GoogleMapsComponent;
