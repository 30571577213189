import * as types from "../actions/actionsTypes"
import initialState from "./initialState"

export default function faqDataReducer(state = initialState.userProfileInfo, action) {
  switch (action.type) {
    case types.LOAD_USER_PROFILE_DATA_SUCCESS:
      return {
        ...state,
        userProfileInfo: action.userProfileInfo ,
      };
    default:
      return state
  }
}
