import React from "react";
import { Rating } from "react-simple-star-rating";

export default function ReactStartRating({ handelChangeRating, rating }) {
  return (
    <div>
      <Rating
        onClick={handelChangeRating}
        readonly
        ratingValue={
          rating === 0
            ? 0
            : rating === 1
            ? 20
            : rating === 2
            ? 40
            : rating === 3
            ? 60
            : rating === 4
            ? 80
            : rating === 5
            ? 100
            : 0
        }
        iconsCount={5}
        size={20}
        allowHalfIcon={true}
        allowHover={false}
      />
    </div>
  );
}
