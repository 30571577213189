import { lazy, React, useEffect, useState } from "react";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Slider from "react-slick";
import {
  addRemoveFavoriteProductDetails,
  getHomeProductDetail,
  submitReport,
  ContactSeller,
} from "../../actions/HomeAction";
import { loadLoginPop } from "../../actions/baseActions";
import { TextFormate } from "../../Component/TextFormate/TexFormate";
import { Cash } from "../../assets/images/index";
import ReactStartRating from "../../Component/StarRating/reactStartRating";
import avertismentImg from "../../assets/images/add.jpg";
import { StaticGoogleMap, Marker } from "react-static-google-map";
import HeartImg from "../../assets/images/heart.svg";
import saleImg from "../../assets/images/brnd.svg";
import HeartFillImg from "../../assets/images/heart_fill.svg";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import SharePostModal from "./SharePostModal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { loadDialog, loadToasterData } from "../../actions/baseActions";
import ReportItemModal from "./ReportItemModal";

const Dialog = lazy(() => import("../../Component/Dialog"));

function ProductDetail(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const productId = location.pathname.substring(
    location.pathname.lastIndexOf("/") + 1
  );

  // All Selectors
  const { PRODUCT_DETAIL, USER_INFO, dialog, LOADING_REPORT, isAuth } =
    useSelector((state) => ({
      dialog: state.dialog,
      PRODUCT_DETAIL: state.homeReducer.homeProductDetails,
      USER_INFO: state.userInfo,
      LOADING_REPORT: state?.homeReducer?.submitReasonLoader,
      isAuth: state.isAuth,
    }));

  //   All State
  const [currentImg, setCurrentImg] = useState({});
  const [productLocation, setProductLocation] = useState([]);
  const [showShareModal, setShowShareModal] = useState(false);
  const [imageGallery, setImageGallery] = useState([]);

  const [showReportItemModal, setShowReportItemModal] = useState(false);

  const toggleReportItemModal = () => {
    setShowReportItemModal(!showReportItemModal);
  };

  const activepostslider = {
    dots: false,
    nav: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    if (productId) {
      const data = { id: productId };
      dispatch(getHomeProductDetail(data, setCurrentImg, setProductLocation));
    }
  }, [productId, dispatch]);

  const habdelFavoratePost = (id) => {
    const data = {
      post_id: id,
    };
    const productData = { id: productId };

    dispatch(
      addRemoveFavoriteProductDetails(
        data,
        productData,
        setCurrentImg,
        setProductLocation
      )
    );
  };

  const toggleShareModal = () => {
    setShowShareModal(!showShareModal);
  };

  useEffect(() => {
    const newArray = [];
    if (PRODUCT_DETAIL?.image?.length !== 0) {
      PRODUCT_DETAIL?.image?.map((row, i) => {
        newArray.push({
          original: row?.originalUrl,
          thumbnail: row?.url,
        });
      });
    }
    setImageGallery(newArray);
  }, [PRODUCT_DETAIL]);

  const HandelClickSimilarProduct = (id) => {
    if (productId) {
      const data = { id: id };
      dispatch(getHomeProductDetail(data, setCurrentImg, setProductLocation));
      history.push(`/product/${id}`);
    }
  };

  const handleReportClick = (e) => {
    dispatch(
      loadDialog({
        open: true,
        message: t("ARE_YOU_SURE_YOU_WANT_TO_REPORT_THIS_POST"),
        title: t("REPORT_POST"),
      })
    );
  };

  const handelReportAction = () => {
    if (PRODUCT_DETAIL?._id) {
      dispatch(
        loadDialog({
          open: false,
          message: t("ARE_YOU_SURE_YOU_WANT_TO_REPORT_THIS_POST"),
          title: t("REPORT_POST"),
        })
      );
      const data = {
        post_id: PRODUCT_DETAIL?._id,
      };
      dispatch(submitReport(data));
    } else {
      dispatch(loadToasterData(t("SOMETHING_WENT_WRONG"), "error", true));
    }
  };

  const HandelClickContactSeller = () => {
    if (!isAuth) {
      dispatch(loadLoginPop(true));
      return false;
    }
    var request = {};
    request.post_id = productId;
    dispatch(ContactSeller(request, contactSellerChat));
  };

  const contactSellerChat = (data) => {
    history.push(`/chat/${data.room}`);
  };

  return (
    <div>
      <>
        {PRODUCT_DETAIL && PRODUCT_DETAIL._id && (
          <section className="confirm_order_section">
            <Container>
              <div className="breadcrumb">
                <ul>
                  <li>
                    <Link to="/">
                      {t("HOME")}
                      <i className="fas fa-chevron-right"></i>
                    </Link>
                  </li>
                  <li>
                    <span>{PRODUCT_DETAIL?.title}</span>
                  </li>
                </ul>
              </div>

              <div className="confirm_order_wrapper">
                <Row>
                  <Col lg={9}>
                    <div className="outline_wrapper mb-5">
                      <div className="image_gallery_sec">
                        {PRODUCT_DETAIL?.image?.length > 0 && (
                          <>
                            <Carousel
                              showStatus={false}
                              showIndicators={false}
                              swipeable={true}
                              emulateTouch={true}
                            >
                              {imageGallery?.map((row, i) => {
                                return (
                                  <div key={i}>
                                    <img src={row.original} alt="user" />
                                  </div>
                                );
                              })}
                            </Carousel>
                            {PRODUCT_DETAIL?.boost && (
                              <span className="doffo_label">Doffo Boost</span>
                            )}
                          </>
                        )}
                      </div>

                      <div className="confirm_order_info border-0">
                        <div className="product_item_horizantal align-items-">
                          <div className="d-flex align-items-center seller-post-review">
                            <div className="offer_product_detail">
                              {PRODUCT_DETAIL?.sold && <p>{t("SOLD")}</p>}
                              <h3>{PRODUCT_DETAIL?.title}</h3>
                              <div className="sel-post-date">
                                <span>
                                  <i className="far fa-clock"></i>
                                  {PRODUCT_DETAIL?.date}
                                </span>
                                <span>
                                  <i className="fas fa-map-marker-alt"></i>
                                  {PRODUCT_DETAIL?.addresses &&
                                    PRODUCT_DETAIL?.addresses?.city}
                                </span>
                              </div>
                              <div className="post-review-item mt-3">
                                <small>{t("ITEM_PRICE")}</small>
                                <span className="price">
                                  {TextFormate(PRODUCT_DETAIL?.price, 2)}{" "}
                                  {t("SR")}
                                  <span className="off_price">
                                    {PRODUCT_DETAIL?.discountPrice > 0 &&
                                      PRODUCT_DETAIL?.discountPrice}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div>
                            <span className="eye_icon d-block text-right">
                              <i className="fas fa-eye"></i>
                              {PRODUCT_DETAIL?.view} {t("VIEW")}
                            </span>
                            {PRODUCT_DETAIL?.seller?._id !== USER_INFO?._id && (
                              <div className="right_btn_service mt-3 mt-md-5">
                                {!PRODUCT_DETAIL?.sold ? (
                                  <>
                                    {PRODUCT_DETAIL?.seller?._id !==
                                      USER_INFO?._id &&
                                      PRODUCT_DETAIL?.shipping &&
                                      PRODUCT_DETAIL?.buyNow && (
                                        <Link
                                          to={`/product-review/${productId}`}
                                          className="btn btn-warning text-white"
                                          product_data={PRODUCT_DETAIL}
                                        >
                                          {t("BUY_NOW")}
                                        </Link>
                                      )}

                                    <Link
                                      to={`/makeoffer/${productId}`}
                                      className="bg-transparent text-dark border-dark btn btn-primary"
                                    >
                                      {t("MAKE_OFFER")}
                                    </Link>
                                  </>
                                ) : null}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="listing_brands_new">
                          <div className="listing_brand_cat">
                            <ul>
                              <li>
                                <span className="preview-label">
                                  {t("ITEM_CONDITION")}:
                                </span>
                                <span>{PRODUCT_DETAIL?.conditions}</span>
                              </li>
                              <li>
                                <span className="preview-label">
                                  {t("CATEGORY")}:
                                </span>
                                <span>{PRODUCT_DETAIL?.categories}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="listing_brand_cat">
                            <ul>
                              {PRODUCT_DETAIL?.brands && (
                                <li>
                                  <span className="preview-label">
                                    {t("BRAND")}:
                                  </span>
                                  <span>{PRODUCT_DETAIL?.brands}</span>
                                </li>
                              )}
                              {PRODUCT_DETAIL?.models && (
                                <li>
                                  <span className="preview-label">
                                    {t("MODAL")}:
                                  </span>
                                  <span>{PRODUCT_DETAIL?.models}</span>
                                </li>
                              )}
                            </ul>
                          </div>

                          <div className="listing_brand_cat">
                            <ul>
                              {PRODUCT_DETAIL?.item_information?.weight && (
                                <li>
                                  <span className="preview-label">
                                    {t("WEIGHT")}:
                                  </span>
                                  <span>
                                    {PRODUCT_DETAIL?.item_information?.weight}
                                    {t("KG")}
                                  </span>
                                </li>
                              )}
                              {PRODUCT_DETAIL?.item_information?.height && (
                                <li>
                                  <span className="preview-label">
                                    {t("HEIGHT")}:
                                  </span>
                                  <span>
                                    {PRODUCT_DETAIL?.item_information?.height}
                                    {t("cm")}
                                  </span>
                                </li>
                              )}
                            </ul>
                          </div>

                          <div className="listing_brand_cat">
                            <ul>
                              {PRODUCT_DETAIL?.item_information?.length && (
                                <li>
                                  <span className="preview-label">
                                    {t("LENGTH")}:
                                  </span>
                                  <span>
                                    {PRODUCT_DETAIL?.item_information?.length}
                                    {t("CM")}
                                  </span>
                                </li>
                              )}
                              {PRODUCT_DETAIL?.item_information?.width && (
                                <li>
                                  <span className="preview-label">
                                    {t("WIDTH")}:
                                  </span>
                                  <span>
                                    {PRODUCT_DETAIL?.item_information?.width}
                                    {t("CM")}
                                  </span>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>

                        {PRODUCT_DETAIL?.description && (
                          <div className="listing_condition">
                            <div className="description">
                              <h3 className="seller_titles">
                                {t("DESCRIPTION")}
                              </h3>
                              <p>
                                {PRODUCT_DETAIL?.description !== null
                                  ? PRODUCT_DETAIL?.description
                                  : t("NO_DESCRIPTION")}
                                .{" "}
                              </p>
                            </div>
                          </div>
                        )}

                        <div className="payment_method">
                          <div className="description d-flex justify-content-between align-items-end">
                            <div>
                              <h3 className="seller_titles mb-3">
                                {t("PAYMENT_METHODS")}
                              </h3>
                              <ul className="m-0 p-0 d-flex payment_list">
                                <li>
                                  <a href="#!">
                                    <img src={Cash} alt="" />
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <div className="payment_social">
                              <ul className="m-0 p-0 d-flex">
                                <li>
                                  <Link
                                    to="#!"
                                    onClick={() =>
                                      habdelFavoratePost(PRODUCT_DETAIL?._id)
                                    }
                                  >
                                    {!PRODUCT_DETAIL?.favorite ? (
                                      <>
                                        <FavoriteBorderIcon />
                                        &nbsp;{t("FAVOURITE")}
                                      </>
                                    ) : (
                                      <>
                                        <FavoriteIcon
                                          style={{ color: "red " }}
                                        />
                                        &nbsp;{t("FAVOURITE")}
                                      </>
                                    )}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#!"
                                    onClick={(e) =>
                                      !LOADING_REPORT && handleReportClick(e)
                                    }
                                    style={
                                      LOADING_REPORT
                                        ? { cursor: "not-allowed" }
                                        : {}
                                    }
                                  >
                                    <i className="far fa-flag"></i>

                                    {t("REPORT")}
                                    {LOADING_REPORT && (
                                      <i
                                        className="fa fa-spinner fa-spin"
                                        style={{
                                          color: "#33A7CC",
                                          fontSize: "16px",
                                          marginLeft: "5px",
                                        }}
                                      />
                                    )}
                                  </Link>
                                </li>
                                <li>
                                  <Link
                                    to="#"
                                    onClick={() => toggleShareModal()}
                                  >
                                    <i className="fas fa-share"></i>
                                    {t("SHARE")}
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {PRODUCT_DETAIL?.similarItem?.length !== 0 && (
                      <div className="outline_wrapper">
                        <div className="active_post_slider product_outer_main border-0 pt-0">
                          <h2>{t("SIMILAR_POST")}</h2>
                          <Slider {...activepostslider}>
                            {PRODUCT_DETAIL?.similarItem &&
                              PRODUCT_DETAIL?.similarItem?.length !== 0 &&
                              PRODUCT_DETAIL?.similarItem?.map((item) => {
                                return (
                                  <div className="product_card" key={item._id}>
                                    <figure>
                                      <img
                                        src={item.image}
                                        alt=""
                                        style={{ cursor: "default" }}
                                      />
                                    </figure>
                                    <div className="overlay_top">
                                      {item?.boost && (
                                        <span className="boost_badge">
                                          {t("DOFFO_BOOST")}
                                        </span>
                                      )}
                                      {item?.sold && (
                                        <span className="sold_badge">
                                          {t("SOLD")}
                                        </span>
                                      )}

                                      <span
                                        className="heart"
                                        onClick={() =>
                                          habdelFavoratePost(item?._id)
                                        }
                                      >
                                        {!item?.favorite ? (
                                          <img
                                            src={HeartImg}
                                            alt="NO_IMG"
                                            style={{ cursor: "pointer" }}
                                          />
                                        ) : (
                                          <img
                                            src={HeartFillImg}
                                            alt="NO_IMG"
                                            style={{ cursor: "pointer" }}
                                          />
                                        )}
                                      </span>
                                    </div>

                                    <div
                                      className="overlay_bottom"
                                      onClick={() =>
                                        HandelClickSimilarProduct(item?._id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <h4>
                                        <Link to="#">{item?.title}</Link>
                                      </h4>
                                      <div className="product_info">
                                        <span className="price">
                                          {TextFormate(item?.price, 2)}{" "}
                                          {t("SR")}
                                        </span>
                                        {item.shipping && (
                                          <span className="brand">
                                            <img src={saleImg} alt="NO_IMG" />
                                          </span>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </Slider>
                        </div>
                      </div>
                    )}
                  </Col>

                  <Col lg={3}>
                    <div className="order_summary_sidebar post_review_side mb-4">
                      <div className="post_user_name p-0 bg-transparent border-0\">
                        <figure className="d-flex align-items-center mb-4">
                          <span className="img_circle border-0">
                            <img
                              src={
                                PRODUCT_DETAIL?.seller &&
                                PRODUCT_DETAIL?.seller?.image
                              }
                              alt="User Profile"
                            />
                          </span>
                          <figcaption className="ms-2">
                            <h5 className="m-0">
                              {PRODUCT_DETAIL?.seller &&
                                PRODUCT_DETAIL?.seller.name}
                            </h5>
                            <div className="user_rating d-flex align-items-center">
                              <ReactStartRating
                                rating={Math.floor(
                                  PRODUCT_DETAIL?.seller?.reviewCount
                                )}
                              />
                              <small className="fs-6 mt-1">
                                {PRODUCT_DETAIL?.seller &&
                                  PRODUCT_DETAIL?.seller?.reviewCount}
                              </small>
                            </div>
                          </figcaption>
                        </figure>
                      </div>
                      {/* {PRODUCT_DETAIL?.seller?._id !== USER_INFO?._id && (
                        <Button
                          className="theme_btn m-0 w-100"
                          onClick={HandelClickContactSeller}
                        >
                          {t("CONTACT_SELLER")}
                        </Button>
                      )} */}
                    </div>

                    <div className="order_summary_sidebar post_review_side mb-4">
                      <h3 className="bg-transparent p-0">{t("POSTED_IN")}</h3>
                      <small className="d-block mb-3 name">
                        {PRODUCT_DETAIL?.addresses?.title && (
                          <i className="fas fa-map-marker-alt" />
                        )}
                        {PRODUCT_DETAIL?.addresses?.city}
                      </small>

                      <div>
                        <StaticGoogleMap
                          size="600x600"
                          className="img-fluid"
                          apiKey="AIzaSyAv6leIF_HsmoOdtnDjQBIDIriyHhcA82U"
                        >
                          <Marker
                            location={`${productLocation[0]},${productLocation[1]}`}
                            color="blue"
                          />
                        </StaticGoogleMap>
                      </div>
                      <small className="d-block mt-1 name">
                        {t("RADIUS_COVER_10")}
                      </small>
                    </div>
                    <div>
                      <img src={avertismentImg} alt="" className="w-100" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </section>
        )}
      </>

      {dialog.open && <Dialog action={handelReportAction} />}
      {showShareModal && (
        <SharePostModal
          show={showShareModal}
          onHide={() => toggleShareModal()}
          PRODUCT_DETAIL={PRODUCT_DETAIL}
        />
      )}

      {showReportItemModal && (
        <ReportItemModal
          orderId={PRODUCT_DETAIL?._id}
          show={showReportItemModal}
          onHide={() => toggleReportItemModal()}
        />
      )}
    </div>
  );
}

export default ProductDetail;
