import { React, useEffect } from "react";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ProfileSidebar from "./profileSidebar";
import ChangePassword from "../Auth/ChangePassword";
import { fetchUserProfileData } from "../../actions/userProfileAction";
import { uploadPic, toggle } from "../../assets/images/index";
const MyAccount = () => {
  const dispatch = useDispatch();
  // const { t } = useTranslation();
  // const { userInfo } = useSelector((state) => ({
  //   userInfo: state.userInfo,
  // }));
  const { userProfileInfo } = useSelector((state) => ({
    userProfileInfo: state.userProfileInfo,
  }));
  useEffect(() => {
    const fetchData = () => {
      dispatch(fetchUserProfileData());
    };
    fetchData();
  }, [dispatch]);

  function dashsidebar() {
    document.getElementById("onscroll").classList.toggle("dashboard_sidebar");
  }

  return (
    <>
      <section
        className="middle_banner_sec"
        style={{
          backgroundImage: `url(${
            userProfileInfo.userProfileInfo &&
            userProfileInfo.userProfileInfo.backgroundImage
          })`,
        }}
      >
        <Container>
          <span className="camera_icon_banner">
            <input type="file" />
            <img className="camera_icon" src={uploadPic} alt="Camera Icon" />
          </span>
        </Container>
      </section>
      <div className="toggle_main">
        <div className="togggle_dashboard" onClick={dashsidebar}>
          <img src={toggle} alt="Toggle" />
        </div>
      </div>
      <section className="manage_address">
        <Container>
          <ChangePassword />
          <ProfileSidebar userdata={userProfileInfo} />
        </Container>
      </section>
    </>
  );
};
export default MyAccount;
