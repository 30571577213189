import { React,useRef } from 'react';
import { Row, Col, Container, Form ,Button} from "react-bootstrap";
import FooterApp from "../../Component/FooterApp";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { Link, useHistory } from 'react-router-dom';
import { ConatctUsImg } from "../../assets/images/index";
import { makeStyles } from "@material-ui/core/styles";
import { ErrorMessage } from "@hookform/error-message";
import { contactUsData } from "../../actions/contactUsAction";
import { checkMobileNumber, ValidateAlpha } from "../../utils/helpers";
const useStyles = makeStyles({
    root: {
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "#33a7cc",
        },
        "& .Mui-focused": {
            color: "#33a7cc",
        },
    },
});
const Contactus = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { push } = useHistory();
    const formRef = useRef(null);
    const classes = useStyles();
    const { contactUs } = useSelector((state) => ({
        contactUs: state.contactUs,
    }));
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        defaultValues: contactUs,
    });
    const onSubmit = (data) => {
        dispatch(contactUsData(data, push));
            formRef.current.reset();
    };
    return (
        <>
            <section className="inner_sec">
                <Container>
                    <h2> {t("CONTACTUS_TITLE")}</h2>
                </Container>
            </section>

            <section className="bredcum_all">
                <Container>
                    <div className="breadcrumb">
                        <ul>
                            <li><Link to=''>{t("HOME")}<i className="fas fa-chevron-right"></i></Link></li>
                            <li><span>{t("CONTACTUS_TITLE")}</span></li>
                        </ul>
                    </div>
                </Container>
            </section>
            <section className="contact_sec">
                <Container>

                    <Row>
                        <h2 className="all_inner_heading">{t("CONTACTUS_TITLE")}</h2>

                        <Col lg={8} md={6}>
                            <Form ref={formRef} className="contact_form"
                                onSubmit={handleSubmit(onSubmit)}
                                noValidate
                                autoComplete="off">
                                <Row>
                                    <Col lg={6} md={6} sm={6}>
                                        <Form.Group className="mb-3" controlId="formGroupName">
                                            <Form.Label>{t("NAME")}   <span className="required-star">*</span></Form.Label>
                                            <Form.Control
                                                className={!errors.name ? classes.root : "w-100"}
                                                placeholder={t("ENTER_NAME")}
                                                onKeyDown={(event) => ValidateAlpha(event)}
                                                autoFocus={true}
                                                name="name"
                                                {...register("name", {
                                                    required: t("ENTER_NAME"),
                                                    minLength: {
                                                        value: 3,
                                                        message: t("NAME_MINIMUM_LENGTH"),
                                                    },
                                                    maxLength: {
                                                        value: 50,
                                                        message: t("NAME_MAXIMUM_LENGTH"),
                                                    },
                                                })} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="name"
                                                render={({ message }) => (
                                                    <p className="error-message">{message}</p>
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6} sm={6}>
                                        <Form.Group className="mb-3" controlId="formGroupMobileNumber">
                                            <Form.Label>{t("MOBILE_NUMBER")}   <span className="required-star">*</span></Form.Label>
                                            <Form.Control
                                                className={
                                                    !errors.mobile_number ? classes.root : "w-100"
                                                }
                                                name="mobile_number"
                                                onKeyDown={(event) => checkMobileNumber(event)}
                                                {...register("mobile_number", {
                                                    required: t("PLEASE_ENTER_MOBILE_NUMBER"),
                                                    minLength: {
                                                        value: 7,
                                                        message: t("MOBILE_NUMBER_MINIUM_LENGTH"),
                                                    },
                                                    maxLength: {
                                                        value: 15,
                                                        message: t("MOBILE_NUMBER_MAXIMUM_LENGTH"),
                                                    },
                                                })} type="text" placeholder={t("ENTER_MOBILE_NUMBER")} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="mobile_number"
                                                render={({ message }) => (
                                                    <p className="error-message">{message}</p>
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12}>
                                        <Form.Group className="mb-3" controlId="formGroupEmail">
                                            <Form.Label>{t("EMAIL_ADDRESS")}<span className="required-star">*</span></Form.Label>
                                            <Form.Control   className={
                                                    !errors.email ? classes.root : "w-100"
                                                }  type="email" name="email"  {...register("email", {
                                                required: t("PLEASE_ENTER_YOUR_EMAIL"),
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: t("INVALID_EMAIL"),
                                                },
                                            })}
                                                placeholder={t("ENTER_EMAIL_ADDRESS")} />
                                            <ErrorMessage
                                                errors={errors}
                                                name="email"
                                                render={({ message }) => (
                                                    <p className="error-message">{message}</p>
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12}>
                                        <Form.Group className="mb-3 mb-md-4" controlId="formGroupMessage">
                                            <Form.Label>{t("MESSAGE")}   <span className="required-star">*</span></Form.Label>
                                            <Form.Control
                                              className={
                                                !errors.message ? classes.root : "w-100"
                                            }
                                                {...register("message", {
                                                    required: t("WRITE_MESSAGE_HERE"),
                                                    minLength: {
                                                        value: 10,
                                                        message: t("MESSAGE_MINIMUM_LENGTH"),
                                                    },
                                                    maxLength: {
                                                        value: 500,
                                                        message: t("MESSAGE_MAXIMUM_LENGTH"),
                                                    },
                                                })}
                                                name="message" as="textarea" rows={6} placeholder={t("WRITE_COMMENT_HERE")} />
                                                      <ErrorMessage
                                                errors={errors}
                                                name="message"
                                                render={({ message }) => (
                                                    <p className="error-message">{message}</p>
                                                )}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12} sm={12}>
                                    <Button type="submit" className="theme_btn send_btn">{t("SEND")}</Button> 
                                    </Col>
                                </Row>
                            </Form>
                        </Col>

                        <Col lg={4} md={6}>
                            <div className="contact_sec">
                                <img src={ConatctUsImg} alt="contact-us-img" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            <FooterApp />

        </>
    )
}
export default Contactus;