export const LOADED_USER_AUTH_SUCCESS = "LOADED_USER_AUTH_SUCCESS";
export const LOADED_LANG_SELECTED_SUCCESS = "LOADED_LANG_SELECTED_SUCCESS";
export const LOADED_TOASTER_INFO_SUCCESS = "LOADED_TOASTER_INFO_SUCCESS";
export const LOADED_USER_INFO_SUCCESS = "LOADED_USER_INFO_SUCCESS";
export const LOADED_USER_PARAM_SUCCESS = "LOADED_USER_PARAM_SUCCESS";
export const SUBMITTING_REQUEST_STATUS = "SUBMITTING_REQUEST_STATUS";
export const NETWORK_REQUEST_STATUS = "NETWORK_REQUEST_STATUS";
export const LOADED_USER_RESET_PASS = "LOADED_USER_RESET_PASS";
export const LOADED_LOGIN_POPUP = "LOADED_LOGIN_POPUP";
export const LOADED_REGISTER_POPUP = "LOADED_REGISTER_POPUP";
export const LOADED_FORGET_POPUP = "LOADED_FORGET_POPUP";
export const LOADED_CHANGE_PASSWORD_POPUP = "LOADED_CHANGE_PASSWORD_POPUP";
export const LOADED_MANAGE_ADDRESS_POPUP = "LOADED_MANAGE_ADDRESS_POPUP";
export const CHANGE_USER_PASSWORD = "CHANGE_USER_PASSWORD";
export const LOADED_REGISTER_VERIFY = "LOADED_REGISTER_VERIFY";
export const LOADED_DIALOG_INFO_SUCCESS = "LOADED_DIALOG_INFO_SUCCESS";
export const LOAD_PAGE_DATA_SUCCESS = "LOAD_PAGE_DATA_SUCCESS";
export const LOADED_SETTING_INFO_SUCCESS = "LOADED_SETTING_INFO_SUCCESS";
export const LOAD_FAQ_DATA_SUCCESS = "LOAD_FAQ_DATA_SUCCESS";
export const LOAD_BANK_DATA_SUCCESS = "LOAD_BANK_DATA_SUCCESS";
export const LOAD_CARD_DATA_SUCCESS = "LOAD_CARD_DATA_SUCCESS";
export const LOAD_USER_WALLET_DATA_SUCCESS = "LOAD_USER_WALLET_DATA_SUCCESS";
export const LOAD_USER_WALLET_TOTAL_AMOUNT_SUCCESS =
  "LOAD_USER_WALLET_TOTAL_AMOUNT_SUCCESS";
export const WALLET_LOADING = "WALLET_LOADING";
export const WALLET_PAGINATION = "WALLET_PAGINATION";
export const WALLET_LOAD_MORE_LOADING = "WALLET_LOAD_MORE_LOADING";
export const SUBMIT_CONTACTUS_REQUEST = "SUBMIT_CONTACTUS_REQUEST";
export const LOAD_HOME_PAGE_DATA_SUCCESS = "LOAD_HOME_PAGE_DATA_SUCCESS";
export const LOAD_USER_PROFILE_DATA_SUCCESS = "LOAD_USER_PROFILE_DATA_SUCCESS";
export const LOAD_PUBLIC_PROFILE = "LOAD_PUBLIC_PROFILE";
export const UPDATE_EMAIL_NOTIFICATION = "UPDATE_EMAIL_NOTIFICATION";
export const UPDATE_PUSH_NOTIFICATION = "UPDATE_PUSH_NOTIFICATION";
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE";
export const ADD_USER_ADDRESS = "ADD_USER_ADDRESS";
export const GET_USER_ADDRESS = "GET_USER_ADDRESS";
export const DELETE_USER_ADDRESS = "DELETE_USER_ADDRESS";
export const DEFAULT_USER_ADDRESS = "DEFAULT_USER_ADDRESS";
export const UPDATE_USER_ADDRESS = "UPDATE_USER_ADDRESS";
export const CREATE_POST = "CREATE_POST";
export const GET_CATEGORY = "GET_CATEGORY";
export const GET_BRAND = "GET_BRAND";
export const GET_MODAL = "GET_MODAL";
export const SUBMIT_POST = "SUBMIT_POST";
export const GET_CONDITION = "GET_CONDITION";
export const GET_ADDRESS_LIST = "GET_ADDRESS_LIST";
export const POST_PREVIEW = "POST_PREVIEW";
export const LOADED_PAGE_DATA_SUCCESS = "LOADED_PAGE_DATA_SUCCESS";
export const LOADED_DATA_SUCCESS = "LOADED_DATA_SUCCESS";
export const CHECK_OFFER_DATA = "CHECK_OFFER_DATA";
export const PAYMENT_DATA_REDUCER = "PAYMENT_DATA_REDUCER";
export const LOADED_DEVICE_TOKEN_SUCCESS = "LOADED_DEVICE_TOKEN_SUCCESS";
export const LOADED_SOCIAL_PARAMS_POPUP = "LOADED_SOCIAL_PARAMS_POPUP";
export const LOADED_PARENT_CATEGORY_SUCCESS = "LOADED_PARENT_CATEGORY_SUCCESS";
