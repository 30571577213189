import * as types from "../actions/actionsTypes";
//import initialState from "./initialState";

const initialState = {
  walletData: [],
  walletTotalAmount: 0,
  walletLoading: false,
  walletPagination: {},
  walletLoadMoreLoading: false,
};

export default function walletDataReducer(state = initialState, action) {
  switch (action.type) {
    case types.LOAD_USER_WALLET_DATA_SUCCESS:
      return {
        ...state,
        walletData: action.payload,
      };

    case types.LOAD_USER_WALLET_TOTAL_AMOUNT_SUCCESS:
      return {
        ...state,
        walletTotalAmount: action.payload,
      };

    case types.WALLET_LOADING:
      return {
        ...state,
        walletLoading: action.payload,
      };

    case types.WALLET_PAGINATION:
      return {
        ...state,
        walletPagination: action.payload,
      };

    case types.WALLET_LOAD_MORE_LOADING:
      return {
        ...state,
        walletLoadMoreLoading: action.payload,
      };

    default:
      return state;
  }
}
