import * as types from "../actions/actionsTypes";
//import initialState from "./initialState";

const initialState = {
  productOffer: {},
};

export default function ProductOfferReducer(state = initialState, action) {
  //console.log("action.type", action.type);
  switch (action.type) {
    case types.CHECK_OFFER_DATA:
      return {
        ...state,
        productOffer: action.productOffer,
      };
    default:
      return state;
  }
}
