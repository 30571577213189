import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function homePageReducer(
  state = initialState.homePageData,
  action
) {
  switch (action.type) {
    case types.LOAD_HOME_PAGE_DATA_SUCCESS:
      return {
        ...state,
        pageData: action.pageData,
      };
    case "STORE_PRODUCTS_DATA":
      return {
        ...state,
        homePageData: action.homePageData,
      };

    case "ADD_MORE_PRODUCTS":
      return {
        ...state,
        homePageData: {
          data: [...state.homePageData.data, ...action.payload.data],
          pagination: action.payload.pagination,
        },
      };

    case "STORE_IP_ADDRESS":
      return {
        ...state,
        ipAddress: action.payload,
      };

    default:
      return state;
  }
}
