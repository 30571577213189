import React, { lazy, useEffect, useState } from "react";
import { Container, Row, Col, Button, Modal, Dropdown } from "react-bootstrap";
import Sidebar from "../Sidebar";
import LottiLoader from "../../../Component/lottiAnimation/LottiLoader";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteAllNotification,
  deleteNotification,
  fetchNotificationData,
} from "../../../actions/SIdebarAction";
import {
  textTruncate,
  textTruncateAny,
} from "../../../Component/TextTruncate/textTruncate";
import { loadDialog, loadToasterData } from "../../../actions/baseActions";
import { postPreviewSuccess } from "../../../actions/postAction";

const Notification = () => {
  const dispatch = useDispatch();
  const Dialog = lazy(() => import("../../../Component/Dialog"));

  useEffect(() => {
    dispatch(fetchNotificationData());
  }, []);

  //   All Selectors
  const { NOTIFICATION, NOTIFICATION_LOADING, dialog } = useSelector(
    (state) => ({
      NOTIFICATION: state?.sidebarReducer?.notificationData,
      NOTIFICATION_LOADING: state?.sidebarReducer?.notificationLoading,
      dialog: state.dialog,
    })
  );
  // All State
  const [currentNotification, setCurrentNotification] = useState("");
  const [activeDeleteAll, setActiveDeleteAll] = useState(false);

  const handleDelete = (item) => {
    setCurrentNotification(item?._id);
    console.log("item?._id", item?._id);

    dispatch(
      loadDialog({
        open: true,
        message: "Are you sure you want to delete?",
        title: "Are you Sure?",
      })
    );
  };

  const handleDeleteAction = () => {
    setActiveDeleteAll(false);
    dispatch(
      loadDialog({
        open: false,
        message: "Are you sure you want to delete?",
        title: "Are you Sure?",
      })
    );
    dispatch(deleteNotification(currentNotification));
  };
  const handleDeleteAllNotification = () => {
    setActiveDeleteAll(true);
    dispatch(
      loadDialog({
        open: true,
        message: "Are you sure you want to delete all notification?",
        title: "Are you Sure?",
      })
    );
  };

  const handleDeleteNotificationAction = () => {
    setActiveDeleteAll(false);
    dispatch(
      loadDialog({
        open: false,
        message: "Are you sure you want to delete all notification?",
        title: "Are you Sure?",
      })
    );
    dispatch(deleteAllNotification());
  };
  //   //   console.log("currentNotification", currentNotification);
  return (
    <div>
      <section
        className="middle_banner_sec"
        style={
          {
            //   backgroundImage: `url(${GET_USER_PROFILE?.backgroundImage})`,
          }
        }
      ></section>

      <section className="manage_address">
        <Container>
          <div className="sidebar_outer">
            <div className="sidebar_main">
              <Sidebar />
            </div>

            <div className="dashboard_main outline_wrapper p-0">
              <div className="order_tracking_info_list mb-2 ml-9">
                <div className="tracking_head m-0">
                  <h2>
                    {" "}
                    <span className="d-inline-block">
                      <i className="far fa-clipboard"></i>
                    </span>{" "}
                    Notification
                  </h2>
                </div>
              </div>
              {NOTIFICATION?.length !== 0 && (
                <Row>
                  <Col md={10}></Col>
                  <Col md={2}>
                    <div className="add_new_btn">
                      <Button
                        className="theme_btn delete_all_btn mt-1"
                        onClick={() => handleDeleteAllNotification()}
                      >
                        <i className="fa fa-trash" aria-hidden="true" />
                        &nbsp; Delete All
                      </Button>
                    </div>
                  </Col>
                </Row>
              )}
              {/* start UI */}
              <div
                style={{
                  padding: "24px 30px 0 30px",
                  height: "1397px",
                  overflow: "auto",
                  marginBottom: "30px",
                }}
              >
                <div className="trans_history_money sale_history_sec manage_post_sec">
                  {NOTIFICATION_LOADING ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <LottiLoader loader={true} height={150} width={150} />
                    </div>
                  ) : (
                    <>
                      {NOTIFICATION?.length == 0 && (
                        <Row>
                          <LottiLoader
                            message={"No Notification"}
                            height={250}
                            width={250}
                          />
                        </Row>
                      )}

                      {NOTIFICATION &&
                        NOTIFICATION.length !== 0 &&
                        NOTIFICATION.map((item) => {
                          return (
                            <ul className="money_add" key={item?.id}>
                              <li>
                                <div className="manage_post_ul">
                                  <div
                                    className="trans_date"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div className="trans_date_list shadow-none border-0">
                                      <figure
                                        className="border-0"
                                        style={{ position: "relative" }}
                                      >
                                        <img
                                          src={item?.image}
                                          alt="No_Img"
                                          className="manage-post-img"
                                        />
                                      </figure>
                                    </div>

                                    <div className="trans_title">
                                      <p className="m-0  fw-bold fs-6">
                                        {textTruncateAny(
                                          item?.description,
                                          120
                                        )}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="likes_btn">
                                    <Button
                                      className="onroute theme_btn delete_all_btn"
                                      onClick={() => handleDelete(item)}
                                    >
                                      <i
                                        className="fa fa-trash"
                                        aria-hidden="true"
                                      />
                                      &nbsp; Delete
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          );
                        })}
                    </>
                  )}
                </div>
              </div>
              {/* End UI */}
            </div>
          </div>
        </Container>
      </section>

      {activeDeleteAll ? (
        <>{dialog.open && <Dialog action={handleDeleteNotificationAction} />}</>
      ) : (
        <>{dialog.open && <Dialog action={handleDeleteAction} />}</>
      )}
    </div>
  );
};

export default Notification;
