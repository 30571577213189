import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loadPageData } from "../../actions/homePageActions";
import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import Breadcrumb from "../../Component/TopTitle";

const Index = () => {
  let { slug } = useParams();
  const dispatch = useDispatch();
  const pageData = useSelector((state) => state?.homePageData?.pageData);

  const prepareHtml = (description) => {
    if (description) {
      return { __html: description || "" };
    }
    return { __html: "" };
  };

  useEffect(() => {
    const fetchData = () => {
      dispatch(loadPageData(`/${slug}`));
    };
    fetchData();
  }, [dispatch, slug]);

  return (
    <Fragment>
      {pageData && pageData.translate && (
        <Fragment>
          <section className="inner_sec">
            <Container>
              <h2>{pageData.translate.title}</h2>
            </Container>
          </section>
          <Breadcrumb title={pageData.translate.title} />
          <section className="about_us_sec">
            <Container>
              <div
                className="about-page-text"
                dangerouslySetInnerHTML={prepareHtml(
                  pageData.translate.description
                )}
              ></div>
            </Container>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Index;
