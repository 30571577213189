import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import CommonLoader from "../Loader/CommonLoader";

const CommonDelete = ({ handleDelete, deleteLoading }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleDeleteLocal = () => {
    handleClose();
    handleDelete();
  };

  return (
    <>
      <p className="mb-0 common_delete_btn"  onClick={handleShow}>
        Delete
      </p>

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ marginBottom: "0" }}>
            Are you sure ?
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: "1rem" }}>
          The action will not be reversed
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button style={{ background: "#33a7cc" }} onClick={handleDeleteLocal}>
            {deleteLoading ? <CommonLoader /> : "Delete"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommonDelete;
