import { React, useState, useRef, useEffect } from "react";
import { Row, Col, Container, Form, Button, InputGroup } from "react-bootstrap";
import ChangePasswordModal from "./ChangePasswordModal";
import Sidebar from "./Sidebar";
import "react-phone-input-2/lib/style.css";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";
import {
  aboutDoffo,
  updateUseImage,
  updateUserProfile,
} from "../../actions/SIdebarAction";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LottiLoader from "../../Component/lottiAnimation/LottiLoader";
import DoffoVarificationModal from "./DoffoVarificationModal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FacebookConnect from "./FacebookConnect";
import { loadToasterData } from "../../actions/baseActions";
import { checkMobileNumber } from "../../utils/helpers";
import {
  StylesProvider,
  jssPreset,
  ThemeProvider,
} from "@material-ui/core/styles";
import { rtlTheme, ltrTheme } from "../../utils/helpers/index";
import { create } from "jss";
import rtl from "jss-rtl";

function AccountInfo() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();
  const { t } = useTranslation();
  const fileInput = useRef();
  const dispatch = useDispatch();

  // All Selectors
  const {
    GET_USER_PROFILE,
    ABOUT_DOFFO,
    ABOUT_DOFFO_LOADER,
    selectedLang,
    CHANGE_BANNER_LOADER,
  } = useSelector((state) => ({
    GET_USER_PROFILE: state?.sidebarReducer?.userProfileData,
    LOADING: state?.sidebarReducer?.sidebarLoader,
    ABOUT_DOFFO: state?.sidebarReducer?.aboutDoffo,
    ABOUT_DOFFO_LOADER: state?.sidebarReducer?.aboutLoading,
    CHANGE_BANNER_LOADER: state?.sidebarReducer?.changeBannerImageLoader,
    selectedLang: state.selectedLang,
  }));

  // All State
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showNameField, setShowNameField] = useState(false);
  const [showEmailField, setShowEmailField] = useState(false);
  const [showDobField, setShowDobField] = useState(false);
  const [showPhoneField, setShowPhoneField] = useState(false);
  const [doffoVerificationModal, setDoffoVerificationModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);

  const toggleChangePasswordModal = () => {
    setShowChangePasswordModal(!showChangePasswordModal);
  };

  useEffect(() => {
    const fetchAboutDoffoData = () => {
      dispatch(aboutDoffo("/about-us"));
    };
    fetchAboutDoffoData();
  }, [dispatch]);

  const toggleInput = (text) => {
    if (text === "name") {
      setShowNameField(!showNameField);
    }
    if (text === "email") {
      setShowEmailField(!showEmailField);
    }
    if (text === "dob") {
      setShowDobField(!showDobField);
    }
    if (text === "phone") {
      setShowPhoneField(!showPhoneField);
    }
  };

  useEffect(() => {
    if (GET_USER_PROFILE) {
      setValue("firstName", GET_USER_PROFILE?.first_name);
      setValue("lastName", GET_USER_PROFILE?.last_name);
      setValue("email", GET_USER_PROFILE?.email);
      setSelectedDate(new Date(GET_USER_PROFILE.dob));
      setValue("mobile_number", GET_USER_PROFILE?.mobile_number);
    }
  }, [GET_USER_PROFILE, setValue]);

  const resetAllFields = () => {
    setShowNameField(false);
    setShowEmailField(false);
    setShowDobField(false);
    setShowPhoneField(false);
  };

  const onSubmit = (formData) => {
    let postData = {
      first_name: formData.firstName,
      last_name: formData.lastName,
      email: formData.email,
      dob: selectedDate,
      mobile_number: formData.mobile_number,
    };
    dispatch(updateUserProfile(postData, resetAllFields));
  };

  const toggleDoffoVerificationModal = () => {
    setDoffoVerificationModal(!doffoVerificationModal);
  };

  const imageHandelChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size > 10485760) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_IS_TOO_BIG"
            )}`,
            "error",
            true
          )
        );
      } else if (
        e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "image/png"
      ) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_NOT_SUPPORTED"
            )}`,
            "error",
            true
          )
        );
      } else {
        const fileArray = Array.from(e.target.files).map(
          (file) =>
            // URL.createObjectURL(file)
            file
        );
        if (fileArray.length > 0) {
          setSelectedFile((prevImages) => prevImages.concat(fileArray));
          const formData = new FormData();
          fileArray.map((e) => {
            formData.append("image", e);
          });
          formData.append("type", "BACKGROUND");
          dispatch(updateUseImage(formData, "BACKGROUND"));
        }
      }
    }
  };

  const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

  return (
    <>
      <StylesProvider jss={jss}>
        <ThemeProvider theme={selectedLang === "ar" ? rtlTheme : ltrTheme}>
          <section
            className={
              selectedLang === "ar"
                ? "is-arabic middle_banner_sec"
                : "middle_banner_sec"
            }
            style={{
              backgroundImage: `url(${
                selectedFile.length !== 0
                  ? URL.createObjectURL(selectedFile[selectedFile?.length - 1])
                  : GET_USER_PROFILE?.backgroundImage
              })`,
            }}
          >
            <Container>
              <span className="camera_icon_banner">
                <input
                  type="file"
                  name="profilePicture"
                  accept="image/jpeg, image/png"
                  style={{ display: "none" }}
                  ref={fileInput}
                  onChange={(e) => imageHandelChange(e)}
                  onClick={(e) => (e.target.value = null)}
                />

                {CHANGE_BANNER_LOADER ? (
                  <span className="camera_icon">
                    <i
                      className="fa fa-spinner fa-spin "
                      style={{
                        background: "#C7C7CC",
                        color: "#ffffff",
                        padding: "8px",
                        borderRadius: "50%",
                        fontSize: "15px",
                        border: "2px solid #ffffff",
                      }}
                    />
                  </span>
                ) : (
                  <CameraAltIcon
                    onClick={() => fileInput.current.click()}
                    className="camera_icon_banner"
                    style={{
                      background: "#C7C7CC",
                      color: "#ffffff",
                      padding: "8px",
                      borderRadius: "50%",
                      fontSize: "40px",
                      border: "2px solid #ffffff",
                    }}
                  />
                )}
              </span>
            </Container>
          </section>

          <section
            className={
              selectedLang === "ar"
                ? "is-arabic manage_address"
                : "manage_address"
            }
          >
            <Container>
              <div className="sidebar_outer">
                <div className="sidebar_main">
                  <Sidebar />
                </div>

                <div className="dashboard_main">
                  <div className="outline_wrapper">
                    <h2>{t("ACCOUNT_INFO")}</h2>

                    <div className="my_account_sec">
                      <Form onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                          <Col lg={7}>
                            <div className="account_step">
                              <div className="order_detail_head border-0">
                                <h4>{t("ACCOUNT_INFO")}</h4>
                                <p
                                  className="danger "
                                  style={{ fontWeight: "bold" }}
                                >
                                  *
                                </p>
                                {!showNameField ? (
                                  <Tooltip title={t("TAP_TO_EDIT_NAME")}>
                                    <Button
                                      className="edit"
                                      onClick={() => toggleInput("name")}
                                    >
                                      <i className="fas fa-pen"></i>
                                      {t("EDIT")}
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  <>
                                    <Tooltip title={t("TAP_TO_UPDATE_CHANGES")}>
                                      <Button className="edit" type="submit">
                                        <i className="fas fa-check"></i>{" "}
                                        {t("UPDATE")}
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title={t("TAP_TO_REMOVE_CHANGES")}>
                                      <CloseIcon
                                        style={{ color: "#33A7CC" }}
                                        onClick={() => {
                                          setShowNameField(false);
                                          setValue(
                                            "firstName",
                                            GET_USER_PROFILE?.first_name
                                          );
                                          setValue(
                                            "lastName",
                                            GET_USER_PROFILE?.last_name
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  </>
                                )}
                              </div>
                              <div className="myaccount_group">
                                <Form.Group
                                  className="mb-3 form_field"
                                  controlId=""
                                >
                                  <Form.Control
                                    type="text"
                                    style={
                                      !showNameField
                                        ? { cursor: "not-allowed" }
                                        : { cursor: "text" }
                                    }
                                    readOnly={showNameField ? false : true}
                                    placeholder={t("ENTER_FIRST_NAME")}
                                    {...register("firstName", {
                                      required: t(
                                        "PLEASE_ENTER_YOUR_FIRST_NAME"
                                      ),
                                      minLength: {
                                        value: 3,
                                        message: t("FIRST_NAME_MINIMUM_LENGTH"),
                                      },
                                    })}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name="firstName"
                                    render={({ message }) => (
                                      <p className="error-message">{message}</p>
                                    )}
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="mb-3 ml-3 form_field last_name_user"
                                  controlId=""
                                >
                                  <Form.Control
                                    type="text"
                                    style={
                                      !showNameField
                                        ? { cursor: "not-allowed" }
                                        : { cursor: "text" }
                                    }
                                    readOnly={showNameField ? false : true}
                                    placeholder={t("ENTER_LAST_NAME")}
                                    {...register("lastName", {
                                      required: t("PLEASE_ENTER_LAST_NAME"),
                                      minLength: {
                                        value: 3,
                                        message: t("LAST_NAME_MINIMUM_LENGTH"),
                                      },
                                    })}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name="lastName"
                                    render={({ message }) => (
                                      <p className="error-message">{message}</p>
                                    )}
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <div className="account_step">
                              <div className="order_detail_head border-0">
                                <h4>{t("EMAIL_ADDRESS")}</h4>{" "}
                                <p
                                  className="danger "
                                  style={{ fontWeight: "bold" }}
                                >
                                  *
                                </p>
                                {!showEmailField ? (
                                  <Tooltip title={t("TAP_TO_EDIT_EMAIL")}>
                                    <Button
                                      className="edit"
                                      onClick={() => toggleInput("email")}
                                    >
                                      <i className="fas fa-pen"></i> {t("EDIT")}
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  <>
                                    <Tooltip title={t("TAP_TO_UPDATE_CHANGES")}>
                                      <Button className="edit" type="submit">
                                        <i className="fas fa-check"></i>{" "}
                                        {t("UPDATE")}
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title={t("TAP_TO_REMOVE_CHANGES")}>
                                      <CloseIcon
                                        style={{ color: "#33A7CC" }}
                                        onClick={() => {
                                          setShowEmailField(false);
                                          setValue(
                                            "email",
                                            GET_USER_PROFILE?.email
                                          );
                                        }}
                                      />
                                    </Tooltip>
                                  </>
                                )}
                                <Button
                                  className="edit"
                                  onClick={() => toggleChangePasswordModal()}
                                >
                                  {t("CHANGE_PASSWORD")}
                                </Button>
                              </div>
                              <div className="myaccount_group">
                                <Form.Group
                                  className="mb-3 form_field"
                                  controlId=""
                                >
                                  <Form.Control
                                    type="email"
                                    style={
                                      !showEmailField
                                        ? { cursor: "not-allowed" }
                                        : { cursor: "text" }
                                    }
                                    readOnly={showEmailField ? false : true}
                                    placeholder={t("SUBSCRIBE_PLACEHOLDER")}
                                    name="email"
                                    {...register("email", {
                                      required: t("PLEASE_ENTER_YOUR_EMAIL"),
                                      pattern: {
                                        value:
                                          /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                        message: t("INVALID_EMAIL"),
                                      },
                                      maxLength: {
                                        value: 50,
                                        message: t("EMAIL_MAXIMUM_LENGTH"),
                                      },
                                    })}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name="email"
                                    render={({ message }) => (
                                      <p className="error-message">{message}</p>
                                    )}
                                  />
                                </Form.Group>
                              </div>
                            </div>

                            <div className="account_step">
                              <div className="order_detail_head border-0">
                                <h4>{t("DATE_OF_BIRTH")}</h4>
                                {!showDobField ? (
                                  <Tooltip
                                    title={t("TAP_TO_EDIT_DATE_OF_BIRTH")}
                                  >
                                    <Button
                                      className="edit"
                                      onClick={() => toggleInput("dob")}
                                    >
                                      <i className="fas fa-pen"></i>
                                      {t("EDIT")}
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  <>
                                    <Tooltip title={t("TAP_TO_UPDATE_CHANGES")}>
                                      <Button className="edit" type="submit">
                                        <i className="fas fa-check"></i>{" "}
                                        {t("UPDATE")}
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title={t("TAP_TO_REMOVE_CHANGES")}>
                                      <CloseIcon
                                        style={{ color: "#33A7CC" }}
                                        onClick={() => {
                                          setShowDobField(false);
                                        }}
                                      />
                                    </Tooltip>
                                  </>
                                )}
                              </div>
                              <div className="myaccount_group">
                                <Form.Group
                                  className="mb-3 form_field"
                                  controlId=""
                                >
                                  {!showDobField ? (
                                    <Form.Control
                                      type="text"
                                      style={
                                        !showNameField
                                          ? { cursor: "not-allowed" }
                                          : { cursor: "text" }
                                      }
                                      readOnly={showNameField ? false : true}
                                      value={moment(
                                        GET_USER_PROFILE.dob
                                      ).format("DD/MM/YYYY")}
                                    />
                                  ) : (
                                    <DatePicker
                                      showIcon
                                      className="form-control"
                                      selected={selectedDate}
                                      onChange={(date) => setSelectedDate(date)}
                                      maxDate={new Date()}
                                      dateFormat="dd/MM/yyyy"
                                    />
                                  )}
                                </Form.Group>
                              </div>
                            </div>

                            <div className="account_step">
                              <div className="order_detail_head border-0">
                                <h4>{t("MOBILE_NUMBER")}</h4>{" "}
                                <p
                                  className="danger "
                                  style={{ fontWeight: "bold" }}
                                >
                                  *
                                </p>
                                {!showPhoneField ? (
                                  <Tooltip
                                    title={t("TAP_TO_EDIT_MOBILE_NUMBER")}
                                  >
                                    <Button
                                      className="edit"
                                      onClick={() => toggleInput("phone")}
                                    >
                                      <i className="fas fa-pen"></i>
                                      {t("EDIT")}
                                    </Button>
                                  </Tooltip>
                                ) : (
                                  <>
                                    <Tooltip title={t("TAP_TO_UPDATE_CHANGES")}>
                                      <Button className="edit" type="submit">
                                        <i className="fas fa-check"></i>{" "}
                                        {t("UPDATE")}
                                      </Button>
                                    </Tooltip>
                                    <Tooltip title={t("TAP_TO_REMOVE_CHANGES")}>
                                      <CloseIcon
                                        style={{ color: "#33A7CC" }}
                                        onClick={() => {
                                          setShowPhoneField(false);
                                        }}
                                      />
                                    </Tooltip>
                                  </>
                                )}
                              </div>
                              <div className="myaccount_group">
                                <div
                                  className="form_sub_grp"
                                  style={{ display: "flex" }}
                                >
                                  <InputGroup.Text>+966</InputGroup.Text>
                                  <Form.Control
                                    type="text"
                                    readOnly={!showPhoneField ? true : false}
                                    placeholder={t("MOBILE_NUMBER")}
                                    style={{ marginLeft: "5px" }}
                                    name="mobile_number"
                                    onKeyDown={(event) =>
                                      checkMobileNumber(event)
                                    }
                                    {...register("mobile_number", {
                                      required: t("PLEASE_ENTER_MOBILE_NUMBER"),
                                      minLength: {
                                        value: 7,
                                        message: t(
                                          "MOBILE_NUMBER_MINIUM_LENGTH"
                                        ),
                                      },
                                      maxLength: {
                                        value: 15,
                                        message: t(
                                          "MOBILE_NUMBER_MAXIMUM_LENGTH"
                                        ),
                                      },
                                    })}
                                  />
                                </div>
                                <ErrorMessage
                                  errors={errors}
                                  name="mobile_number"
                                  render={({ message }) => (
                                    <p className="error-message">{message}</p>
                                  )}
                                />
                              </div>
                            </div>

                            <div className="account_infor_verfiy">
                              <ul>
                                {GET_USER_PROFILE.photo !== "" &&
                                GET_USER_PROFILE.id_proof !== "" ? (
                                  <li>
                                    {t("DOFFO_VERIFIED")}{" "}
                                    <span>
                                      <img
                                        src="../assets/images/tick.png"
                                        alt=""
                                      />
                                    </span>
                                  </li>
                                ) : (
                                  <li
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      toggleDoffoVerificationModal()
                                    }
                                  >
                                    {t("DOFFO_VERIFIED")}{" "}
                                  </li>
                                )}
                                <li>
                                  <FacebookConnect />

                                  <span>
                                    <img
                                      src="../assets/images/tick.png"
                                      alt=""
                                    />
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                      <div className="doffo_info">
                        <h2>{t("ABOUT_DOFFO")}</h2>

                        {ABOUT_DOFFO_LOADER ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            <LottiLoader
                              loader={true}
                              height={100}
                              width={100}
                            />
                          </div>
                        ) : (
                          <div
                            dangerouslySetInnerHTML={{ __html: ABOUT_DOFFO }}
                          ></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </section>
          <ChangePasswordModal
            show={showChangePasswordModal}
            onHide={() => toggleChangePasswordModal()}
          />
          <DoffoVarificationModal
            show={doffoVerificationModal}
            onHide={() => toggleDoffoVerificationModal()}
          />
        </ThemeProvider>
      </StylesProvider>
    </>
  );
}

export default AccountInfo;
