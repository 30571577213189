import * as types from '../actions/actionsTypes'
import initialState from './initialState'


export default function networkRequest(state = initialState.submitPost, action) {
  switch (action.type) {
    case types.SUBMIT_POST:
      return action.submitPost
    default:
      return state
  }
}
