import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function dialogReducer(state = initialState.dialog, action) {
  switch (action.type) {
    case types.LOADED_DIALOG_INFO_SUCCESS:
      return action.dialog;
    default:
      return state;
  }
}
