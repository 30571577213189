import { combineReducers } from "redux";
import initialState from "./initialState";
import isAuth from "./isAuthRequest";
import selectedLang from "./selectedLangRequest";
import toaster from "./toasterReducer";
import userParams from "./isUserParams";
import userInfo from "./isUserInfo";
import resetPass from "./resetPass";
import loginPopup from "./loginPopup";
import registerPopup from "./registerPopup";
import forgotPassPopup from "./forgotPass";
import registerVerify from "./registerVerify";
import isSubmitting from "./isSubmittingRequest";
import isFetching from "./networkRequest";
import dialog from "./dialogReducer";
import pageData from "./pageReducers";
import setting from "./settingReducer";
import faqData from "./faqDataReducer";
import contactUs from "./contactUsReducer";
import homePageData from "./homePageReducers";
import changePassPopup from "./changePassPopup";
import changePassInfo from "./changeUserPassword";
import userProfileInfo from "./userProfileDataReducer";
import emailStatus from "./updateEmailNotifyReducer";
import pushStatus from "./updatePushNotifyReducer";
import language from "./languageReducer";
import manageAddressPopup from "./manageAddressPopup";
import addUserAddress from "./addAddressReducer";
import getuserdata from "./getUserAddressReducer";
import deleteuseradd from "./deleteAddressReducer";
import defaultuseradd from "./setdefaultAddressReducer";
import updateuseradd from "./updateAddressReducer";
import createpost from "./createPostReducer";
import publicProfile from "./publicProfileReducer";
import getCategoriesData from "./getCategoryReducer";
import getBrandData from "./getBrandReducer";
import getModalData from "./PostModalReducer";
import submitPost from "./onSubmitPostReducer";
import getConditionData from "./conditionReducer";
import getAddressListData from "./getAddressListReducer";
import postPreview from "./postPreviewReducer";
import UserReducer from "./UserReducer";
import { profileReducer } from "../redux/reducers/profileReducers";
import SidebarReducer from "./SidebarReducer";
import HomeReducer from "./homeReducer";
import bankData from "./bankDataReducer";
import walletData from "./walletDataReducer";
import productOffer from "./ProductOfferReducer";
import paymentData from "./paymentDataReducer";
import device_token from "./deviceTokenRequest";
import socialParamsPopup from "./socialParamsPopup";
import parentCategory from "./parentCategoryReducers";

const rootReducer = combineReducers({
  isAuth,
  selectedLang,
  toaster,
  userParams,
  userInfo,
  resetPass,
  loginPopup,
  registerPopup,
  forgotPassPopup,
  registerVerify,
  isSubmitting,
  dialog,
  pageData,
  isFetching,
  setting,
  faqData,
  bankData,
  walletData,
  contactUs,
  homePageData,
  changePassPopup,
  changePassInfo,
  userProfileInfo,
  emailStatus,
  pushStatus,
  language,
  manageAddressPopup,
  addUserAddress,
  getuserdata,
  deleteuseradd,
  defaultuseradd,
  updateuseradd,
  createpost,
  publicProfile,
  getCategoriesData,
  getBrandData,
  getModalData,
  submitPost,
  getConditionData,
  getAddressListData,
  postPreview,
  productOffer,
  userReducer: UserReducer,
  profile: profileReducer,
  sidebarReducer: SidebarReducer,
  homeReducer: HomeReducer,
  paymentData,
  device_token,
  socialParamsPopup,
  parentCategory,
});

export default function combinedRootReducer(state, action) {
  return action.type === "LOG_OUT"
    ? rootReducer(initialState, action)
    : rootReducer(state, action);
}
