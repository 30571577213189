import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function forgotPass(
  state = initialState.forgotPassPopup,
  action
) {
  switch (action.type) {
    case types.LOADED_FORGET_POPUP:
      return action.forgotPassPopup;
    default:
      return state;
  }
}
