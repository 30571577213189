import React, { useEffect, useRef, useState } from "react";

import {
  Row,
  Col,
  Container,
  Form,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { IconButton } from "@mui/material";
import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import Tooltip from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import { loadToasterData } from "../../../actions/baseActions";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { ErrorMessage } from "@hookform/error-message";
import Autocomplete from "@mui/material/Autocomplete";

import {
  getPostCategory,
  getBrand,
  getModal,
  createPost,
  onGetCondition,
  getAddressList,
} from "../../../actions/postAction";
import TextField from "@mui/material/TextField";
import { checkMobileNumber } from "../../../utils/helpers";
import { crose } from "../../../assets/images/index";
import Dialog from "@mui/material/Dialog";
import PostPreview from "../../Post/postPreview";
import { Link, useHistory, useLocation } from "react-router-dom";
import CustomSwitch from "../../../Component/Switch/CustomSwitch";
import Switch from "@mui/material/Switch";
import PostCreatedSucessModal from "../../Post/PostCreatedSucessModal";
import InfoIcon from "@mui/icons-material/Info";

// import { DropzoneArea } from "material-ui-dropzone";

function CreatePostPage() {
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    values,
  } = useForm();
  const fileInput = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  // All Selectors
  const {
    getCategoriesData,
    getBrandData,
    getModalData,
    getConditionData,
    getAddressListData,
    selectedLang,
  } = useSelector((state) => ({
    getCategoriesData: state.getCategoriesData,
    getBrandData: state.getBrandData,
    getModalData: state.getModalData,
    getConditionData: state.getConditionData,
    getAddressListData: state.getAddressListData,
    selectedLang: state.selectedLang,
  }));

  //   All State
  const [selectedFile, setSelectedFile] = useState([]);
  const [onEditSelectedFile, SetOnEditSelectedFile] = useState([]);
  const [category, setCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [condition, setCondition] = useState("");
  const [paymentMode, setPayment] = useState("");
  const [isshipping, setShipping] = useState(false);
  const [isMeetup, setMeetUp] = useState(false);
  const [addessId, setAddressId] = useState("");
  const [whoPay, setWhoPay] = useState("");
  const [showdialog, setShowDialog] = useState(false);
  const [previewData, setPreviewData] = useState({});
  const [showPreview, setShowPreview] = useState(false);
  const [itemInfo, setItemInfo] = useState({});
  const [addressError, setAddressError] = useState("");
  const [deliverPreferanceError, setDeliverPreferanceError] = useState("");
  const [switchBox, setSwitchBox] = useState(true);
  const [postSuccessModal, setPostSuccessModal] = useState(false);
  const [deleteImagesFile, setDeletedImageFile] = useState([]);
  const [postId, setPostId] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");
  const [brandTitle, setBrandTitle] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [pageType] = useState("sell");

  const imageHandelChange = (e) => {
    if (e.target.files) {
      if (e.target.files[0].size > 10485760) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_IS_TOO_BIG"
            )}`,
            "error",
            true
          )
        );
      } else if (
        e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "image/png"
      ) {
        dispatch(
          loadToasterData(
            `${t("FILE")} ${e.target.files[0].name} ${t(
              "FILE_SIZE_NOT_SUPPORTED"
            )}`,
            "error",
            true
          )
        );
      } else {
        const fileArray = Array.from(e.target.files).map(
          (file) =>
            // URL.createObjectURL(file)
            file
        );

        const tempArr = fileArray.concat(selectedFile);

        if (tempArr.length > 6) {
          dispatch(
            loadToasterData(
              `${t("CAN_NOT_ALLOW_TO_ADD_MORE_THEN_SIX")}`,
              "error",
              true
            )
          );
        } else {
          setSelectedFile((prevImages) => prevImages.concat(fileArray));
          Array.from(e.target.files).map((file) => URL.revokeObjectURL(file));
        }
      }
    }
  };

  const deleteUploadedImages = (index) => {
    const copyPostArray = Object.assign([], selectedFile);
    copyPostArray.splice(index, 1);
    setSelectedFile(copyPostArray);
  };

  const renderPhotos = (source) => {
    return source.map((photo, index) => {
      // source.map((photo, index) => {
      return (
        <div style={{ position: "relative" }} key={index}>
          <IconButton
            style={{
              position: "absolute",
              top: "3px",
              right: "14px",
              fontSize: "14px",
              backgroundColor: "#ffffff",
            }}
            onClick={(e) => deleteUploadedImages(index)}
          >
            <CloseIcon style={{ fontSize: "18px", color: "#000" }} />
          </IconButton>
          <img
            src={URL.createObjectURL(photo)}
            key={photo}
            alt="NO_IMG"
            style={{
              height: "150px",
              width: "150px",
              marginRight: "10px",
              marginBottom: "10px",
            }}
          />
        </div>
      );
    });
  };

  const onSubmit = (data) => {
    const isValid = onSubmitValidation(data.price);

    if (isValid) {
      const formData = new FormData();
      var tempItemInfo = {};
      if (isshipping === true) {
        tempItemInfo.weight = data.weight;
        tempItemInfo.length = data.length;
        tempItemInfo.width = data.width;
        tempItemInfo.height = data.height;
        setItemInfo({
          weight: data.weight,
          length: data.length,
          width: data.width,
          height: data.height,
        });
      } else {
        tempItemInfo.weight = null;
        tempItemInfo.length = null;
        tempItemInfo.width = null;
        tempItemInfo.height = null;
        setItemInfo({
          weight: null,
          length: null,
          width: null,
          height: null,
        });
      }

      let createPostData = {
        title: data.title,
        category: category,
        brand: brand,
        brandModel: model,
        condition: condition,
        description: data.description,
        price: data.price,
        // payment_type: paymentMode,
        payment_type: "Cash",
        shipping: isshipping,
        who_pay: whoPay,
        shipping_id: addessId,
        location: [26.8467, 80.9462],
        buyNow: switchBox,
        meetUp: isMeetup,
        // item_information: JSON.stringify(tempItemInfo),
        item_information: tempItemInfo,
      };

      console.log("tempItemInfo", createPostData);

      if (postId) {
        formData.append("title", data.title);
        formData.append("category", category);
        formData.append("brand", brand);
        formData.append("brandModel", model);
        formData.append("condition", condition);
        formData.append("description", data.description);
        formData.append("price", data.price);
        formData.append("payment_type", "Cash");
        formData.append("shipping", isshipping);
        formData.append("buyNow", switchBox);
        formData.append("meetUp", isMeetup);
        formData.append("shipping_id", addessId);
        formData.append("item_information", JSON.stringify(tempItemInfo));
        formData.append("who_pay", whoPay);
        formData.append("location", [26.8467, 80.9462]);
        formData.append("_id", postId);
        formData.append("deleteImage", JSON.stringify(deleteImagesFile));
      }

      if (selectedFile.length > 0) {
        selectedFile.map((e) => {
          formData.append("image", e);
        });
      }
      setAddressError("");
      setDeliverPreferanceError("");
      dispatch(
        createPost(
          createPostData,
          formData,
          showPreview,
          getPeviewData,
          history,
          handleShowDialog,
          togglePostSucessModal,
          postId,
          pageType
        )
      );
    }
  };
  const getPeviewData = (previewResData) => {
    // console.log("PRIVEW DATA", previewResData);
    setPreviewData(previewResData);
  };

  const onSubmitValidation = (price) => {
    // console.log("PRICE IN VALIDATION", price);
    let addressError = "";
    let deliveryPreference = "";

    if (!addessId) {
      addressError = t("PLEASE_SELECT_LOCATION");
    }

    if (!isMeetup) {
      if (!isshipping) {
        deliveryPreference = t("PLEASE_SELECT_DELIVERY_PREFERENCE");
      }
    }

    if (isshipping === true) {
      if (price > 1000) {
        dispatch(
          loadToasterData(
            `${t("PRICE_MUST_BE_LESS_THEN_1000")} `,
            "error",
            true
          )
        );
        return false;
      }
    }

    if (addressError || deliveryPreference) {
      setAddressError(addressError);
      setDeliverPreferanceError(deliveryPreference);
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    const fetchData = () => {
      dispatch(getPostCategory());
    };
    const fetchConditionData = () => {
      dispatch(onGetCondition());
    };
    const fetchAddressListData = () => {
      dispatch(getAddressList());
    };
    fetchData();
    fetchConditionData();
    fetchAddressListData();
  }, [dispatch]);

  const onCategoryHandel = (catgName) => {
    if (catgName !== null) {
      // console.log("CAT NAME", catgName);
      dispatch(getBrand(catgName._id));
      setCategory(catgName._id);
      setCategoryTitle("");
      setBrandTitle("");
      setModalTitle("");
    }
  };

  const onBrandHandel = (brandName) => {
    if (brandName !== null) {
      dispatch(getModal(brandName._id));
      setBrand(brandName._id);
      setBrandTitle("");
      setModalTitle("");
    }
  };

  const onModelHandel = (modelName) => {
    if (modelName !== null) {
      setModel(modelName._id);
      setModalTitle("");
    }
  };

  const onHanelCondition = (id) => {
    setCondition(id);
  };

  const onHandelPayment = (paymentType) => {
    setPayment(paymentType);
  };

  const onShippingHandel = () => {
    setShipping(!isshipping);
  };

  const onMeetupHandel = () => {
    setMeetUp(!isMeetup);
  };

  const onHandelAddress = (event) => {
    setAddressId(event.target.value);
  };

  const onHandelPay = (onPay) => {
    setWhoPay(onPay);
  };
  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);

  const OnHandelPreview = (ispreview) => {
    setShowPreview(ispreview);
  };

  const handelSwitchBoxChange = (event) => {
    setSwitchBox(!switchBox);
  };

  const togglePostSucessModal = () => {
    setPostSuccessModal(!postSuccessModal);
  };

  useEffect(() => {
    if (location?.pathname !== "/create-post") {
      setPostId(location?.state?.data?._id);
      setValue("title", location?.state?.data?.title);
      setValue("description", location?.state?.data?.description);
      setValue("price", location?.state?.data?.price);
      setShipping(location?.state?.data?.shipping);
      setMeetUp(location?.state?.data?.meetUp);
      setSwitchBox(location?.state?.data?.buyNow);
      if (location?.state?.data?.item_information?.length) {
        setValue("weight", location?.state?.data?.item_information?.weight);
        setValue("length", location?.state?.data?.item_information?.length);
        setValue("width", location?.state?.data?.item_information?.width);
        setValue("height", location?.state?.data?.item_information?.height);
      }
      setAddressId(location?.state?.data?.addresses._id);
      setWhoPay(location?.state?.data?.who_pay);
      setCondition(location?.state?.data?.conditions);

      if (
        location?.state?.data?.image !== 0 &&
        location?.state?.data?.image !== undefined
      ) {
        SetOnEditSelectedFile((prevImages) =>
          prevImages.concat(location?.state?.data?.image)
        );
      }
      setValue("category", location?.state?.data?.categories?.id);
      setCategory(location?.state?.data?.categories?.id);
      setCategoryTitle(location?.state?.data?.categories?.title);
      setValue("brand", location?.state?.data?.brands?.id);
      setBrand(location?.state?.data?.brands?.id);
      setBrandTitle(location?.state?.data?.brands?.title);
      setModel(location?.state?.data?.models?.id);
      setModalTitle(location?.state?.data?.models?.title);
    }
  }, [location?.state]);

  const deletePreviousUploadedImages = (item, index) => {
    const temp = [];
    temp.push(...deleteImagesFile, { id: item?.id });
    setDeletedImageFile(temp);
    const copyPostArray = Object.assign([], onEditSelectedFile);
    copyPostArray.splice(index, 1);
    SetOnEditSelectedFile(copyPostArray);
  };

  return (
    <div className={selectedLang == "ar" ? "is-arabic" : ""}>
      <section className="bredcum_all">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">
                  {t("HOME")}
                  <i className="fas fa-chevron-right"></i>
                </Link>
              </li>

              <>
                <li>
                  {/* <Link to={`/${selectedLang}/manage-post-list`}> */}
                  {pageType == "manage" ? (
                    <Link to={`/manage`}>
                      <span>{t("MANAGE")}</span>{" "}
                      <i className="fas fa-chevron-right"></i>
                    </Link>
                  ) : (
                    <Link to={`/manage-post-list`}>
                      <span>{t("MANAGE_POST")}</span>{" "}
                      <i className="fas fa-chevron-right"></i>
                    </Link>
                  )}
                </li>
              </>
              {location?.pathname !== "/create-post" ? (
                <li>
                  <span>
                    {t("EDIT")} {t("RIGHT_SIDEBAR_POST")}
                  </span>
                </li>
              ) : (
                <li>
                  <span>{t("CREATE_POST")}</span>
                </li>
              )}
            </ul>
          </div>
        </Container>
      </section>

      <section className="create_post_sec">
        <Container>
          <Form
            className="post_form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            autoComplete="off"
          >
            <Row>
              <Col lg={6} md={6}>
                <Form.Label>{t("ADD_PHOTOS_IMAGES")}</Form.Label>

                <div className="upload_post" style={{ position: "relative" }}>
                  {selectedFile.concat(onEditSelectedFile)?.length !== 0 &&
                    selectedFile.concat(onEditSelectedFile)?.length < 6 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                        className="add-more-main"
                      >
                        <Tooltip title="Add More Images">
                          <AddAPhotoIcon
                            color="primary"
                            fontSize="medium"
                            onClick={() => fileInput.current.click()}
                          />
                        </Tooltip>
                      </div>
                    )}

                  <input
                    type="file"
                    name="profilePicture"
                    accept="image/jpeg, image/png"
                    style={{ display: "none" }}
                    ref={fileInput}
                    onChange={(e) => imageHandelChange(e)}
                    multiple
                    onClick={(e) => (e.target.value = null)}
                  />

                  {selectedFile.length === 0 &&
                    onEditSelectedFile?.length === 0 && (
                      <span
                        onClick={() => fileInput.current.click()}
                        style={{ cursor: "pointer" }}
                      >
                        <AddAPhotoIcon color="primary" fontSize="medium" />{" "}
                        {t("UPLOAD_IMAGE")}
                      </span>
                    )}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {onEditSelectedFile?.length !== 0 &&
                      onEditSelectedFile?.map((photo, index) => {
                        return (
                          <div style={{ position: "relative" }} key={photo?.id}>
                            <IconButton
                              style={{
                                position: "absolute",
                                top: "3px",
                                right: "14px",
                                fontSize: "14px",
                                backgroundColor: "#ffffff",
                              }}
                              onClick={(e) =>
                                deletePreviousUploadedImages(photo, index)
                              }
                            >
                              <CloseIcon
                                style={{ fontSize: "18px", color: "#000" }}
                              />
                            </IconButton>
                            <img
                              src={photo?.url}
                              alt={t("NO_IMG")}
                              style={{
                                height: "150px",
                                width: "150px",
                                marginRight: "10px",
                                marginBottom: "10px",
                              }}
                            />
                          </div>
                        );
                      })}

                    {renderPhotos(selectedFile)}
                  </div>
                </div>
              </Col>

              <Col lg={6} md={6}>
                <div className="form_title_post">
                  <Form.Group
                    className="mb-4 same_input"
                    controlId="formGroupEmail"
                  >
                    <Form.Label>{t("TITLE")}</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder={t("ENTER_TITLE")}
                      {...register("title", {
                        required: t("ENTER_POST_TITLE"),

                        minLength: {
                          value: 3,
                          message: t("POST_TITLE_MUST_BE_MORE"),
                        },
                        maxLength: {
                          value: 50,
                          message: t("POST_TITLE_MUST_BE_LESS"),
                        },
                      })}
                      autoFocus={true}
                      style={{
                        borderColor: "darkGray",
                        borderRadius: "4px",
                        color: "#000",
                        fontWeight: "400",
                        padding: "0px 8px",
                        fontSize: "18px",
                      }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="title"
                      render={({ message }) => (
                        <p className="error-message">{message}</p>
                      )}
                    />
                  </Form.Group>

                  {getCategoriesData.length > 0 && (
                    <Form.Group
                      className="mb-4 same_input"
                      controlId="formGroupCategory"
                    >
                      <Form.Label>{t("CATEGORY")}</Form.Label>
                      <Autocomplete
                        disablePortal
                        id="checkboxes-tags-demo"
                        groupBy={(option) => option.categories.title}
                        options={getCategoriesData}
                        onChange={(event, value) => onCategoryHandel(value)}
                        getOptionLabel={(option) =>
                          option.translate && option.translate.title
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={categoryTitle ? categoryTitle : " "}
                            variant="outlined"
                            InputLabelProps={{ shrink: false }}
                            fullWidth
                            // required
                            name="category"
                            {...register("category", {
                              required: t("SELECT_CATEGORY"),
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="category"
                        render={({ message }) => (
                          <p className="error-message">{message}</p>
                        )}
                      />
                    </Form.Group>
                  )}

                  {getCategoriesData.length > 0 && (
                    <Form.Group
                      className="mb-4 same_input"
                      controlId="formGroupBrand"
                    >
                      <Form.Label>{t("BRAND")}</Form.Label>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={getBrandData}
                        onChange={(event, value) => onBrandHandel(value)}
                        getOptionLabel={(option) =>
                          getBrandData.length !== 0
                            ? option.translate && option.translate.title
                            : " "
                        }
                        // getOptionLabel={(option) =>
                        //   option.translate && option.translate.title
                        // }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={brandTitle ? brandTitle : " "}
                            variant="outlined"
                            fullWidth
                            InputLabelProps={{ shrink: false }}
                            // required
                            name="brand"
                            {...register("brand", {
                              required: t("SELECT_BRAND"),
                            })}
                          />
                        )}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="brand"
                        render={({ message }) => (
                          <p className="error-message">{message}</p>
                        )}
                      />
                    </Form.Group>
                  )}

                  {getCategoriesData.length > 0 && (
                    <Form.Group
                      className="mb-4 same_input"
                      controlId="formGroupModel"
                    >
                      <Form.Label>
                        {t("MODAL")}
                        <span> ({t("OPTIONAL")})</span>{" "}
                      </Form.Label>
                      <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={getModalData}
                        disableCloseOnSelect
                        onChange={(event, value) => onModelHandel(value)}
                        getOptionLabel={(option) =>
                          getBrandData.length !== 0 && getModalData.length !== 0
                            ? option.translate && option.translate.title
                            : ""
                        }
                        // getOptionLabel={(option) =>
                        //   option.translate && option.translate.title
                        // }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={modalTitle ? modalTitle : " "}
                            variant="outlined"
                            fullWidth
                            // required
                            InputLabelProps={{ shrink: false }}
                            name="brandModel"
                            // {...register("brandModel", {
                            //   required: t("PLEASE_ENTER_MODEL"),
                            // })}
                          />
                        )}
                      />
                      {/* <ErrorMessage
                        errors={errors}
                        name="brandModel"
                        render={({ message }) => (
                          <p className="error-message">{message}</p>
                        )}
                      /> */}
                    </Form.Group>
                  )}
                </div>
              </Col>

              <Col lg={12} className="mt-4">
                <Form.Label>{t("CONDITIONS")}</Form.Label>
                <div className="condition_post">
                  <Row>
                    {getConditionData.map((item, index) => (
                      <Col lg={6} md={6} key={index}>
                        <div className="condition_label mb-3">
                          <label>
                            <input
                              type="radio"
                              name="condition"
                              {...register("condition", {
                                required: t("SELECT_CONDITION"),
                              })}
                              onChange={() => onHanelCondition(item._id)}
                              checked={item._id == condition ? true : false}
                            />
                            <small>{item.translate.title}</small>{" "}
                            <span className="radio_indicator"></span>
                            <span className="outlineborder"></span>
                          </label>
                        </div>
                      </Col>
                    ))}
                    <ErrorMessage
                      errors={errors}
                      name="condition"
                      render={({ message }) => (
                        <p className="error-message">{message}</p>
                      )}
                    />
                  </Row>
                </div>
              </Col>

              <Col lg={12} className="mt-4">
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <Form.Label>
                    {t("DESCRIPTIONS")} ({t("OPTIONAL")})
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    name="description"
                    {...register("description", {})}
                    rows={4}
                    placeholder={t("ENTER_DESCRIPTION")}
                    style={{
                      borderColor: "darkGray",
                      borderRadius: "4px",
                      color: "#000",
                      fontWeight: "400",
                      padding: "0px 8px",
                      fontSize: "18px",
                      resize: "none",
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="description"
                    render={({ message }) => (
                      <p className="error-message">{message}</p>
                    )}
                  />
                </Form.Group>
              </Col>
              <Col lg={6}>
                <Form.Group
                  className="mb-4 same_input"
                  controlId="formGroupEmail"
                >
                  <Form.Label>{t("PRICE_IN_SR")}</Form.Label>
                  <Form.Control
                    name="price"
                    onKeyDown={(event) => checkMobileNumber(event)}
                    type="text"
                    placeholder={t("ENTER_PRICE")}
                    {...register("price", {
                      required: t("ENTER_PRICE"),
                    })}
                  />{" "}
                  <ErrorMessage
                    errors={errors}
                    name="price"
                    render={({ message }) => (
                      <p className="error-message">{message}</p>
                    )}
                  />
                </Form.Group>
              </Col>

              <Col lg={6} md={6}>
                <Form.Group
                  className="mb-4 same_input"
                  controlId="formGroupEmail"
                >
                  <Form.Label>{t("ITEM_LOCATION")}</Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    // name="location"
                    // {...register("location", {
                    //   required: "cxzzxzcx",
                    // })}
                    value={addessId}
                    onChange={onHandelAddress}
                  >
                    <option value="">{t("SELECT_ADDRESS")}</option>
                    {getAddressListData.map((item, index) => (
                      <option value={item._id} key={item._id}>
                        {item.building +
                          " " +
                          item.landmark +
                          " " +
                          item.district +
                          " " +
                          item.city +
                          " " +
                          item.postal_code}
                      </option>
                    ))}
                  </Form.Select>

                  {/* {console.log("ERROR OF ADDRESS", errors)} */}

                  {/* <ErrorMessage
                    errors={errors}
                    name="addessId"
                    render={({ message }) => (
                      <p className="error-message">{message}</p>
                    )}
                  /> */}

                  <span style={{ color: "red", fontSize: "16px" }}>
                    {addressError}
                  </span>
                </Form.Group>
              </Col>

              {/* <Col lg={6}>
                <Row>
                  <Form.Label>Payment Preference</Form.Label>

                  <Col lg={6} md={6}>
                    <div className="condition_label payment_prefrence mb-3">
                      <label>
                        <input
                          type="radio"
                          name="payment_type"
                          onClick={() => onHandelPayment("Cash")}
                        />
                        <small>Cash</small>{" "}
                        <span className="radio_indicator"></span>
                        <span className="outlineborder"></span>
                      </label>
                    </div>
                  </Col>

                  <Col lg={6} md={6}>
                    <div className="condition_label payment_prefrence mb-3">
                      <label>
                        <input
                          type="radio"
                          name="payment_type"
                          onClick={() => onHandelPayment("Online Payment")}
                        />
                        <small>Online Payment</small>{" "}
                        <span className="radio_indicator"></span>
                        <span className="outlineborder"></span>
                      </label>
                    </div>
                  </Col>
                </Row>
              </Col> */}

              <Row>
                <Col lg={6} className="mt-4 d-flex">
                  <Form.Label className="d-flex align-items-center">
                    {t("SHIPPING")}{" "}
                    <Tooltip title={t("PLEASE_ENTER_UPTO_1000")}>
                      <InfoIcon style={{ color: "#33A7CC" }} />
                    </Tooltip>{" "}
                  </Form.Label>
                  <span className="email_notify d-flex ">
                    <input
                      type="checkbox"
                      id="switch1"
                      onChange={onShippingHandel}
                      checked={isshipping}
                    />
                    <label htmlFor="switch1" className="nlabel ml-0"></label>
                  </span>
                </Col>
                <Col lg={6} className="mt-4 d-flex">
                  <Form.Label className="d-flex align-items-center">
                    {t("MEET_UP")}
                    {/* <InfoIcon style={{ color: "#33A7CC" }} /> */}
                  </Form.Label>
                  <span className="email_notify d-flex">
                    <input
                      type="checkbox"
                      id="switch2"
                      onChange={onMeetupHandel}
                      checked={isMeetup}
                    />
                    <label htmlFor="switch2" className="nlabel ml-0"></label>
                  </span>
                </Col>
                <span style={{ color: "red", fontSize: "16px" }}>
                  {deliverPreferanceError}
                </span>
              </Row>
              {isshipping && (
                <>
                  <Col lg={12} className="mt-4">
                    <div className="condition_post">
                      <Row>
                        <Col lg={6} md={6}>
                          <Form.Label>
                            {t("WHO_WILL_PAY_FOR_SHIPPING")}
                          </Form.Label>
                          <div className="condition_label mb-3">
                            <label>
                              <input
                                type="radio"
                                name="name1"
                                onClick={() => onHandelPay("Seller")}
                                {...register("name1", {
                                  required: t(
                                    "SELECT_WHO_WILL_PAY_FOR_SHIPPING"
                                  ),
                                })}
                                checked={whoPay == "Seller" ? true : false}
                              />
                              <small>{t("SELLER")}</small>{" "}
                              <span className="radio_indicator"></span>
                              <span className="outlineborder"></span>
                            </label>
                          </div>
                        </Col>

                        <Col lg={6} md={6}>
                          <Form.Label className="invisible">
                            {t("WHO_WILL_PAY_FOR_SHIPPING")}
                          </Form.Label>
                          <div className="condition_label mb-3">
                            <label>
                              <input
                                type="radio"
                                name="name1"
                                onClick={() => onHandelPay("Buyer")}
                                {...register("name1", {
                                  required: t(
                                    "SELECT_WHO_WILL_PAY_FOR_SHIPPING"
                                  ),
                                })}
                                checked={whoPay == "Buyer" ? true : false}
                              />
                              <small>{t("BUYER")}</small>{" "}
                              <span className="radio_indicator"></span>
                              <span className="outlineborder"></span>
                            </label>
                          </div>
                        </Col>
                        <ErrorMessage
                          errors={errors}
                          name="name1"
                          render={({ message }) => (
                            <p className="error-message">{message}</p>
                          )}
                        />
                      </Row>
                    </div>
                  </Col>
                  <Col lg={12} className="mt-4">
                    <Form.Label>{t("ITEM_INFORMATION")}</Form.Label>
                    <div className="condition_post weight_form">
                      <Row>
                        <Col lg={12} md={12}>
                          <Form.Label>{t("HOW_MUCH_WEIGHT")}</Form.Label>
                          <br />
                          <Form.Label>{t("ITEM_WEIGHT")}</Form.Label>
                          <InputGroup className="mb-3 same_input">
                            <FormControl
                              type="text"
                              placeholder=""
                              name="weight"
                              onKeyDown={(event) => checkMobileNumber(event)}
                              {...register("weight", {
                                required: t("ENTER_ITEM_WEIGHT"),
                              })}
                            />

                            <InputGroup.Text>{t("KG")}</InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            errors={errors}
                            name="weight"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Col>
                        <Col lg={12} md={12}>
                          <Form.Label>{t("WHAT_IS_ITEM_SIZE")}</Form.Label>
                        </Col>

                        <Col lg={4} md={6}>
                          <Form.Label>{t("ITEM_LENGTH")}</Form.Label>
                          <InputGroup className="mb-4 same_input">
                            <FormControl
                              type="text"
                              placeholder=""
                              name="length"
                              onKeyDown={(event) => checkMobileNumber(event)}
                              {...register("length", {
                                required: t("ENTER_ITEM_LENGTH"),
                              })}
                            />
                            <InputGroup.Text>{t("CM")}</InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            errors={errors}
                            name="length"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Col>

                        <Col lg={4} md={6}>
                          <Form.Label>{t("ITEM_WIDTH")}</Form.Label>
                          <InputGroup className="mb-4 same_input">
                            <FormControl
                              type="text"
                              placeholder=""
                              name="width"
                              onKeyDown={(event) => checkMobileNumber(event)}
                              {...register("width", {
                                required: t("ENTER_ITEM_WIDTH"),
                              })}
                            />
                            <InputGroup.Text>{t("CM")}</InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            errors={errors}
                            name="width"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Col>

                        <Col lg={4} md={6}>
                          <Form.Label>{t("ITEM_HEIGHT")}</Form.Label>
                          <InputGroup className="mb-3 same_input">
                            <FormControl
                              type="text"
                              placeholder=""
                              name="height"
                              onKeyDown={(event) => checkMobileNumber(event)}
                              {...register("height", {
                                required: t("ENTER_ITEM_HEIGHT"),
                              })}
                            />
                            <InputGroup.Text>{t("CM")}</InputGroup.Text>
                          </InputGroup>
                          <ErrorMessage
                            errors={errors}
                            name="height"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Col>
                        <Col lg={4} md={6}>
                          <Form.Label>{t("BUY_NOW")}</Form.Label>
                          <InputGroup className="mb-3 same_input">
                            <CustomSwitch
                              switchBox={switchBox}
                              handelSwitchBoxChange={handelSwitchBoxChange}
                            />
                          </InputGroup>
                          <ErrorMessage
                            errors={errors}
                            name="height"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </>
              )}

              <div className="d-flex justify-content-end py-3 py-md-5 post_footer">
                <div className="add_new_btn text-end">
                  <Button
                    type="submit"
                    onClick={() => OnHandelPreview(true)}
                    className="theme_btn btn"
                  >
                    {t("PREVIEW")}
                  </Button>

                  {location?.pathname !== "/create-post" ? (
                    <Button
                      type="submit"
                      onClick={() => OnHandelPreview(false)}
                      className="theme_btn btn ml-1"
                    >
                      {t("SAVE")}
                    </Button>
                  ) : (
                    <Button
                      type="submit"
                      onClick={() => OnHandelPreview(false)}
                      className="theme_btn btn ml-1"
                    >
                      {t("SAVE")}
                    </Button>
                  )}
                </div>
              </div>
            </Row>
          </Form>
        </Container>
        {previewData && (
          <Dialog fullScreen open={showdialog} onClose={handleCloseDialog}>
            <button className="crose_btn" onClick={handleCloseDialog}>
              <img src={crose} alt="Close icon" />
            </button>

            <PostPreview
              data={previewData}
              selectedFile={selectedFile}
              handleCloseDialog={handleCloseDialog}
              onEditSelectedFile={onEditSelectedFile}
            />
          </Dialog>
        )}

        <PostCreatedSucessModal
          show={postSuccessModal}
          onHide={() => togglePostSucessModal()}
        />
      </section>
    </div>
  );
}

export default CreatePostPage;
