

import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function getCategoryReducer(state = initialState.getCategoriesData, action) {
  switch (action.type) {
    case types.GET_CATEGORY:
      return action.getCategoriesData;
    default:
      return state;
  }
}