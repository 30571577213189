export const LOGIN = "auth/customerLoginV1";
export const Register = "auth/registerV2";
export const FORGOT_PASSWORD = "auth/customer-forgot-password";
export const CHANGE_PASSWORD = "users/change-password";
export const RESET_PASSWORD = "auth/update-password-with-otp";
export const SOCIAL_LOGIN = "auth/social-login";
export const CHECK_USER = "auth/checkUser";
export const UPDATE_PROFILE = "users/user-profile-update";
export const RESEND_OTP_MOBILE = "auth/resend-otp";
export const OTP_VERIFY_MOBILE = "auth/otp-verify";
export const EMAIL_VERIFY_MOBILE = "auth/verify-email";
export const CMS_PAGE = "cms/page";
export const SETTING = "setting";
export const SUBMIT_CONTACT_REQUEST = "cms/contactUs";
export const LOAD_FAQ = "faq/get-listing";
export const LOAD_PROFILE_INFO = "users/get-user-profile";
export const LOAD_PUBLIC_PROFILE_URL = "users/publicProfile";
export const LOAD_PRODUCT_LIST = "home/postItem";
export const UPDATE_EMAIL_NOTIFY = "users/update-user-notification";
export const UPDATE_PUSH_NOTIFY = "users/pushNotification";
export const UPDATE_LANGUGAGE_URL = "users/update-user-language";
export const USER_ADDRESS_URL = "address";
export const CREATE_POST_URL = "post/dummy";
export const GET_CATEGORY_URL = "category/subCategory";
export const GET_BRAND_URL = "brand/list";
export const GET_MODAL_URL = "model/listing";
export const ON_POST_SUBMIT = "post/submit";
export const POST_PREVIEW_URL = "post/preview";
export const ON_GET_CONDITION = "setting/condition";
export const GET_ADDRESS_LIST_URL = "address";
export const FETCH_PRODUCT_DETAILS = "post/details";
export const GET_POST = "post";
export const DELETE_POST = "post";
export const GET_EDIT_POST = "post/editItem";
export const ON_UPDATE_POST = "post/update";
export const GET_USER_IP_ADDRESS = "https://geolocation-db.com/json";
export const GET_ABOUT_US_DATA = "cms/page/guideline";
export const PAGE_DATA = "cms/page";
export const ARCHIVE_POST = "post/archive";
export const GET_USER_PROFILE = "users/get-user-profile";
export const UPDATE_USER_PROFILE = "users/user-profile-update";
export const DOFFO_VERIFY = "users/doffoVerify";
export const PUBLIC_PROFILE = "users/publicProfile";
export const FAVORITE_ADD_REMOVE = "favorite";
export const UPDATE_USER_IMAGE = "users/updateImage";
export const GET_ARCHIVE = "post/archive";
export const UPDATE_EMAIL_NOTIFICATION = "users/update-user-notification";
export const UPDATE_PUSH_NOTIFICATION = "users/pushNotification";
export const GET_SELLING_HISTORY = "order/selling";
export const GET_BUYING_HISTORY = "order";
export const GET_ORDER_DETAIL = "order/details";
export const RETURN_REQUEST = "order/returnRequest";
export const GET_FAVORITE_LIST = "favorite";
export const GET_RECOMMENDED_LIST = "recommend";
export const GET_BUYING = "offer/buying";
export const GET_REPORT_LIST = "setting/report";
export const SUBMIT_REPORT = "report/item";
export const BUY_NOW = "product-payment/buy-now";
export const PRODUCT_REVIEW = "home/buyNowReview";
export const PURCHASE_PRODUCT = "order/purchase";
export const COUPON_VERIFY = "coupon/verify";
export const CONTACT_SELLER = "offer/contactSeller";
export const FILTER_CATEGORY = "category/category-listing";
export const FACEBOOK_CONNECT = "users/faceBookVerify";
export const SUBSCRIPTION_PLAN = "subscription/subscription-list";
export const SUBSCRIPTION_BUY = "product-payment/subscription_purchase";
export const SUBSCRIPTION_PLAN_BUY_CONFIRM = "subscription/buy-plan";
export const SELL_ANOTHER = "post/editItem";
export const LOAD_BANK_DATA = "bank";
export const LOAD_CARD = "card";
export const LOAD_WALLET = "users/wallet";
export const LOAD_CASHOUT = "users/cashOut";
export const AUTOMATICALLY_BANK_ACCOUNT = "users/automaticallyBackAccount";
export const COUNTER_REVIEW_OFFER = "offer/review/counter";
export const OFFER_VIEWS = "offer/review";
export const REMAINING_BOOST = "subscription/remainingBoot";
export const REMAINING_BOOST_APPLY = "subscription/apply-boot";
export const CARD_LIST = "card";
export const GET_USER_SOLD_LIST = "offer/soldList";
export const MARK_AS_SOLD = "order/soldItem";
export const CHECK_OFFER = "offer/check";
export const GET_FEEDBACK_LIST = "feedback";
export const FEEDBACK_SUBMIT = "feedback/submit";
export const SUBMIT_MEETUP = "offer/set-meet-up";
export const SEND_OFFER = "offer/submit";
export const ORDER_PAYMENT = "product-payment/order-payment";
export const AUTHORIZE_PAYMENT = "product-payment/autorize-payment";
export const VIEW_RETURN_REQUEST = "returnRequest/view";
export const VIEW_POST_DATA = "offer/listing";
export const GET_PROFILE_BALANCE = "users/get-user-profile";
export const GET_NOTIFICATIONS_DATA = "notification";
export const DELETE_NOTIFICATION = "notification/delete";
export const DELETE_NOTIFICATION_ALL = "notification";
