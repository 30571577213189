import React, { useRef, useState } from "react";
import { Form, Button, Modal, Tooltip, Overlay } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { changePassword } from "../../actions/SIdebarAction";

function ChangePasswordModal({ show, onHide }) {
  const target = useRef(null);
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    values,
    watch,
    clearErrors,
  } = useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const password = useRef({});
  password.current = watch("password", "");

  // All state
  const [showhover, sethover] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const closeModal = () => {
    onHide();
    setValue("current_password", "");
    setValue("password", "");
    setValue("password_confirmation", "");
    clearErrors();
    setShowOldPassword(false);
    setShowNewPassword(false);
    setShowConfirmPassword(false);
  };

  const onSubmit = (formData) => {
    console.log("FORM DATA", formData);
    dispatch(changePassword(formData, closeModal));
  };

  const togglePassword = (text) => {
    if (text === "oldPass") {
      setShowOldPassword(!showOldPassword);
    }
    if (text === "newPass") {
      setShowNewPassword(!showNewPassword);
    }
    if (text === "conformPass") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={() => false} className="change_passsord_modal">
        <Modal.Header>
          <h4 className="mb-0">{t("CHANGE_PASSWORD")}</h4>
          <button
            className="crose_btn"
            onClick={() => {
              onHide();
              setValue("current_password", "");
              setValue("password", "");
              setValue("password_confirmation", "");
              clearErrors();
            }}
          >
            <img src="./assets/images/crose.png" alt="No_IMAGE" />
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="change_password_main">
            <Form className="login_form" onSubmit={handleSubmit(onSubmit)}>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>{t("CURRENT_PASSWORD")}</Form.Label>{" "}
                <div className="sign_up_doffo">
                  <Form.Control
                    type={showOldPassword ? "text" : "password"}
                    placeholder={t("ENTER_YOUR_CURRENT_PASSWORD")}
                    {...register("current_password", {
                      required: t("PASSWORD_REQUIRED"),
                      minLength: {
                        value: 6,
                        message: t("PASSWORD_MINIMUM_LENGTH"),
                      },
                      maxLength: {
                        value: 50,
                        message: t("PASSWORD_MAXIMUM_LENGTH"),
                      },
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})$/i,
                        message: t("PASSWORD_PATTERN_VALIDATION"),
                      },
                    })}
                  />
                  <span
                    className="text_view"
                    onClick={() => togglePassword("oldPass")}
                  >
                    {showOldPassword ? t("HIDE") : t("SHOW")}
                  </span>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="current_password"
                  render={({ message }) => (
                    <p className="error-message">{message}</p>
                  )}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>
                  {t("NEW_PASSWORD")}{" "}
                  <span
                    className="d-inline-block px-2 password_rest"
                    ref={target}
                    onClick={() => sethover(!showhover)}
                  >
                    <img src="../assets/images/pro_fees_icon.svg" alt="" />
                  </span>
                </Form.Label>
                <Overlay
                  target={target.current}
                  show={showhover}
                  placement="right"
                >
                  {(props) => (
                    <Tooltip
                      id="overlay-example"
                      {...props}
                      className="password_restrication"
                    >
                      <span style={{ color: "#000", float: "right" }}>
                        <IconButton onClick={() => sethover(!showhover)}>
                          <CloseIcon style={{ fontSize: "14px" }} />
                        </IconButton>
                      </span>
                      <ul>
                        <li>
                          <span>
                            <i class="fas fa-check"></i>
                          </span>
                          {t("CONTAIN_EIGHT_CHARACTERS")}
                        </li>
                        <li>
                          <span>
                            <i class="fas fa-check"></i>
                          </span>
                          {t("AT_LEAST_ONE_CHARACTER_UPPERCASE")}
                        </li>
                        <li>
                          <span>
                            <i class="fas fa-check"></i>
                          </span>
                          {t("AT_LEAST_ONE_NUMBER_0_9")}
                        </li>
                        <li>
                          <span>
                            <i class="fas fa-check"></i>
                          </span>
                          {t("AT_LEAST_ONE_SYMBOL")}
                        </li>
                      </ul>
                    </Tooltip>
                  )}
                </Overlay>
                <div className="sign_up_doffo">
                  <Form.Control
                    type={showNewPassword ? "text" : "password"}
                    placeholder={t("ENTER_YOUR_NEW_PASSWORD")}
                    {...register("password", {
                      required: t("PASSWORD_REQUIRED"),
                      minLength: {
                        value: 6,
                        message: t("PASSWORD_MINIMUM_LENGTH"),
                      },
                      maxLength: {
                        value: 50,
                        message: t("PASSWORD_MAXIMUM_LENGTH"),
                      },
                      pattern: {
                        value:
                          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})$/i,
                        message: t("PASSWORD_PATTERN_VALIDATION"),
                      },
                    })}
                  />
                  <span
                    className="text_view"
                    onClick={() => togglePassword("newPass")}
                  >
                    {showNewPassword ? t("HIDE") : t("SHOW")}
                  </span>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) => (
                    <p className="error-message">{message}</p>
                  )}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formGroupEmail">
                <Form.Label>{t("CONFIRM_NEW_PASSWORD")}</Form.Label>
                <div className="sign_up_doffo">
                  <Form.Control
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Re-enter password"
                    {...register("password_confirmation", {
                      required: t("PASSWORD_REQUIRED"),
                      minLength: {
                        value: 6,
                        message: t("PASSWORD_MINIMUM_LENGTH"),
                      },
                      maxLength: {
                        value: 50,
                        message: t("PASSWORD_MAXIMUM_LENGTH"),
                      },
                      validate: (value) => {
                        if (value === "") {
                          return true;
                        }
                        console.log(
                          "password.current",
                          password.current,
                          "VALUE",
                          value
                        );
                        if (value !== password.current)
                          return t("PASSWORD_DO_NOT_MATCH");
                      },
                    })}
                  />
                  <span
                    className="text_view"
                    onClick={() => togglePassword("conformPass")}
                  >
                    {showConfirmPassword ? t("HIDE") : t("SHOW")}
                  </span>
                </div>
                <ErrorMessage
                  errors={errors}
                  name="password_confirmation"
                  render={({ message }) => (
                    <p className="error-message">{message}</p>
                  )}
                />
              </Form.Group>
              <Button className="theme_btn" type="submit">
                {t("CHANGE_PASSWORD")}
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ChangePasswordModal;
