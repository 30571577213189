import { React, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { sendOffer } from "../../actions/homePageActions";
import {
  sendCounterOffer,
  initiateSocket,
  joinRoom,
  disconnectSocket,
} from "../../utils/Socket";
import { useSelector } from "react-redux";
import { loadToasterData } from "../../actions/baseActions";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
const queryString = require("query-string");

function Purchase(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const queryStringParsed = queryString.parse(location.search);
  const { selectedLang, paymentData } = useSelector((state) => ({
    selectedLang: state.selectedLang,
    paymentData: state.paymentData,
  }));

  // All Selectors

  useEffect(() => {
    initiateSocket(paymentData.user_id);
    if (paymentData && paymentData.room_id) {
      joinRoom(paymentData && paymentData.room_id);
    }
    return () => {
      disconnectSocket();
    };
  }, [paymentData]);

  // All Selectors
  useEffect(() => {
    if (queryStringParsed.Result === "Failure") {
      dispatch(loadToasterData(t("PAYMENT_FAILED"), "error", true));
      setTimeout(function () {
        if (paymentData && paymentData.authorize_type === "Counter") {
          history.push(`/chat/${paymentData.room_id}`);
        } else if (
          paymentData &&
          paymentData.authorize_type === "Seller_Counter"
        ) {
          history.push(`/manage`);
        } else {
          history.push(`/product/${paymentData.post_id}`);
        }
      }, 1000);
    } else {
      if (paymentData && paymentData.authorize_type === "Counter") {
        var request = {
          user_id: paymentData.user_id,
          room_id: paymentData.room_id,
          post_id: paymentData.post_id,
          amount: paymentData.amount,
          paymentType: paymentData.payment_type,
          paymentId: queryStringParsed.PaymentId,
          cardId: paymentData.card_id,
          language: selectedLang,
        };

        setTimeout(function () {
          sendCounterOffer(request);
          history.push(`/chat/${paymentData.room_id}`);
        }, 2000);
      } else if (
        paymentData &&
        paymentData.authorize_type === "Seller_Counter"
      ) {
        const request = {
          user_id: paymentData.user_id,
          room_id: paymentData.room_id,
          post_id: paymentData.post_id,
          amount: paymentData.amount,
          paymentType: paymentData.payment_type,
          paymentId: queryStringParsed.PaymentId,
          cardId: paymentData.card_id,
          language: selectedLang,
        };
        sendCounterOffer(request);
        setTimeout(function () {
          history.push(`/manage`);
          //  window.href.location = "/manage";
        }, 1000);
      } else {
        var paymentType = "ONLINE";
        const request = {
          post_id: paymentData.post_id,
          amount: paymentData.amount,
          code: paymentData.coupon_code,
          address_id: paymentData.address_id,
          shipping: paymentData.shipping,
          meetUp: paymentData.meetUp,
          paymentType: paymentType,
          room_id: paymentData.room_id,
          paymentId: queryStringParsed.PaymentId,
          cardId: paymentData.card_id,
          useWallet: paymentData.useWallet,
        };
        dispatch(sendOffer(request, updateOffer, history));
      }
    }
  }, []);

  useEffect(() => {
    initiateSocket(paymentData.user_id);
    joinRoom(paymentData.room_id);
    return () => {
      disconnectSocket();
    };
  }, [paymentData.room_id, paymentData.user_id]);

  const updateOffer = (data) => {
    if (data) {
      //dispatch(loadToasterData(data.message, "success", true));
      history.push(`/chat/${data.room}`);
    } else {
      //   dispatch(loadToasterData(data.message, "error", true));
      history.push(`/`);
    }
  };
  /* useEffect(() => {
    subscribeToSuccessfully((err, data) => {
      // if (err) return;
      dispatch(loadToasterData(data.message, "success", true));
    });

    subscribeToError((err, data) => {
      // if (err) return;
      dispatch(loadToasterData(data.message, "error", true));
      //history.push(`/`);
    });
  }, []); */
  return (
    <div>
      <Backdrop
        sx={{ color: "#00BFFF", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <section className="section-b-space light-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="success-text">
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                <h2>{t("THANK_YOU")}</h2>
                <p>{t("THANK_YOU_MESSAGE")}</p>
                <p>
                  {t("TRANSACTION_ID")}:{queryStringParsed.TranId}
                </p>
                {/* <p>Order ID:{getOrderID.order_id}</p>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Purchase;
