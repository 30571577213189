import { React, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Modal, Row, Col, Dropdown, Button } from "react-bootstrap";
import { ValidateAlpha, checkMobileNumber } from "../../utils/helpers";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { makeStyles } from "@material-ui/core/styles";
import {
  addUserAddress,
  fetchUserAddressData,
  deleteUserAddress,
  setDefaultUserAddress,
  updateUserAddress,
} from "../../actions/addressManagerAction";
import { toggleButton, toggle, crose } from "../../assets/images/index";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#33a7cc",
    },
    "& .Mui-focused": {
      color: "#33a7cc",
    },
  },
});
const AddressManager = () => {
  const { push } = useHistory();
  const dispatch = useDispatch();
  const [edituser, setEditUser] = useState([]);
  const [edituserlength, setEditUserlength] = useState(0);
  const { getuserdata } = useSelector((state) => ({
    getuserdata: state.getuserdata,
  }));

  useEffect(() => {
    const fetchData = () => {
      dispatch(fetchUserAddressData());
    };
    fetchData();
  }, [dispatch]);

  const [showdialog, setShowDialog] = useState(false);
  const handleCloseDialog = () => setShowDialog(false);
  const handleShowDialog = () => setShowDialog(true);
  const { t } = useTranslation();
  const classes = useStyles();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onSubmit = (data) => {
    if (edituserlength > 0) {
      dispatch(updateUserAddress(data, edituser.id));
    } else {
      dispatch(addUserAddress(data));
    }
    dispatch(fetchUserAddressData());
    handleCloseDialog();
  };

  const handelDeletAddress = (id) => {
    dispatch(
      deleteUserAddress(
        {
          id: id,
        },
        push
      )
    );
    dispatch(fetchUserAddressData());
  };

  const handelDefaultAddress = (id) => {
    dispatch(
      setDefaultUserAddress(
        {
          id: id,
        },
        push
      )
    );
    dispatch(fetchUserAddressData());
  };

  const handelUpdateAddress = (id) => {
    let filteredValue = getuserdata.filter((x) => x._id === id);
    let editedvalue = {
      title: filteredValue[0].title,
      location: filteredValue[0].location,
      address: filteredValue[0].address,
      building: filteredValue[0].building,
      city: filteredValue[0].city,
      district: filteredValue[0].district,
      landmark: filteredValue[0].landmark,
      postal_code: filteredValue[0].postal_code,
      id: id,
    };
    setEditUser(editedvalue);
    handleShowDialog();
    setEditUserlength(filteredValue.length);
  };
  const onAddNewAddress = () => {
    handleShowDialog();
    setEditUser([]);
  };
  function dashsidebar() {
    document.getElementById("onscroll").classList.toggle("dashboard_sidebar");
  }
  return (
    <>
      <div className="manage_address">
        <div className="toggle_main">
          <div className="togggle_dashboard" onClick={dashsidebar}>
            <img src={toggle} alt="toggle-icon" />
          </div>
        </div>
        <div className="outline_wrapper">
          <h2>{t("MANAGE_ADDRESS")}</h2>
          <Row>
            {undefined !== getuserdata && getuserdata.length !== 0
              ? Object.keys(getuserdata).map((item) => (
                  <Col
                    sm={6}
                    className={
                      getuserdata[item].is_default ? "default_address_wrap" : ""
                    }
                    key={getuserdata[item]._id}
                  >
                    <div className="order_detail_box">
                      <div className="order_detail_head">
                        <span>OFFICE</span>
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="success"
                            id="dropdown-basic"
                          >
                            <img src={toggleButton} alt="Toggle Button" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                handelUpdateAddress(getuserdata[item]._id)
                              }
                            >
                              Edit
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                handelDeletAddress(getuserdata[item]._id)
                              }
                            >
                              Delete
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() =>
                                handelDefaultAddress(getuserdata[item]._id)
                              }
                            >
                              Set As Default
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="order_caption">
                        <strong>{getuserdata[item].title}</strong>{" "}
                        <strong className="ml-3 d-inline-block"></strong>
                        <p>
                          {getuserdata[item].building +
                            " " +
                            getuserdata[item].landmark +
                            " " +
                            getuserdata[item].district +
                            " " +
                            getuserdata[item].city +
                            " " +
                            getuserdata[item].postal_code}
                        </p>
                      </div>
                    </div>
                  </Col>
                ))
              : "No data found"}
            <Col sm={12} className="mb-3 mb-md-5">
              <div className="add_new_btn">
                <Button className="theme_btn" onClick={onAddNewAddress}>
                  {t("ADD_NEW_ADDRESS")}
                </Button>
              </div>
            </Col>
            {/* manage_address */}

            <Modal
              show={showdialog}
              onHide={handleCloseDialog}
              className="add_card"
            >
              <button className="crose_btn" onClick={handleCloseDialog}>
                <img src={crose} alt="Close icon" />
              </button>
              <Modal.Body>
                <div className="add_new_card">
                  <h4>{t("ADD_SHIPPING_DETAIL")}</h4>
                  <Form
                    className="addcard_form"
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    autoComplete="off"
                  >
                    <Row>
                      <Col md={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formGrouptitle">
                          <Form.Control
                            className={!errors.title ? classes.root : "w-100"}
                            name="title"
                            type="text"
                            defaultValue={edituser.title}
                            placeholder={t("ADDRESS_NAME")}
                            {...register("title", {
                              required: t("PLEASE_ENTER_ADDRESS_TITLE"),

                              minLength: {
                                value: 3,
                                message: t("ADDRESS_TITLE_MINIMUM_LENGTH"),
                              },
                              maxLength: {
                                value: 50,
                                message: t("ADDRESS_TITLE_MAXIMUM_LENGTH"),
                              },
                            })}
                            autoFocus={true}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="title"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formGrouptitle">
                          <Form.Control
                            className={
                              !errors.location ? classes.root : "w-100"
                            }
                            name="location"
                            onKeyDown={(event) => checkMobileNumber(event)}
                            type="text"
                            defaultValue={edituser.location}
                            placeholder={t("USER_LOCATION")}
                            {...register("location", {
                              required: t("PLEASE_ENTER_LOCATION"),

                              minLength: {
                                value: 3,
                                message: t("LOCATION_MINIMUM_LENGTH"),
                              },
                              maxLength: {
                                value: 50,
                                message: t("LOCATION_MAXIMUM_LENGTH"),
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="location"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Form.Group>
                      </Col>
                      {/* <Col md={12} className="mb-3">
                    <div>
                      <figure>
                        <img src="./assets/images/google_map.jpg"   alt="Map"/>
                      </figure>
                    </div>
                  </Col> */}

                      <Col md={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formGrouptitle">
                          <Form.Control
                            className={!errors.address ? classes.root : "w-100"}
                            name="address"
                            type="text"
                            defaultValue={edituser.address}
                            placeholder={t("STREET")}
                            {...register("address", {
                              required: t("PLEASE_ENTER_ADDRESS"),

                              minLength: {
                                value: 10,
                                message: t("ADDRESS_MINIMUM_LENGTH"),
                              },
                              maxLength: {
                                value: 250,
                                message: t("ADDRESS_MAXIMUM_LENGTH"),
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="address"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formGrouptitle">
                          <Form.Control
                            className={
                              !errors.building ? classes.root : "w-100"
                            }
                            name="building"
                            type="text"
                            defaultValue={edituser.building}
                            placeholder={t("BUILDING_ADDRESS")}
                            {...register("building", {
                              required: t("PLEASE_ENTER_BUILDING"),

                              minLength: {
                                value: 3,
                                message: t("BUILDING_MINIMUM_LENGTH"),
                              },
                              maxLength: {
                                value: 50,
                                message: t("BUILDING_MAXIMUM_LENGTH"),
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="building"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formGrouptitle">
                          <Form.Control
                            className={!errors.city ? classes.root : "w-100"}
                            name="city"
                            onKeyDown={(event) => ValidateAlpha(event)}
                            type="text"
                            defaultValue={edituser.city}
                            placeholder={t("CITY")}
                            {...register("city", {
                              required: t("PLEASE_ENTER_CITY"),

                              minLength: {
                                value: 3,
                                message: t("CITY_MINIMUM_LENGTH"),
                              },
                              maxLength: {
                                value: 50,
                                message: t("CITY_MAXIMUM_LENGTH"),
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="city"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6} className="mb-3">
                        <Form.Group className="mb-3" controlId="formGrouptitle">
                          <Form.Control
                            className={
                              !errors.district ? classes.root : "w-100"
                            }
                            name="district"
                            onKeyDown={(event) => ValidateAlpha(event)}
                            type="text"
                            defaultValue={edituser.district}
                            placeholder={t("DISTRICT")}
                            {...register("district", {
                              required: t("PLEASE_ENTER_DISTRICT"),

                              minLength: {
                                value: 3,
                                message: t("DISTRICT_MINIMUM_LENGTH"),
                              },
                              maxLength: {
                                value: 50,
                                message: t("DISTRICT_MAXIMUM_LENGTH"),
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="district"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={6} className="mb-3">
                        <Form.Group className="mb-3" controlId="formGrouptitle">
                          <Form.Control
                            className={
                              !errors.postal_code ? classes.root : "w-100"
                            }
                            name="postal_code"
                            onKeyDown={(event) => checkMobileNumber(event)}
                            type="text"
                            defaultValue={edituser.postal_code}
                            placeholder={t("POSTAL_CODE")}
                            {...register("postal_code", {
                              required: t("PLEASE_ENTER_POSTAL_CODE"),

                              minLength: {
                                value: 5,
                                message: t("POSTAL_CODE_MINIMUM_LENGTH"),
                              },
                              maxLength: {
                                value: 5,
                                message: t("POSTAL_CODE_MAXIMUM_LENGTH"),
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="postal_code"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Form.Group>
                      </Col>

                      <Col md={12} className="mb-3">
                        <Form.Group className="mb-3" controlId="formGrouptitle">
                          <Form.Control
                            className={
                              !errors.landmark ? classes.root : "w-100"
                            }
                            name="landmark"
                            type="text"
                            defaultValue={edituser.landmark}
                            placeholder={t("LANDMARK")}
                            {...register("landmark", {
                              required: t("PLEASE_ENTER_LANDMARK"),

                              minLength: {
                                value: 3,
                                message: t("LANDMARK_MINIMUM_LENGTH"),
                              },
                              maxLength: {
                                value: 50,
                                message: t("LANDMARK_MAXIMUM_LENGTH"),
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="landmark"
                            render={({ message }) => (
                              <p className="error-message">{message}</p>
                            )}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      type="submit"
                      className="theme_btn text-center d-inline-block"
                    >
                      Save
                    </Button>
                  </Form>
                </div>
              </Modal.Body>
            </Modal>
            {/* manage_address */}
          </Row>
        </div>
      </div>
    </>
  );
};
export default AddressManager;
