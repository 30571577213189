const initialState = {
  postData: [],
  editPostData: {},
  postListPagination: {},
  loadingStatus: false,
  buyingData: [],
  userSoldList: {},
  userSoldListLoader: false,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case "POST_LIST":
      return {
        ...state,
        postData: action.payload,
      };

    case "EDIT_POST_DATA":
      return {
        ...state,
        editPostData: action.payload,
      };

    case "POST_LIST_PAGINATION":
      return {
        ...state,
        postListPagination: action.payload,
      };
    case "LOADING_STATUS":
      return {
        ...state,
        loadingStatus: action.payload,
      };

    case "BUYING_LIST":
      return {
        ...state,
        buyingData: action.payload,
      };

    case "USER_SOLD_LIST":
      return {
        ...state,
        userSoldList: action.payload,
      };
    case "USER_SOLD_LIST_LOADER":
      return {
        ...state,
        userSoldListLoader: action.payload,
      };
    default:
      return state;
  }
};

export default UserReducer;
