import React from "react";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  switchBase: {
    color: "#33A7CC",
    "&$checked": {
      color: "#33A7CC",
    },
    "&$checked + $track": {
      backgroundColor: "#93D0E4",
    },
  },

  track: {
    backgroundColor: "#787C80",
  },
  checked: {},
}));

const CustomSwitch = ({ switchBox, handelSwitchBoxChange }) => {
  const classes = useStyles();

  return (
    <div>
      <Switch
        checked={switchBox}
        onClick={(e) => handelSwitchBoxChange(e)}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
      />
    </div>
  );
};

export default CustomSwitch;
