import React from "react";
import Lottie from "react-lottie";

import sbLoader from "../../assets/lottiAnimations/apLoader2.json";
import sbNoRecord from "../../assets/lottiAnimations/noRecord2.json";
import { Link } from "react-router-dom";

// import wsLoader2 from "assets/lottieFile/BasicLoader2.json";

export default function LottiLoader({
  loader,
  height,
  width,
  message,
  previousMessage,
  linkTo,
}) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader ? sbLoader : sbNoRecord,
    // animationData: sbNoRecord,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <>
      <Lottie
        options={defaultOptions}
        height={height ?? 120}
        width={width ?? 120}
      />
      <p
        style={{
          color: "#33A7CC",
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "20px",
          fontSize: "20px",
        }}
      >
        {message ?? ""}
      </p>

      {linkTo && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "#0077B6",
            fontWeight: "bold",
          }}
        >
          <i className="fa fa-angle-double-left" />
          &nbsp;
          <Link
            to={linkTo}
            style={{ textDecoration: "none", color: "#0077B6" }}
          >
            {previousMessage ?? ""}
          </Link>
        </div>
      )}
    </>
  );
}
