import { Modal } from "react-bootstrap";
import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import successImage from "../../assets/images/congratulations.png";

function PostCreatedSucessModal({ show, onHide }) {
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <div>
      {" "}
      <Modal show={show} onHide={() => false} className="success_modal">
        {/* <button className="crose_btn" onClick={onHide}>
          <img src="./assets/images/crose.png" alt="Close Icon" />
        </button> */}
        <Modal.Body>
          <div className="success_popup">
            <figure>
              <img
                // src="./assets/images/congratulations.png"
                src={successImage}
                alt="Congrats Images"
              />
            </figure>
            <h3>{t("CONGRATULATIONS")}</h3>
            <small>{t("POST_CREATED_SUCCESSFULLY")}</small>
            <button
              className="theme_btn"
              // onClick={() => history.push("/manage-post-list")}
              onClick={() => history.push("/manage")}
            >
              {t("VIEW_POST")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default PostCreatedSucessModal;
