import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../actions/baseActions";
const { REACT_APP_DEFAULT_LANG } = process.env;

const LangSelector = (props) => {
  const { selectedLang } = props;
  const { pathname } = useLocation();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedLang === "en" || selectedLang === "ar") {
      i18n.changeLanguage(selectedLang);
      dispatch(setLanguage(selectedLang));
    } else {
      i18n.changeLanguage(REACT_APP_DEFAULT_LANG);
      dispatch(setLanguage(REACT_APP_DEFAULT_LANG));
    }
  }, [pathname, i18n, dispatch, selectedLang]);

  return null;
};

export default LangSelector;
