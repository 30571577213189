import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function ChangePass(
  state = initialState.changePassPopup,
  action
) {
  switch (action.type) {
    case types.LOADED_CHANGE_PASSWORD_POPUP:
      return action.changePassPopup;
    default:
      return state;
  }
}
