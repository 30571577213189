import { React, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../Sidebar";
import { Link, useHistory, useLocation } from "react-router-dom";
import CustomTabs from "../../../Component/Tabs/Tabs";
import {
  addRemoveFavorite,
  getFavorite,
  getRecommend,
} from "../../../actions/SIdebarAction";
import LottiLoader from "../../../Component/lottiAnimation/LottiLoader";
import saleLogo from "../../../assets/images/like_pro.svg";
import { useTranslation } from "react-i18next";
import Pagination from "@mui/material/Pagination";
import { Divider } from "@mui/material";
import { TextFormate } from "../../../Component/TextFormate/TexFormate";

function Like() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();

  // All Selectors
  const {
    selectedLang,
    GET_USER_PROFILE,
    GET_FAVORITE__RECOMMENDED_LIST,
    GET_FAVORITE__RECOMMENDED_PAGINATION,
    GET_FAVORITE__RECOMMENDED_LOADER,
  } = useSelector((state) => ({
    selectedLang: state.selectedLang,
    GET_USER_PROFILE: state?.sidebarReducer?.userProfileData,
    GET_FAVORITE__RECOMMENDED_LIST:
      state?.sidebarReducer?.favoriteAndRecommendList,
    GET_FAVORITE__RECOMMENDED_PAGINATION:
      state?.sidebarReducer?.favoriteAndRecommendPagination,
    GET_FAVORITE__RECOMMENDED_LOADER:
      state?.sidebarReducer?.favoriteAndRecommendLoading,
  }));

  // All state
  const customTab = [
    { eventKey: "favorite", title: t("FAVORITE") },
    { eventKey: "recommend", title: t("RECOMMENDED") },
  ];
  const [activeDefaultTab, setActiveDefaultTab] = useState("favorite");
  const [page, setPage] = useState(1);

  const onSelectTab = (e) => {
    history.push({
      pathname: "/like",
    });
    setActiveDefaultTab(e);
    if (e == "favorite") {
      dispatch(getFavorite());
    }
    if (e == "recommend") {
      dispatch(getRecommend());
    }
  };

  useEffect(() => {
    if (activeDefaultTab == "favorite") {
      dispatch(getFavorite());
    }
    if (activeDefaultTab == "recommend") {
      dispatch(getRecommend());
    }
  }, []);

  const habdelFavoratePost = (id, userID) => {
    const data = {
      post_id: id,
    };

    if (activeDefaultTab == "favorite") {
      dispatch(addRemoveFavorite(data, userID, "likes"));
    }
    if (activeDefaultTab == "recommend") {
      dispatch(addRemoveFavorite(data, userID, "recommended"));
    }
  };

  const pagination = (e, value) => {
    // console.log("value sdasd", value);
    const params = new URLSearchParams(location.search);
    params.set("page", value);
    history.push({
      pathname: "/like",
      search: "?" + params,
    });
    setPage(value);

    if (activeDefaultTab == "favorite") {
      dispatch(getFavorite());
    }
    if (activeDefaultTab == "recommend") {
      dispatch(getRecommend());
    }
  };

  return (
    <>
      <section
        className="middle_banner_sec"
        style={{
          backgroundImage: `url(${GET_USER_PROFILE?.backgroundImage})`,
        }}
      ></section>

      <section className="manage_address">
        <Container>
          <div className="sidebar_outer">
            <div className="sidebar_main">
              <Sidebar />
            </div>

            <div className="dashboard_main outline_wrapper p-0">
              <div className="order_tracking_info_list mb-2 ml-9">
                <div className="tracking_head m-0">
                  <h2>
                    {" "}
                    <span className="d-inline-block">
                      <i className="far fa-heart"></i>
                    </span>{" "}
                    {t("LIKES")}
                  </h2>
                </div>
              </div>
              <div
                style={{
                  padding: "24px 30px 0 30px",
                  height: "1354px",
                  overflow: "auto",
                }}
              >
                <CustomTabs
                  tabArr={customTab}
                  defaultActiveKey={activeDefaultTab}
                  onSelect={onSelectTab}
                  content={
                    <>
                      {GET_FAVORITE__RECOMMENDED_LOADER ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <LottiLoader loader={true} height={100} width={100} />
                        </div>
                      ) : (
                        <div className="trans_history_money sale_history_sec my-favourite">
                          <ul className="money_add">
                            {GET_FAVORITE__RECOMMENDED_LIST &&
                              GET_FAVORITE__RECOMMENDED_LIST?.length !== 0 &&
                              GET_FAVORITE__RECOMMENDED_LIST?.map((item) => {
                                return (
                                  <li key={item?._id}>
                                    <div className="manage_post_ul">
                                      <div className="trans_date">
                                        <div className="trans_date_list shadow-none border-0">
                                          <figure className="border-0">
                                            <img src={item?.image} alt="" />
                                            {item.favorite ? (
                                              <small
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  habdelFavoratePost(
                                                    item?._id,
                                                    GET_USER_PROFILE?._id
                                                  )
                                                }
                                              >
                                                <i className="fas fa-heart favourite"></i>
                                              </small>
                                            ) : (
                                              <small
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  habdelFavoratePost(
                                                    item?._id,
                                                    GET_USER_PROFILE?._id
                                                  )
                                                }
                                              >
                                                <i className="fas fa-heart "></i>
                                              </small>
                                            )}
                                          </figure>
                                        </div>

                                        <div className="trans_title">
                                          <h3>{item?.title}</h3>
                                          <span>
                                            {item?.shipping && (
                                              <img
                                                src={saleLogo}
                                                alt="NO_IMAGE"
                                              />
                                            )}{" "}
                                            {TextFormate(item?.price, 2)}{" "}
                                            {t("SR")}{" "}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="likes_btn">
                                        {item?.buyNow && (
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              alert("Coming soon!")
                                            }
                                            className="bg-warning theme_btn"
                                          >
                                            {t("BUY_NOW")}
                                          </Link>
                                        )}
                                        {item.offer && (
                                          <Link
                                            to="#"
                                            onClick={() =>
                                              alert("Coming soon!")
                                            }
                                            className="onroute theme_btn"
                                          >
                                            {t("MAKE_OFFER")}
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                      )}
                    </>
                  }
                />
              </div>
              <>
                {!GET_FAVORITE__RECOMMENDED_LOADER &&
                  GET_FAVORITE__RECOMMENDED_LIST?.length !== 0 && (
                    <>
                      <Divider />
                      <div
                        className="d-flex justify-content-between "
                        style={{ padding: "20px 24px 13px" }}
                      >
                        <p>
                          <strong>{"Total Item"}</strong> :{" "}
                          <span>
                            {GET_FAVORITE__RECOMMENDED_PAGINATION?.total}
                          </span>
                        </p>
                        <Pagination
                          page={page}
                          count={
                            GET_FAVORITE__RECOMMENDED_PAGINATION?.total_pages
                          }
                          color="primary"
                          variant="outlined"
                          onChange={(e, value) => pagination(e, value)}
                        />
                      </div>
                    </>
                  )}
              </>
            </div>
          </div>
        </Container>
      </section>

      {/* order_confirm */}

      {/* <Modal show={show2} onHide={handleClose2} className="success_modal">
        <button className="crose_btn" onClick={handleClose2}>
          <img src="./assets/images/crose.png" />
        </button>
        <Modal.Body>
          <div className="success_popup">
            <figure className="border-0">
              <img src="./assets/images/congratulations.png" />
            </figure>
            <h3>Congratulations</h3>
            <small>Your Order Confirmed</small>
            <button className="theme_btn">Track Order</button>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
}

export default Like;
