import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function paymentDataReducer(
  state = initialState.paymentData,
  action
) {
  switch (action.type) {
    case types.PAYMENT_DATA_REDUCER:
      return action.paymentData;
    default:
      return state;
  }
}
