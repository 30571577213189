import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function socialParamsPopup(
  state = initialState.socialParamsPopup,
  action
) {
  switch (action.type) {
    case types.LOADED_SOCIAL_PARAMS_POPUP:
      return action.socialParamsPopup;
    default:
      return state;
  }
}
