import Button from "@restart/ui/esm/Button";
import React, { useEffect, useState, lazy } from "react";
import { Row, Col, Container, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { next } from "../../assets/images/index";
import { checkProductOffer } from "../../actions/homePageActions";
import { geProductDetail } from "../../actions/HomeAction";
const ReviewOffer = lazy(() => import("./ReviewOffer"));

const MakeOffer = () => {
  const { postId, roomId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { productDetails, userInfo } = useSelector((state) => ({
    productDetails: state.homeReducer.homeProductDetails,
    userInfo: state.userInfo,
  }));

  const [offerData, setOfferData] = useState({});
  const [offerMethod, setOfferMethod] = useState("");
  const [offerKey, setOfferKey] = useState(0);
  const [offerAmount, setOfferAmount] = useState(0);
  const [customAmount, setCustomAmount] = useState(null);

  const checkOfferData = (data) => {
    setOfferData(data);
    if (data.offer.length > 0) {
      setOfferAmount(data.offer[0].value);
    }
    if (data.meetUp) {
      setOfferMethod("meetUp");
    } else {
      setOfferMethod("shipping");
    }
  };

  useEffect(() => {
    dispatch(checkProductOffer({ post_id: postId }, checkOfferData));
    dispatch(geProductDetail({ id: postId }));
  }, [dispatch, postId]);

  const nextOfferReview = () => {
    setShow(true);
  };

  return (
    <>
      <section className="make_offer_section">
        <Container>
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/">
                  {t("HOME")}
                  <i className="fas fa-chevron-right"></i>
                </Link>
              </li>

              {productDetails && (
                <li>
                  <Link to={`product/${postId}`}>
                    {productDetails.title}
                    <i className="fas fa-chevron-right"></i>
                  </Link>
                </li>
              )}
              <li>
                <span>{t("MAKE_OFFER")}</span>
              </li>
            </ul>
          </div>

          {offerData.offer && (
            <div className="outline_wrapper">
              <h2>{t("MAKE_OFFER")}</h2>
              <div className="product_item_horizantal">
                <div className="d-flex">
                  <figure>
                    <Link to={`product/${postId}`}>
                      {productDetails.imageArray &&
                        productDetails?.imageArray.length > 0 && (
                          <img
                            width={100}
                            src={productDetails?.imageArray?.[0]}
                            alt="post_item"
                          />
                        )}
                    </Link>
                  </figure>
                  <div className="offer_product_detail">
                    <h3>{productDetails?.title}</h3>
                    <small>{t("ITEM_PRICE")}</small>
                    <span className="price">{offerData.price} SR</span>
                  </div>
                </div>
              </div>

              <div className="wrapper_form">
                <Row>
                  <Col lg={8}>
                    <div className="best_offers_detail">
                      <div className="matherd_select">
                        <h4>{t("SELECT_METHOD")}:</h4>
                        {offerData.meetUp && (
                          <label className="radio_btn">
                            <input
                              type="radio"
                              name="offer_method"
                              value="meetUp"
                              checked={
                                offerMethod === "meetUp" ? "checked" : ""
                              }
                              onChange={() => setOfferMethod("meetUp")}
                            />
                            <span className="custome_radio"></span>
                            <span>{t("MEET_UP")}</span>
                          </label>
                        )}
                        {offerData.shipping && (
                          <label className="radio_btn">
                            <input
                              type="radio"
                              name="offer_method"
                              value="shipping"
                              checked={
                                offerMethod === "shipping" ? "checked" : ""
                              }
                              onChange={() => setOfferMethod("shipping")}
                            />
                            <span className="custome_radio"></span>
                            <span>{t("SHIPPING")}</span>
                          </label>
                        )}
                      </div>

                      <Form className="make_offer_form">
                        <h5>{t("SELECT_OFFER")}</h5>
                        <ul>
                          {offerData.offer &&
                            offerData.offer.length > 0 &&
                            offerData.offer.map((offer, key) => (
                              <li key={key}>
                                <label className="checkbox_tab bluetab">
                                  <input
                                    type="radio"
                                    name="best_offer"
                                    value={key}
                                    checked={offerKey === key ? "checked" : ""}
                                    onChange={() => {
                                      setOfferAmount(offer.value);
                                      setCustomAmount(0);
                                      setOfferKey(key);
                                    }}
                                  />
                                  <span className="checkbox_design"></span>
                                  <span>{offer.value} SR</span>
                                  <small>{offer.title} off</small>
                                </label>
                              </li>
                            ))}

                          <li className="custome_price">
                            <Form.Group className="custome_price">
                              <Form.Label>{t("CUSTOM_PRICE")}</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Enter your price"
                                value={customAmount === 0 ? "" : customAmount}
                                min={1}
                                onChange={(e) => {
                                  setOfferAmount(e.target.value);
                                  setCustomAmount(e.target.value);
                                  if (e.target.value) {
                                    setOfferAmount(e.target.value);
                                  } else {
                                    if (offerData !== null) {
                                      if (offerData.offer.length) {
                                        setOfferAmount(
                                          offerData.offer[offerKey].value
                                        );
                                      }
                                    }
                                  }
                                }}
                              />
                            </Form.Group>
                          </li>
                        </ul>

                        <div className="genrating_price">
                          <strong>
                            {offerAmount} SR
                            <small> {t("OFFER_TIME")}</small>
                          </strong>
                        </div>
                        <Button
                          className="theme_btn next"
                          onClick={nextOfferReview}
                        >
                          {t("NEXT")}
                        </Button>
                      </Form>
                    </div>
                  </Col>

                  <Col lg={4}>
                    <figure>
                      <img src={next} alt="offer_image" />
                    </figure>
                  </Col>
                </Row>
              </div>
            </div>
          )}
        </Container>
      </section>
      {show && (
        <ReviewOffer
          userId={userInfo._id}
          roomIds={roomId}
          item={productDetails}
          open={show}
          setOpen={setShow}
          amount={offerAmount}
          offerType={offerMethod}
        />
      )}
    </>
  );
};
export default MakeOffer;
