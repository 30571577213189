import { Fragment, React, useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Dropdown,
  Modal,
  Figure,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { addRemoveFavorite, publicProfile } from "../../actions/SIdebarAction";
import LottiLoader from "../../Component/lottiAnimation/LottiLoader";
import Rating from "../../Component/StarRating";
import ReactStartRating from "../../Component/StarRating/reactStartRating";
import { useTranslation } from "react-i18next";
import Sidebar from "./Sidebar";
import HeartImg from "../../assets/images/heart.svg";
import HeartFillImg from "../../assets/images/heart_fill.svg";
import saleImg from "../../assets/images/brnd.svg";
import { TextFormate } from "../../Component/TextFormate/TexFormate";

function PublicProfile() {
  const activepostslider = {
    dots: false,
    nav: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // All Selectors
  const { GET_USER_PROFILE, GET_PUBLIC_PROFILE_DATA, PUBLIC_PROFILE_LOADER } =
    useSelector((state) => ({
      GET_USER_PROFILE: state?.sidebarReducer?.userProfileData,
      GET_PUBLIC_PROFILE_DATA: state?.sidebarReducer?.publicProfileData,
      PUBLIC_PROFILE_LOADER: state?.sidebarReducer?.publicProfileLoading,
    }));

  useEffect(() => {
    const fetchPublicProfile = () => {
      dispatch(publicProfile(GET_USER_PROFILE?._id));
    };
    if (GET_USER_PROFILE?._id) {
      fetchPublicProfile();
    }
  }, [dispatch, GET_USER_PROFILE]);

  const habdelFavoratePost = (id, userID) => {
    const data = {
      post_id: id,
    };

    dispatch(addRemoveFavorite(data, userID));
  };

  return (
    <>
      <section
        className="middle_banner_sec"
        style={{
          backgroundImage: `url(${GET_USER_PROFILE?.backgroundImage})`,
        }}
      ></section>

      <section className="manage_address">
        <Container>
          <div className="sidebar_outer">
            <div className="sidebar_main">
              <Sidebar />
            </div>

            <div className="dashboard_main">
              <div className="outline_wrapper">
                <h2>{t("PUBLIC_PROFILE")}</h2>
                {PUBLIC_PROFILE_LOADER ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <LottiLoader loader={true} height={100} width={100} />
                  </div>
                ) : (
                  <Fragment>
                    <div className="profile_heading">
                      <figure>
                        <img
                          src={GET_PUBLIC_PROFILE_DATA?.image}
                          alt="NO_IMAGE"
                        />
                      </figure>
                      <div className="rating_star text-center">
                        <h2>{GET_PUBLIC_PROFILE_DATA?.name}</h2>
                        <p>{t("DOFFO_MEMBERS")}</p>
                        <div className="user_rating">
                          <ReactStartRating
                            rating={GET_PUBLIC_PROFILE_DATA?.rating}
                          />
                          <small>
                            ({GET_PUBLIC_PROFILE_DATA?.totalRating}{" "}
                            {t("RATINGS")})
                          </small>
                        </div>
                      </div>

                      <div className="profile_item_rate">
                        <ul>
                          <li>
                            <h4>{GET_PUBLIC_PROFILE_DATA?.sold}</h4>
                            <span>{t("SOLD_ITEMS")}</span>
                          </li>
                          <li>
                            <h4>{GET_PUBLIC_PROFILE_DATA?.bought}</h4>
                            <span>{t("BOUGHT_ITEMS")}</span>
                          </li>
                          <li>
                            <h4>{GET_PUBLIC_PROFILE_DATA?.totalBuyer}</h4>
                            <span>{t("TOTAL_BUYER")}</span>
                          </li>
                          <li>
                            {GET_PUBLIC_PROFILE_DATA?.totalSeller && (
                              <h4>
                                {parseInt(GET_PUBLIC_PROFILE_DATA?.totalSeller)}
                              </h4>
                            )}
                            <span>{t("TOTAL_SELLER")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div
                      className="badges_sec"
                      style={{ borderBottom: "none" }}
                    >
                      <h2 className="profile_inner_heading">
                        {t("MY_BADGES")}
                      </h2>
                      <ul>
                        {GET_PUBLIC_PROFILE_DATA.mobile && (
                          <li>
                            <figure>
                              <img src="./assets/images/badge1.png" alt="" />
                            </figure>
                            <span>{t("PHONE_VERIFIED")}</span>
                          </li>
                        )}
                        {GET_PUBLIC_PROFILE_DATA?.doffo && (
                          <li>
                            <figure>
                              <img src="./assets/images/badge2.png" alt="" />
                            </figure>
                            <span>{t("DOFFO_VERIFIED")}</span>
                          </li>
                        )}
                        {GET_PUBLIC_PROFILE_DATA?.email && (
                          <li>
                            <figure>
                              <img src="./assets/images/badge3.png" alt="" />
                            </figure>
                            <span>{t("EMAIL_VERIFIED")}</span>
                          </li>
                        )}
                        {GET_PUBLIC_PROFILE_DATA.facebook && (
                          <li>
                            <figure>
                              <img src="./assets/images/badge4.png" alt="" />
                            </figure>
                            <span>{t("FACEBOOK_VERIFIED")}</span>
                          </li>
                        )}
                      </ul>
                    </div>

                    {/* <div className="profile_feedback">
                  <h2 className="profile_inner_heading text-center">
                    Feedback
                  </h2>
                  <ul>
                    <li>
                      <span>14</span> Communicative
                    </li>
                    <li>
                      <span>54</span> Reliable
                    </li>
                    <li>
                      <span>74</span> Friendly
                    </li>
                    <li>
                      <span>4</span> On Time
                    </li>
                  </ul>
                </div> */}

                    <div className="active_post_slider product_outer_main">
                      <h2>{t("ACTIVE_POSTS")}</h2>
                      <Slider {...activepostslider}>
                        {GET_PUBLIC_PROFILE_DATA.activePost &&
                          GET_PUBLIC_PROFILE_DATA.activePost.length !== 0 &&
                          GET_PUBLIC_PROFILE_DATA.activePost.map((item) => {
                            return (
                              <div className="product_card" key={item._id}>
                                <figure onClick={() => alert("FULL CARD")}>
                                  <img src={item?.image} alt="No_IMG" />
                                </figure>
                                <div className="overlay_top">
                                  {item?.boost && (
                                    <span className="boost_badge">
                                      {t("DOFFO_BOOST")}
                                    </span>
                                  )}
                                  {item?.sold && (
                                    <span className="sold_badge">
                                      {t("SOLD")}
                                    </span>
                                  )}
                                  {/* <span className="add_badge">Ad</span> */}
                                  <span
                                    className="heart"
                                    onClick={() =>
                                      habdelFavoratePost(
                                        item?._id,
                                        GET_USER_PROFILE?._id
                                      )
                                    }
                                  >
                                    {!item?.favorite ? (
                                      <img
                                        // src="./assets/images/heart.png"
                                        src={HeartImg}
                                        alt="NO_IMG"
                                      />
                                    ) : (
                                      <img
                                        // src="./assets/images/heart_fill.png"
                                        src={HeartFillImg}
                                        alt="NO_IMG"
                                      />
                                    )}
                                  </span>
                                </div>
                                <div
                                  className="overlay_bottom"
                                  onClick={() => alert("coming soon!")}
                                >
                                  <h4>
                                    <Link to="#">{item?.title}</Link>
                                  </h4>
                                  <div className="product_info">
                                    <span className="price">
                                      {TextFormate(item?.price, 2)} {t("SR")}
                                    </span>
                                    {item.shipping && (
                                      <span className="brand">
                                        <img
                                          // src="./assets/images/brnd.png"
                                          src={saleImg}
                                          alt="NO_IMG"
                                        />
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </Slider>
                    </div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
}

export default PublicProfile;
