import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import markerImage from "../../assets/images/location_pin_small_new.svg";
import SearchIcon from "@mui/icons-material/Search";
const GoogleMapsAPIKey = "AIzaSyAv6leIF_HsmoOdtnDjQBIDIriyHhcA82U";

Geocode.setApiKey(GoogleMapsAPIKey);
Geocode.enableDebug();

class CommonMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      position: {
        lat: "",
        lng: "",
      },
      zoom: this.props.zoom,
    };
    // this.mapRef = React.createRef(null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.center.lat !== this.props.center.lat) {
      this.setState({
        mapPosition: {
          lat: this.props.center.lat,
          lng: this.props.center.lng,
        },
        markerPosition: {
          lat: this.props.center.lat,
          lng: this.props.center.lng,
        },
      });
    }
  }

  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.markerPosition.lat !== this.props.center.lat ||
      this.state.mapPosition.lat !== this.props.center.lat ||
      this.state.markerPosition.lat !== nextState.markerPosition.lat ||
      this.props.center.lat !== nextProps.center.lat
    ) {
      return true;
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false;
    }
  }

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        console.log("response:", response);
        const full_Address = response.results[0].formatted_address;
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          postal_code = this.getPostalCode(addressArray),
          building_no = this.getBuildingNo(addressArray),
          district_addr = this.getDistrictOnScroll(addressArray);

        this.props.sendAddress({
          address,
          city,
          postal_code,
          building_no,
          district_addr,
        });
        this.props.sendLatLng(newLat, newLng, addressArray, full_Address);
        this.setState({
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          mapPosition: {
            lat: newLat,
            lng: newLng,
          },
        });

        // console.log("address:", address);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place) => {
    console.log("PLACE---->", place);

    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      postal_code = this.getPostalCode(addressArray),
      building_no = this.getBuildingNo(addressArray),
      district_addr = this.getDistrict(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();

    this.props.sendAddress({
      address,
      city,
      postal_code,
      building_no,
      district_addr,
    });
    this.props.sendLatLng(
      latValue,
      lngValue,
      addressArray,
      place?.formatted_address
    );

    this.setState({
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
    });
  };

  /**
   * Get the postal code and set the postal code input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getPostalCode = (addressArray) => {
    let postalCode = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "postal_code" === addressArray[i].types[0]
      ) {
        postalCode = addressArray[i].long_name;
        return postalCode;
      }
    }
  };

  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };

  getBuildingNo = (addressArray) => {
    let building = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "street_number" === addressArray[i].types[0]
      ) {
        building = addressArray[i].long_name;
        return building;
      } else if (
        addressArray[i].types[0] &&
        "premise" === addressArray[i].types[0]
      ) {
        building = addressArray[i].long_name;
        return building;
      }
    }
  };

  getDistrict = (addressArray) => {
    let district = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "sublocality_level_1" === addressArray[i].types[0]
      ) {
        district = addressArray[i].long_name;
        return district;
      }
    }
  };

  getDistrictOnScroll = (addressArray) => {
    let district = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[2] &&
        "sublocality_level_1" === addressArray[i].types[2]
      ) {
        district = addressArray[i].long_name;
        return district;
      }
    }
  };

  // handleLoad = (map) => {
  //   this.mapRef.current = map;
  // };

  // handleCenter() {
  //   if (!this.mapRef.current) return;

  //   const newPos = this.mapRef.current.getCenter().toJSON();
  //   this.setState({ position: newPos });
  // }

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <div className="position-absolute top-0  ms-1 mt-1 w-50">
          <GoogleMap
            google={this.props.google}
            defaultZoom={this.props.zoom}
            defaultCenter={{
              lat: this.state.mapPosition.lat,
              lng: this.state.mapPosition.lng,
            }}
            onZoomChanged={this.onZoomChanged}
            options={
              this.props.options
                ? this.props.options
                : { disableDefaultUI: true, fullscreenControl: true }
            }
            onMapMounted={this.onMapMounted}
            // onLoad={this.handleLoad}
            // onDragEnd={this.handleCenter}
          >
            {/*Marker*/}
            <Marker
              google={this.props.google}
              name={"Dolores park"}
              draggable={true}
              onDragEnd={this.onMarkerDragEnd}
              position={{
                lat: this.state.markerPosition.lat,
                lng: this.state.markerPosition.lng,
              }}
              icon={{
                url: markerImage,
                style: { height: "10px", width: "10px" },
              }}
            />
            <Marker />
            {/* For Auto complete Search Box */}
            <div style={{ position: "relative" }}>
              <Autocomplete
                style={{
                  width: "100%",
                  height: "40px",
                  paddingLeft: "30px",
                  border: "solid 1px lightGray",
                  outline: 0,
                  paddingRight: "10px",
                }}
                onPlaceSelected={this.onPlaceSelected}
                placeholder="Search"
                // types={["(regions)"]}
                options={{ types: [] }}
              />
              <SearchIcon
                style={{
                  position: "absolute",
                  top: "10px",
                  left: "6px",
                  fontSize: "20px",
                  color: "Gray",
                }}
              />
            </div>
          </GoogleMap>
        </div>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          <AsyncMap
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${GoogleMapsAPIKey}&libraries=places`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: this.props.height }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return map;
  }
}
export default CommonMap;
