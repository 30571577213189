import submittingRequestStatus from "./submittingRequestStatusAction";
import * as API from "../utils/apiPath";
import { agent } from "../utils/agent";
import toasterStatusAction from "./toasterStatusAction";
import { loadToasterData } from "./baseActions";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";

const USER_PROFILE_DATA = (data) => {
  return {
    type: "USER_PROFILE_DATA",
    payload: data,
  };
};

const SIDEBAR_LOADER = (data) => {
  return {
    type: "SIDEBAR_LOADER",
    payload: data,
  };
};

const ABOUT_DOFFO = (data) => {
  return {
    type: "ABOUT_DOFFO",
    payload: data,
  };
};
const ABOUT_DOFFO_LOADING = (data) => {
  return {
    type: "ABOUT_DOFFO_LOADING",
    payload: data,
  };
};
const DOFFO_VERIFY_LOADING = (data) => {
  return {
    type: "DOFFO_VERIFY_LOADING",
    payload: data,
  };
};

//Public Profile

const PUBLIC_PROFILE_DATA = (data) => {
  return {
    type: "PUBLIC_PROFILE_DATA",
    payload: data,
  };
};

const PUBLIC_PROFILE_LOADING = (data) => {
  return {
    type: "PUBLIC_PROFILE_LOADING",
    payload: data,
  };
};

const CHANGE_IMAGE_LOADER = (data) => {
  return {
    type: "CHANGE_IMAGE_LOADER",
    payload: data,
  };
};
const CHANGE_BANNER_IMAGE_LOADER = (data) => {
  return {
    type: "CHANGE_BANNER_IMAGE_LOADER",
    payload: data,
  };
};

// Manage Archive Component

const ARCHIVE_LIST = (data) => {
  return {
    type: "ARCHIVE_LIST",
    payload: data,
  };
};

const ARCHIVE_LIST_PAGINATION = (data) => {
  return {
    type: "ARCHIVE_LIST_PAGINATION",
    payload: data,
  };
};

const ARCHIVE_LIST_LOADING = (data) => {
  return {
    type: "ARCHIVE_LIST_LOADING",
    payload: data,
  };
};

// Email Notification

const IS_EMAIL_CHECK = (data) => {
  return {
    type: "IS_EMAIL_CHECK",
    payload: data,
  };
};

// Push Notification

const IS_PUSH_CHECK = (data) => {
  return {
    type: "IS_PUSH_CHECK",
    payload: data,
  };
};

//***************Purchase and Sale History********************/
const BUYING_AND_SELLING_LIST = (data) => {
  return {
    type: "BUYING_AND_SELLING_LIST",
    payload: data,
  };
};

const BUYING_AND_SELLING_PAGINATION = (data) => {
  return {
    type: "BUYING_AND_SELLING_PAGINATION",
    payload: data,
  };
};

const BUYING_AND_SELLING_LOADING = (data) => {
  return {
    type: "BUYING_AND_SELLING_LOADING",
    payload: data,
  };
};

const ORDER_TRACKING_LIST = (data) => {
  return {
    type: "ORDER_TRACKING_LIST",
    payload: data,
  };
};

//******************LIKES************************/
const FAVORITE_AND_RECOMMENDED_LIST = (data) => {
  return {
    type: "FAVORITE_AND_RECOMMENDED_LIST",
    payload: data,
  };
};

const FAVORITE_AND_RECOMMENDED_PAGINATION = (data) => {
  return {
    type: "FAVORITE_AND_RECOMMENDED_PAGINATION",
    payload: data,
  };
};

const FAVORITE_AND_RECOMMENDED_LOADING = (data) => {
  return {
    type: "FAVORITE_AND_RECOMMENDED_LOADING",
    payload: data,
  };
};

//******************NOTIFICATIONS************************/
const NOTIFICATION_DATA = (data) => {
  return {
    type: "NOTIFICATION_DATA",
    payload: data,
  };
};
const NOTIFICATION_LOADER = (data) => {
  return {
    type: "NOTIFICATION_LOADER",
    payload: data,
  };
};

export {
  USER_PROFILE_DATA,
  SIDEBAR_LOADER,
  ABOUT_DOFFO,
  ABOUT_DOFFO_LOADING,
  DOFFO_VERIFY_LOADING,
  PUBLIC_PROFILE_LOADING,
  CHANGE_IMAGE_LOADER,
  CHANGE_BANNER_IMAGE_LOADER,
  ARCHIVE_LIST,
  ARCHIVE_LIST_PAGINATION,
  ARCHIVE_LIST_LOADING,
  IS_EMAIL_CHECK,
  IS_PUSH_CHECK,
  BUYING_AND_SELLING_LIST,
  BUYING_AND_SELLING_PAGINATION,
  BUYING_AND_SELLING_LOADING,
  ORDER_TRACKING_LIST,
  FAVORITE_AND_RECOMMENDED_LIST,
  FAVORITE_AND_RECOMMENDED_PAGINATION,
  FAVORITE_AND_RECOMMENDED_LOADING,
  NOTIFICATION_DATA,
  NOTIFICATION_LOADER,
};

// Api Functions

//Account Info Component

// get User Profile
export const fetchUserProfile = () => async (dispatch) => {
  try {
    dispatch(SIDEBAR_LOADER(true));
    const response = await agent.get(API.GET_USER_PROFILE);

    if (response.status === 200) {
      dispatch(USER_PROFILE_DATA(response?.data?.data));
      dispatch(SIDEBAR_LOADER(false));
    }
  } catch (error) {
    dispatch(SIDEBAR_LOADER(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

//Update User Profile
export const updateUserProfile = (data, resetAllFields) => async (dispatch) => {
  try {
    dispatch(SIDEBAR_LOADER(false));
    const response = await agent.put(API.UPDATE_USER_PROFILE, data);
    if (response.status === 200) {
      dispatch(
        toasterStatusAction({ open: true, message: response.data.message })
      );
      resetAllFields();
      dispatch(fetchUserProfile());
      dispatch(SIDEBAR_LOADER(false));
    }
    if (response.status === 201) {
      resetAllFields();
      dispatch(
        toasterStatusAction({
          open: true,
          message: response.data.message,
          severity: "error",
        })
      );
      dispatch(SIDEBAR_LOADER(false));
    }
  } catch (error) {
    dispatch(SIDEBAR_LOADER(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

//Change Password
export const changePassword = (data, closeModal) => async (dispatch) => {
  try {
    // dispatch(LOADING_STATUS(true));
    const response = await agent.put(API.CHANGE_PASSWORD, data);
    if (response.status === 200) {
      console.log("RESPONSE OF UPDATE PROFILE", response.data);
      dispatch(
        toasterStatusAction({ open: true, message: response.data.message })
      );
      closeModal();
      dispatch(fetchUserProfile());
      //   dispatch(LOADING_STATUS(false));
    }
  } catch (error) {
    // dispatch(LOADING_STATUS(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

//fetch About DoffoInfo
export const aboutDoffo = (slug) => async (dispatch) => {
  try {
    dispatch(ABOUT_DOFFO_LOADING(true));
    const response = await agent.get(API.CMS_PAGE + slug);
    dispatch(ABOUT_DOFFO(response.data?.data?.translate?.description));
    dispatch(ABOUT_DOFFO_LOADING(false));
  } catch (error) {
    dispatch(ABOUT_DOFFO_LOADING(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

//Doffo Verify API
export function doffoVerify(params, closeModal) {
  return async (dispatch) => {
    dispatch(DOFFO_VERIFY_LOADING(true));
    await agent
      .post(API.DOFFO_VERIFY, params)
      .then((response) => {
        dispatch(DOFFO_VERIFY_LOADING(false));
        if (response.status === 200) {
          dispatch(
            toasterStatusAction({ open: true, message: response.data.message })
          );
          dispatch(fetchUserProfile());
          closeModal();
        }
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
        dispatch(DOFFO_VERIFY_LOADING(false));
      });
  };
}

//Public Profile Component

//fetch Public Profile
export const publicProfile = (id, msj) => async (dispatch) => {
  try {
    msj !== "favorite" && dispatch(PUBLIC_PROFILE_LOADING(true));
    const response = await agent.get(`${API.PUBLIC_PROFILE}?id=${id}`);
    dispatch(PUBLIC_PROFILE_DATA(response.data.data));
    dispatch(PUBLIC_PROFILE_LOADING(false));
  } catch (error) {
    dispatch(PUBLIC_PROFILE_LOADING(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

// handel Add/Remove Favorite
export function addRemoveFavorite(data, userID, text) {
  return async (dispatch) => {
    await agent
      .post(API.FAVORITE_ADD_REMOVE, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            toasterStatusAction({ open: true, message: response.data.message })
          );

          if (text == "likes") {
            dispatch(getFavorite(text));
          } else if (text == "recommended") {
            dispatch(getRecommend(text));
          } else {
            dispatch(publicProfile(userID, "favorite"));
          }
        }
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
      });
  };
}

// handel User Image Change
export function updateUseImage(formData, type) {
  return async (dispatch) => {
    type == "BACKGROUND"
      ? dispatch(CHANGE_BANNER_IMAGE_LOADER(true))
      : dispatch(CHANGE_IMAGE_LOADER(true));
    await agent
      .post(API.UPDATE_USER_IMAGE, formData)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            toasterStatusAction({ open: true, message: response.data.message })
          );
          dispatch(CHANGE_IMAGE_LOADER(false));
          dispatch(CHANGE_BANNER_IMAGE_LOADER(false));
          dispatch(fetchUserProfile());
        }
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
        dispatch(CHANGE_IMAGE_LOADER(false));
        dispatch(CHANGE_BANNER_IMAGE_LOADER(false));
      });
  };
}

// Archive Component

//fetch Archive Data
export const getArchiveList = () => async (dispatch) => {
  try {
    dispatch(ARCHIVE_LIST_LOADING(true));
    const response = await agent.get(API.GET_ARCHIVE);
    if (response.status === 200) {
      // console.log("RESPONSE OF ARCHIVE LIST---->", response.data.data);
      dispatch(ARCHIVE_LIST(response?.data?.data?.data));
      dispatch(ARCHIVE_LIST_PAGINATION(response?.data?.data?.pagination));
      dispatch(ARCHIVE_LIST_LOADING(false));
    }
  } catch (error) {
    dispatch(ARCHIVE_LIST_LOADING(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

// Delete im Archive post
export const deletePost = (id) => async (dispatch) => {
  try {
    // dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.delete(API.DELETE_POST, {
      data: {
        id: id,
      },
    });
    if (response.status === 200) {
      dispatch(getArchiveList());
      // dispatch(toggleNetworkRequestStatus(false));
    }
  } catch (error) {
    // dispatch(toggleNetworkRequestStatus(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

// Email Notification
export function updateEmailNOtification(data) {
  return async (dispatch) => {
    await agent
      .post(API.UPDATE_EMAIL_NOTIFICATION, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            toasterStatusAction({ open: true, message: response.data.message })
          );
          dispatch(IS_EMAIL_CHECK(response.data.data.email_notification));
        }
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
      });
  };
}

// Email Notification
export function updatePushNotification(data) {
  return async (dispatch) => {
    await agent
      .post(API.UPDATE_PUSH_NOTIFICATION, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(
            toasterStatusAction({ open: true, message: response.data.message })
          );
          console.log("RESPONSE OF PUSH NOTIFICATION", response.data.data);
          dispatch(IS_PUSH_CHECK(response.data.data.notification));
        }
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
      });
  };
}

export function postReportItem(data, toggleLoadMessage) {
  return async (dispatch) => {
    dispatch(toggleNetworkRequestStatus(true));
    await agent
      .post(API.RETURN_REQUEST, data)
      .then((response) => {
        dispatch(toggleNetworkRequestStatus(false));
        toggleLoadMessage();
        if (response.status === 200) {
          dispatch(
            toasterStatusAction({ open: true, message: response.data.message })
          );
        }
      })
      .catch((error) => {
        dispatch(toggleNetworkRequestStatus(false));
        dispatch(loadToasterData(error.message, "error", true));
      });
  };
}

//***************Purchase and Sale History********************/

// fetch Selling History
export const getSellingHistory = () => async (dispatch) => {
  try {
    dispatch(BUYING_AND_SELLING_LOADING(true));
    const response = await agent.get(API.GET_SELLING_HISTORY);
    if (response.status === 200) {
      // console.log("RESPONSE OF SELLING HISTORY ---->", response.data.data);
      dispatch(BUYING_AND_SELLING_LIST(response?.data?.data?.data));
      dispatch(BUYING_AND_SELLING_PAGINATION(response?.data?.data?.pagination));
      dispatch(BUYING_AND_SELLING_LOADING(false));
    }
  } catch (error) {
    dispatch(BUYING_AND_SELLING_LOADING(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

// fetch Buying History
export const getBuyingHistory = () => async (dispatch) => {
  try {
    dispatch(BUYING_AND_SELLING_LOADING(true));
    const response = await agent.get(API.GET_BUYING_HISTORY);
    if (response.status === 200) {
      // console.log("RESPONSE OF BUYING HISTORY ---->", response.data.data.data);
      dispatch(BUYING_AND_SELLING_LIST(response?.data?.data?.data));
      dispatch(BUYING_AND_SELLING_PAGINATION(response?.data?.data?.pagination));
      dispatch(BUYING_AND_SELLING_LOADING(false));
    }
  } catch (error) {
    dispatch(BUYING_AND_SELLING_LOADING(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

// ****************Order Tracking***********************
// fetch order Tracking List
// Email Notification
export function getOrderDetail(data) {
  return async (dispatch) => {
    await agent
      .post(API.GET_ORDER_DETAIL, data)
      .then((response) => {
        if (response.status === 200) {
          // console.log("RESPONSE OF PUSH NOTIFICATION", response.data.data);
          dispatch(ORDER_TRACKING_LIST(response?.data?.data));
        }
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
      });
  };
}

//******************LIKES************************/

// fetch favorite
export const getFavorite = (text) => async (dispatch) => {
  try {
    text == "likes"
      ? dispatch(FAVORITE_AND_RECOMMENDED_LOADING(false))
      : dispatch(FAVORITE_AND_RECOMMENDED_LOADING(true));
    const response = await agent.get(API.GET_FAVORITE_LIST);
    if (response.status === 200) {
      // console.log("RESPONSE OF FAVORITE ---->", response.data.data);
      dispatch(FAVORITE_AND_RECOMMENDED_LIST(response?.data?.data?.data));
      dispatch(
        FAVORITE_AND_RECOMMENDED_PAGINATION(response?.data?.data?.pagination)
      );
      dispatch(FAVORITE_AND_RECOMMENDED_LOADING(false));
    }
  } catch (error) {
    dispatch(FAVORITE_AND_RECOMMENDED_LOADING(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

// fetch recommend
export const getRecommend = (text) => async (dispatch) => {
  try {
    text == "recommended"
      ? dispatch(FAVORITE_AND_RECOMMENDED_LOADING(false))
      : dispatch(FAVORITE_AND_RECOMMENDED_LOADING(true));
    const response = await agent.get(API.GET_RECOMMENDED_LIST);
    if (response.status === 200) {
      // console.log("RESPONSE OF BUYING HISTORY ---->", response.data.data.data);
      dispatch(FAVORITE_AND_RECOMMENDED_LIST(response?.data?.data?.data));
      dispatch(
        FAVORITE_AND_RECOMMENDED_PAGINATION(response?.data?.data?.pagination)
      );
      dispatch(FAVORITE_AND_RECOMMENDED_LOADING(false));
    }
  } catch (error) {
    dispatch(FAVORITE_AND_RECOMMENDED_LOADING(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

/* Rating Feedback List */
export const ratingFeedbackList =
  (request, returnDataFeedback) => async (dispatch) => {
    try {
      // console.log(request.order_id);
      // const response = await agent.get(API.GET_FEEDBACK_LIST, request);
      const response = await agent.get(
        API.GET_FEEDBACK_LIST + "?orderId=" + request.order_id
      );
      dispatch(submittingRequestStatus(false));
      if (response.status === 200) {
        returnDataFeedback(response.data.data);
      }
    } catch (error) {
      dispatch(submittingRequestStatus(false));
      dispatch(loadToasterData(error.message, "error", true));
    }
  };
/* Rating Feedback List */
/* RatinG Submit */
export const ratingSubmitData = (request) => async (dispatch) => {
  try {
    dispatch(submittingRequestStatus(true));
    const response = await agent.post(API.FEEDBACK_SUBMIT, request);
    dispatch(submittingRequestStatus(false));
    if (response.status === 200) {
      dispatch(loadToasterData(response.data.message, "success", true));
    }
  } catch (error) {
    dispatch(submittingRequestStatus(false));
    dispatch(loadToasterData(error.message, "error", true));
  }
};
/* Rating Submit */

export const saveMeetUpDetails = (request) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const response = await agent.post(API.SUBMIT_MEETUP, request);
    dispatch(toggleNetworkRequestStatus(false));
    if (response.status === 200) {
      dispatch(loadToasterData(response.data.message, "success", true));
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(loadToasterData(error.message, "error", true));
  }
};

// get Notification
export const fetchNotificationData = () => async (dispatch) => {
  try {
    console.log("API CALLED");
    dispatch(NOTIFICATION_LOADER(true));
    const response = await agent.get(API.GET_NOTIFICATIONS_DATA);

    if (response.status === 200) {
      dispatch(NOTIFICATION_DATA(response?.data?.data?.item));
      dispatch(NOTIFICATION_LOADER(false));
    }
  } catch (error) {
    dispatch(NOTIFICATION_LOADER(false));
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};

// Delete im Notification
export const deleteNotification = (id) => async (dispatch) => {
  console.log("TEST");
  try {
    const response = await agent.delete(API.DELETE_NOTIFICATION, {
      data: {
        id: id,
      },
    });
    if (response.status === 200) {
      dispatch(loadToasterData(response?.data?.message, "success", true));
      dispatch(fetchNotificationData());
    }
  } catch (error) {
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};
export const deleteAllNotification = (id) => async (dispatch) => {
  try {
    const response = await agent.delete(API.DELETE_NOTIFICATION_ALL, {});
    if (response.status === 200) {
      dispatch(loadToasterData(response?.data?.message, "success", true));
      dispatch(fetchNotificationData());
    }
  } catch (error) {
    dispatch(
      toasterStatusAction({
        open: true,
        message: error.message,
        severity: "error",
      })
    );
  }
};
