import { Fragment, lazy, React, useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { subscriptionDetailsSave } from "../../../actions/HomeAction";
import { loadDialog, loadToasterData } from "../../../actions/baseActions";
const queryString = require("query-string");
function Confirm(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const queryStringParsed = queryString.parse(location.search);
  console.log("queryString", queryStringParsed);
  var boost_id = localStorage.getItem("boost_id");
  var post_ids = localStorage.getItem("post_id_subs");
  var amount = localStorage.getItem("subscription_amount");

  console.log("B", boost_id);
  console.log("P", post_ids);
  // All Selectors

  useEffect(() => {
    var post_id = post_ids;
    var boot_id = boost_id;
    var paymentData = queryStringParsed;
    const request = { post_id, boot_id, paymentData };
    if (queryStringParsed.Result === "Failure") {
      dispatch(loadToasterData(t("SUBSCRIPTION_FAILED"), "error", true));
      setTimeout(function () {
        history.push(`/manage`);
        //  window.href.location = "/manage";
      }, 100);
    } else {
      dispatch(subscriptionDetailsSave(request));
      dispatch(loadToasterData(t("SUBSCRIPTION_SUCCESS"), "success", true));
      setTimeout(function () {
        //  history.push(`/manage`);
        window.location.href = "/manage";
      }, 100);
    }
  }, [dispatch, queryStringParsed, post_ids, boost_id]);

  return (
    <div>
      <section className="section-b-space light-layout">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="success-text">
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                {queryStringParsed.Result === "Failure" ? (
                  <div>
                    <p>{t("SUBSCRIPTION_FAILED")}</p>
                    <p>Transaction ID:{queryStringParsed.TranId}</p>
                  </div>
                ) : (
                  <div>
                    <h2>thank you</h2>
                    <p>for subscription Payment</p>
                    <p>Transaction ID:{queryStringParsed.TranId}</p>
                  </div>
                )}

                {/* <p>Order ID:{getOrderID.order_id}</p>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Confirm;
