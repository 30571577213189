import { Fragment, lazy, React, useEffect, useState } from "react";
import { Row, Col, Container, Form, Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { purchaseDetailsSave } from "../../actions/HomeAction";
import { loadDialog, loadToasterData } from "../../actions/baseActions";
const queryString = require("query-string");
function Purchase(props) {
  var Transid = props.match.params.id;
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div>
      <section className="section-b-space light-layout thanku_page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="success-text">
                <i className="fa fa-check-circle" aria-hidden="true"></i>
                <h2>thank you</h2>
                <p>
                  Payment is successfully processsed and your order is on the
                  way
                </p>
                <p>Transaction ID:{Transid}</p>
                {/* <p>Order ID:{getOrderID.order_id}</p>*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section class="section-b-space">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="product-order">
                <h3>your order details</h3>
                <div class="total-sec">
                  <ul>
                    <li>
                      subtotal <span>$0</span>
                    </li>
                  </ul>
                </div>
                <div class="final-total">
                  <h3>
                    total <span>$0</span>
                  </h3>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="order-success-sec row">
                <div class="col-sm-6">
                  <h4>summery</h4>
                  <ul class="order-detail">
                    <li>order ID: 5563853658932</li>
                    <li>Order Date: October 22, 2021</li>
                    <li>Order Total: $907.28</li>
                  </ul>
                </div>
                <div class="col-sm-6">
                  <h4>shipping address</h4>
                  <ul class="order-detail">
                    <li>gerg harvell</li>
                    <li>568, suite ave.</li>
                    <li>Austrlia, 235153</li>
                    <li>Contact No. 987456321</li>
                  </ul>
                </div>
                <div class="payment-mode col-sm-12">
                  <h4>payment method</h4>
                  <p>
                    Pay on Delivery (Cash/Card). Cash on delivery (COD)
                    available. Card/Net banking acceptance subject to device
                    availability.
                  </p>
                </div>
                <div class="col-md-12">
                  <div class="delivery-sec">
                    <h3>expected date of delivery</h3>
                    <h2>october 22, 2021</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default Purchase;
