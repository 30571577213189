import React, { useEffect } from "react";
import { useState } from "react";
import "./ManagePostList.css";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Dropdown,
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  archivePost,
  deletePost,
  fetchPostList,
  getEditPost,
} from "../../actions/UserAction";
import {
  textTruncate,
  textTruncateMore,
} from "../../Component/TextTruncate/textTruncate";
import Sidebar from "../Sidebar/Sidebar";
import sold from "../../assets/images/sold.png";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import { useForm } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import { ErrorMessage } from "@hookform/error-message";
import Pagination from "@mui/material/Pagination";
import { Divider, OutlinedInput, Tooltip } from "@mui/material";
import LottiLoader from "../../Component/lottiAnimation/LottiLoader";
import ClearIcon from "@mui/icons-material/Clear";

function ManagePostList() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    values,
  } = useForm();

  // All State
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState("");

  useEffect(() => {
    const fetchData = () => {
      dispatch(fetchPostList());
    };
    fetchData();
  }, []);

  //  All Selectors

  const {
    GET_POST_DATA,
    POST_LIST_PAGINATION,
    LOADING,
    GET_USER_PROFILE,
    selectedLang,
  } = useSelector((state) => ({
    GET_POST_DATA: state?.userReducer?.postData,
    POST_LIST_PAGINATION: state?.userReducer?.postListPagination,
    LOADING: state?.userReducer?.loadingStatus,
    GET_USER_PROFILE: state?.sidebarReducer?.userProfileData,
    selectedLang: state.selectedLang,
  }));

  const fetchEditPostData = (id) => {
    dispatch(getEditPost(id, history));
  };

  const onSubmit = (data) => {
    const params = new URLSearchParams(location.search);
    params.set("keyword", data.title);
    history.push({
      pathname: "/manage-post-list",
      search: "?" + params,
    });
    dispatch(fetchPostList(page ? page : 1, data?.title));
  };

  const pagination = (e, value) => {
    // console.log("value sdasd", value);
    const params = new URLSearchParams(location.search);
    params.set("page", value);
    history.push({
      pathname: "/manage-post-list",
      search: "?" + params,
    });
    setPage(value);
    dispatch(fetchPostList(value));
  };

  const handelArchivePost = (id) => {
    const postData = { id: id };
    dispatch(archivePost(postData));
  };

  const resetPage = () => {
    dispatch(fetchPostList());
    setValue("title", "");
    setPage(1);
    setKeyword("");
    history.push(`/manage-post-list`);
  };
  const params = new URLSearchParams(location.search);

  return (
    <>
      <section
        className="middle_banner_sec"
        // className={
        //   selectedLang == "ar"
        //     ? "is-arabic middle_banner_sec"
        //     : "middle_banner_sec"
        // }
        style={{
          backgroundImage: `url(${GET_USER_PROFILE?.backgroundImage})`,
        }}
      ></section>

      <section
        // className="manage_address"
        className={
          selectedLang == "ar" ? "is-arabic manage_address" : "manage_address"
        }
      >
        <Container>
          <div className="sidebar_outer">
            <div className="sidebar_main">
              <Sidebar />
            </div>

            <div className="dashboard_main outline_wrapper  p-0">
              <div className="p-sm-3">
                <div className="mb-4 manage-post-flex">
                  <h2 style={{ marginBottom: "0px", marginTop: "15px" }}>
                    {t("MANAGE_POST_LIST")}
                  </h2>
                  {!LOADING && GET_POST_DATA.length !== 0 && (
                    <Form
                      className="post_form"
                      onSubmit={handleSubmit(onSubmit)}
                      noValidate
                      autoComplete="off"
                    >
                      <FormControl sx={{}} variant="outlined">
                        <InputLabel htmlFor="outlined-adornment-password">
                          {t("SEARCH_BY_TITLE")}
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type="text"
                          name="title"
                          {...register("title", {})}
                          onChange={(e) => setKeyword(e.target.value)}
                          inputProps={{ maxLength: 50 }}
                          endAdornment={
                            <InputAdornment position="end">
                              {keyword && (
                                <IconButton
                                  aria-label="toggle password visibility"
                                  type="submit"
                                >
                                  <SearchIcon style={{ color: "#1972d6" }} />
                                </IconButton>
                              )}
                            </InputAdornment>
                          }
                          label={t("SEARCH_BY_TITLE")}
                        />
                      </FormControl>

                      {/* <FormControl
                        sx={{ m: 1, width: "57%" }}
                        variant="standard"
                      >
                        <InputLabel htmlFor="standard-adornment-password">
                          {t("SEARCH_BY_TITLE")}
                        </InputLabel>
                        <Input
                          id="standard-adornment-password"
                          type="text"
                          name="title"
                          {...register("title", {})}
                          inputProps={{ maxLength: 50 }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                type="submit"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl> */}
                    </Form>
                  )}

                  {params.get("keyword") || params.get("page") ? (
                    <>
                      {!LOADING && GET_POST_DATA.length !== 0 && (
                        <div className="add_new_btn">
                          <Tooltip title={t("RESET_ALL")}>
                            <IconButton
                              onClick={() => resetPage()}
                              style={{ backgroundColor: "#46BADF" }}
                            >
                              <ClearIcon style={{ color: "#ffffff" }} />
                            </IconButton>
                          </Tooltip>
                        </div>
                      )}
                    </>
                  ) : null}

                  <div className="add_new_btn">
                    <Button
                      className="theme_btn mt-1"
                      onClick={() => history.push("/create-post")}
                    >
                      {t("ADD_NEW_POST")}
                    </Button>
                  </div>
                </div>

                <div
                  style={{
                    padding: "",
                    height: "1338px",
                    overflow: "auto",
                  }}
                  className="px-3"
                >
                  {LOADING ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <LottiLoader loader={true} height={150} width={150} />
                    </div>
                  ) : (
                    <>
                      {!LOADING && GET_POST_DATA.length === 0 && (
                        <Row>
                          {/* <div className="order_detail_box"> */}
                          <LottiLoader
                            message="No Post Found"
                            height={250}
                            width={250}
                          />
                          {/* </div> */}
                        </Row>
                      )}

                      <Row>
                        {GET_POST_DATA &&
                          GET_POST_DATA.length !== 0 &&
                          GET_POST_DATA.map((item) => {
                            return (
                              <Col md={6} sm={12} key={item?._id}>
                                <div
                                  className="order_detail_box"
                                  style={{ height: "210px" }}
                                >
                                  {!item?.sold && (
                                    <>
                                      <div className="order_detail_head">
                                        <div className="non-semantic-protector">
                                          <h5 className="ribbon">
                                            <div
                                              className="ribbon-content "
                                              style={{
                                                fontWeight: "100",
                                                fontSize: "14px",
                                              }}
                                            >
                                              {t("BOOST_DURATION")} :{" "}
                                              {item?.day} {t("DAYS_LEFT")}
                                            </div>
                                          </h5>
                                        </div>

                                        <Dropdown>
                                          <Dropdown.Toggle
                                            variant="success"
                                            id="dropdown-basic"
                                            style={{
                                              backgroundColor: "transparent",
                                              border: "none",
                                            }}
                                          >
                                            <img
                                              src="../assets/images/toggle_icon1.svg"
                                              alt=""
                                              style={{ width: "6px" }}
                                            />
                                          </Dropdown.Toggle>

                                          <Dropdown.Menu>
                                            <Dropdown.Item
                                              href="#"
                                              onClick={() =>
                                                fetchEditPostData(item?._id)
                                              }
                                            >
                                              {t("EDIT")}
                                            </Dropdown.Item>
                                            {/* <Dropdown.Item
                                            href="#"
                                            onClick={() =>
                                              hendelDeletePost(item?._id)
                                            }
                                          >
                                            {t("DELETE")}{" "}
                                          </Dropdown.Item> */}
                                            <Dropdown.Item
                                              href="#"
                                              onClick={() =>
                                                handelArchivePost(item?._id)
                                              }
                                            >
                                              {t("ARCHIVE")}
                                            </Dropdown.Item>
                                          </Dropdown.Menu>
                                        </Dropdown>
                                      </div>
                                    </>
                                  )}

                                  <div
                                    className="order_caption"
                                    onClick={() => {
                                      if (!item.sold) {
                                        history.push("/product/" + item?._id);
                                      }
                                    }}
                                    style={
                                      item.sold
                                        ? { cursor: "default" }
                                        : { cursor: "pointer" }
                                    }
                                  >
                                    <div className="manage-row">
                                      <div
                                        className="manage"
                                        style={{ position: "relative" }}
                                      >
                                        <figure>
                                          <img
                                            src={item?.image}
                                            alt="No_Img"
                                            className="manage-post-img"
                                          />
                                        </figure>
                                        {item?.sold && (
                                          <p
                                            style={{
                                              backgroundColor:
                                                "rgb(210 163 32 / 60%)",
                                              textAlign: "center",
                                              color: "#ffffff",
                                              fontWeight: "bold",
                                              width: "90px",
                                              borderRadius: "8px",
                                              position: "absolute",
                                              top: "5px",
                                              left: "19px",
                                              border:
                                                "1px solid rgb(210 163 32 / 60%)",
                                            }}
                                          >
                                            {t("SOLD")}
                                          </p>
                                        )}
                                      </div>

                                      <div className="manage-column">
                                        <div className="manage-post-heading">
                                          <p
                                            style={{
                                              fontWeight: "bold",
                                              margin: "0px",
                                            }}
                                            className="manage-pl"
                                          >
                                            {textTruncate(item?.title)}{" "}
                                          </p>
                                          <p>
                                            {/* <strong>{t("OFFER")}:</strong>{" "} */}
                                            {item?.offer}
                                          </p>{" "}
                                          {item?.boost && (
                                            <p>
                                              {/* <strong>{t("BOOST")}:</strong>{" "} */}
                                              <span
                                                className="boost_badge"
                                                style={{ padding: "5px" }}
                                              >
                                                {t("DOFFO_BOOST")}
                                              </span>
                                            </p>
                                          )}
                                          <p>
                                            {" "}
                                            <span className="eye_icon">
                                              <i className="fas fa-eye"></i>
                                              {item?.view} {t("VIEW")}
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                            );
                          })}
                      </Row>
                    </>
                  )}
                </div>
              </div>
              {!LOADING && GET_POST_DATA.length !== 0 && (
                <>
                  <Divider />
                  <div
                    className="d-flex justify-content-between "
                    style={{ padding: "20px 24px 13px" }}
                  >
                    <p>
                      <strong>{t("TOTAL_ITEM")}</strong> :{" "}
                      <span>{POST_LIST_PAGINATION?.total}</span>
                    </p>
                    <Pagination
                      page={page}
                      count={POST_LIST_PAGINATION?.total_pages}
                      color="primary"
                      variant="outlined"
                      onChange={(e, value) => pagination(e, value)}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </Container>
      </section>

      {/* manage_address */}

      {/* manage_address */}
    </>
  );
}

export default ManagePostList;
