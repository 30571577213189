import React from "react";
const Rating = (props) => {
    const { rating } = props;
    var stars = [];
   const repeatstr = (ch, n) => {
        var result = "";
        while (n-- > 0)
          result += ch;
        return result;
      }
    for (var i = 0; i < 5; i++) {
        var iconClass = 'fas fa-star';
        if (rating > i && rating != null) {
            iconClass = iconClass + repeatstr(" ", 1) + 'active_icon';
        }
        stars.push(
            <span key={i}><i className={iconClass}></i></span>
        );
    }
    return (
        <>
            {stars}
        </>
    );
};

export default Rating;