import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function UpdateUserLanguage(
  state = initialState.language,
  action
) {
  switch (action.type) {
    case types.UPDATE_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    default:
      return state;
  }
}
