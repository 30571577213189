import * as types from "../actions/actionsTypes";
import initialState from "./initialState";

export default function getConditionReducer(
  state = initialState.getConditionData,
  action
) {
  switch (action.type) {
    case types.GET_CONDITION:
      return action.getConditionData;
    default:
      return state;
  }
}
