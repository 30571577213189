import React, { Fragment, lazy, useEffect, useState } from "react";
import "./MarkAsSold.css";
import {
  Form,
  Button,
  Modal,
  Tooltip,
  Overlay,
  Row,
  Col,
} from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import profileImage from "../../../assets/images/profile.png";
import { Divider, Radio } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getUserSoldList, handelMarkAsSold } from "../../../actions/UserAction";
import LottiLoader from "../../../Component/lottiAnimation/LottiLoader";
import { loadDialog, loadToasterData } from "../../../actions/baseActions";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { crose } from "../../../assets/images/index";
const Dialogs = lazy(() => import("../../../Component/Dialog"));

function MarkAsSoldModal(props) {
  const { show, onHide, markAsSoldData } = props;
  const dispatch = useDispatch();

  // All Selectors
  const { GET_SOLD_LIST, LOADING, dialog } = useSelector((state) => ({
    GET_SOLD_LIST: state.userReducer?.userSoldList,
    LOADING: state.userReducer?.userSoldListLoader,
    dialog: state.dialog,
  }));

  // All State
  const [selectedRadio, setSelectedRadio] = useState(false);
  const [radioText, setRadioText] = useState("");

  const handelChange = (event, text) => {
    setSelectedRadio(event.target.checked);
    setRadioText(text);
  };

  useEffect(() => {
    const data = { post_id: markAsSoldData?._id };
    const fetchData = () => {
      dispatch(getUserSoldList(data));
    };
    if (markAsSoldData?._id) {
      fetchData();
    }
  }, []);

  const closeModal = () => {
    onHide();
  };

  const handelSubmit = () => {
    if (!radioText) {
      dispatch(loadToasterData("Please select a buyer", "error", true));
    } else {
      handleClickSubmit();
    }
  };

  const handleClickSubmit = () => {
    dispatch(
      loadDialog({
        open: true,
        message: "Are you sure you want to mark as sold",
        title: "Mark as sold " + markAsSoldData?.title,
      })
    );
  };

  const handleActionSubmit = () => {
    dispatch(
      loadDialog({
        open: false,
        message: "Are you sure you want to mark as sold",
        title: "Mark as sold " + markAsSoldData?.title,
      })
    );
    const data = { post_id: markAsSoldData?._id, type: radioText };
    if (markAsSoldData?._id) {
      dispatch(handelMarkAsSold(data, closeModal));
    }
  };
  const handleCloseToogleSold = (data) => {
    props.handleCloseToogleSold();
  };
  return (
    <div>
      <Dialog
        open={show}
        onClose={handleCloseToogleSold}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className="with-crose">
          {" "}
          {markAsSoldData?.title}{" "}
          <span style={{ fontWeight: "100", fontSize: "16px" }}>
            (Mark As Sold)
          </span>{" "}
          <button className="crose_btn" onClick={handleCloseToogleSold}>
            <img src={crose} alt="img" />
          </button>
        </DialogTitle>

        <DialogContent className="pt-2">
          <div className="change_password_main">
            <section className="create_post_sec">
              <div className="post_form" noValidate autoComplete="off">
                <Row>
                  <Col lg={12} md={12}>
                    <div className="master_radio bg-light p-md-3 p-2 mb-3 d-flex justify-content-between align-items-center">
                      <p className="fw-bold mb-0 fs-6">Sold Somewhere Else</p>
                      <Radio
                        checked={
                          radioText == "OUTSIDE" && selectedRadio == true
                            ? 1
                            : 0
                        }
                        onChange={(e) => handelChange(e, "OUTSIDE")}
                        value={selectedRadio}
                        name="radio-buttons-outside"
                        inputProps={{ "aria-label": "A" }}
                        style={{ color: "#33a7cc" }}
                      />
                    </div>
                  </Col>
                  {LOADING ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <LottiLoader loader={true} height={80} width={80} />
                    </div>
                  ) : (
                    <Fragment>
                      {GET_SOLD_LIST?._id && (
                        <>
                          <Col lg={12} md={12}>
                            <div className="condition_label mb-1 d-flex justify-content-between align-items-center">
                              <p className="fw-bold mb-0">Or Select Buyer</p>
                            </div>
                          </Col>
                          <Col lg={12} md={12}>
                            <div className="p-3 border rounded d-flex align-items-center mb-3">
                              <span className="d-flex justify-content-start align-items-center">
                                <img
                                  src={
                                    GET_SOLD_LIST?.image
                                      ? GET_SOLD_LIST?.image
                                      : profileImage
                                  }
                                  alt=""
                                  className="mark-sold-image"
                                />
                                <span className="inner-span-content">
                                  <p className="fw-bold mb-0">
                                    {GET_SOLD_LIST?.first_name}{" "}
                                    {GET_SOLD_LIST?.last_name}
                                  </p>
                                  <p
                                    className=" fw-bold mb-0"
                                    style={{
                                      fontSize: "14px",
                                      color: "#46BADF",
                                    }}
                                  >
                                    {GET_SOLD_LIST?.amount + " SR"}
                                  </p>
                                </span>
                              </span>

                              <label className="radio_btn ms-auto">
                                <Radio
                                  onChange={(e) => handelChange(e, "USER")}
                                  value={selectedRadio}
                                  name="radio-buttons"
                                  inputProps={{ "aria-label": "A" }}
                                  style={{ color: "#33a7cc" }}
                                  checked={
                                    radioText == "USER" && selectedRadio == true
                                      ? 1
                                      : 0
                                  }
                                />
                              </label>
                            </div>
                          </Col>{" "}
                        </>
                      )}
                    </Fragment>
                  )}

                  <Col
                    md={12}
                    className="d-flex justify-content-center mb-3 mt-5"
                  >
                    <Button
                      className="theme_btn"
                      style={{ width: "100%", borderRadius: "4px" }}
                      onClick={() => handelSubmit()}
                    >
                      Save
                      {/* <i
                        className="fa fa-spinner fa-spin"
                        style={{
                          color: "#fff",
                          fontSize: "14px",
                          marginLeft: "1px",
                        }}
                      /> */}
                    </Button>
                  </Col>
                </Row>
              </div>
            </section>
          </div>
        </DialogContent>
      </Dialog>

      {dialog.open && <Dialogs action={handleActionSubmit} />}
    </div>
  );
}

export default MarkAsSoldModal;
