import React, { Fragment, lazy, useEffect, useMemo } from "react";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loadVerifyEmailData } from "../../actions/homePageActions";
const Header = lazy(() => import("../../Component/Loader"));
function useQuery() {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}
const Home = () => {
  let { token } = useParams();
  const dispatch = useDispatch();
  let { push } = useHistory();
  let query = useQuery();
  useEffect(() => {
    const fetchData = () => {
      dispatch(loadVerifyEmailData(token, query.get("type"), push));
    };
    fetchData();
  }, [dispatch, token, push, query]);
  return (
    <Fragment>
      <Header />
    </Fragment>
  );
};

export default Home;
