import * as types from "./actionsTypes";
import submittingRequestStatus from "./submittingRequestStatusAction";
import toggleNetworkRequestStatus from "./toggleNetworkRequestStatus";
import * as API from "../utils/apiPath";
import { agent } from "../utils/agent";
import toasterStatusAction from "./toasterStatusAction";

import {
  loadLoginPop,
  loadRegisterPop,
  loadForgetPop,
  loadFormPop,
  loadRegisterVerify,
  loadToasterData,
  loadSocialParamsPop,
} from "./baseActions";

export function ParamsDataSuccess(userParams) {
  return { type: types.LOADED_USER_PARAM_SUCCESS, userParams };
}

export function ChangeUserPasswordSuccess(changePassInfo) {
  return { type: types.CHANGE_USER_PASSWORD, changePassInfo };
}

export function loginDataSuccess(userInfo) {
  return { type: types.LOADED_USER_INFO_SUCCESS, userInfo };
}

export function loadUserAuth(isAuth) {
  return { type: types.LOADED_USER_AUTH_SUCCESS, isAuth };
}

const WALLET_LIST = (data) => {
  return {
    type: types.LOAD_USER_WALLET_DATA_SUCCESS,
    payload: data,
  };
};

const WALLET_LOADING = (data) => {
  return {
    type: types.WALLET_LOADING,
    payload: data,
  };
};

const WALLET_PAGINATION = (data) => {
  return {
    type: types.WALLET_PAGINATION,
    payload: data,
  };
};

const WALLET__LOAD_MORE_LOADING = (data) => {
  return {
    type: types.WALLET_LOAD_MORE_LOADING,
    payload: data,
  };
};

const WALLET__LOAD_TOTAL_AMOUNT = (data) => {
  return {
    type: types.LOAD_USER_WALLET_TOTAL_AMOUNT_SUCCESS,
    payload: data,
  };
};

export {
  WALLET_LIST,
  WALLET__LOAD_TOTAL_AMOUNT,
  WALLET_LOADING,
  WALLET_PAGINATION,
  WALLET__LOAD_MORE_LOADING,
};

export function userWallet(data) {
  return async (dispatch) => {
    dispatch(WALLET_LOADING(true));
    await agent
      .get(API.LOAD_WALLET, data)
      .then((response) => {
        if (response.status === 200) {
          dispatch(WALLET_LIST(response?.data?.data?.data));
          dispatch(
            WALLET__LOAD_TOTAL_AMOUNT(response?.data?.data?.totalAmount)
          );
          dispatch(WALLET_PAGINATION(response?.data?.data?.pagination));
          dispatch(WALLET_LOADING(false));
          dispatch(WALLET__LOAD_MORE_LOADING(false));
        }
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
        dispatch(WALLET_LOADING(false));
      });
  };
}

export const userCashOut = (params, cash2) => async (dispatch) => {
  try {
    dispatch(submittingRequestStatus(true));
    const response = await agent.put(API.LOAD_CASHOUT, params);
    dispatch(submittingRequestStatus(false));
    dispatch(loadToasterData(response.data.message, "success", true));
    cash2();
  } catch (error) {
    dispatch(submittingRequestStatus(false));
    dispatch(loadToasterData(error.message, "error", true));
  }
};

export const userAutomaticallyCashOut = (params) => async (dispatch) => {
  try {
    dispatch(submittingRequestStatus(true));
    const response = await agent.put(API.AUTOMATICALLY_BANK_ACCOUNT, params);
    dispatch(submittingRequestStatus(false));
    dispatch(loadToasterData(response.data.message, "success", true));
  } catch (error) {
    dispatch(submittingRequestStatus(false));
    dispatch(loadToasterData(error.message, "error", true));
  }
};

export function userLoginData(params) {
  return async function (dispatch) {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.LOGIN, params)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
        if (response.status === 201) {
          dispatch(loadToasterData(response.data.message, "error", true));
          if (response.data.data.mobile_number) {
            dispatch(ParamsDataSuccess(response.data.data));
            dispatch(loadLoginPop(false));
            dispatch(loadRegisterVerify(true));
          }
        } else {
          dispatch(
            toasterStatusAction({ open: true, message: response.data.message })
          );
          dispatch(loginDataSuccess(response.data.data));
          dispatch(loadUserAuth(true));
          localStorage.setItem("userToken", response.data.data.api_token);
          if (params.remember_me) {
            dispatch(ParamsDataSuccess(params));
          } else {
            dispatch(ParamsDataSuccess({}));
          }
          dispatch(loadLoginPop(false));
        }
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
        dispatch(submittingRequestStatus(false));
      });
  };
}

export function userLogout() {
  return (dispatch) => {
    dispatch(loadUserAuth(false));
    dispatch(loginDataSuccess({}));
    localStorage.removeItem("userToken");
  };
}

export const userRegisterData = (params) => async (dispatch) => {
  try {
    dispatch(submittingRequestStatus(true));
    const response = await agent.post(API.Register, params);
    dispatch(
      toasterStatusAction({ open: true, message: response.data.message })
    );
    dispatch(submittingRequestStatus(false));
    dispatch(
      ParamsDataSuccess({
        mobile_number: params.mobile_number,
        type: "REGISTER",
        id: response.data.data.id,
      })
    );
    loadRegisterPop(false)(dispatch);
    loadRegisterVerify(true)(dispatch);
  } catch (error) {
    dispatch(loadToasterData(error.message, "error", true));
    dispatch(submittingRequestStatus(false));
  }
};
export function forgotPassword(params) {
  return async (dispatch) => {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.FORGOT_PASSWORD, params)
      .then((response) => {
        dispatch(
          toasterStatusAction({ open: true, message: response.data.message })
        );
        dispatch(submittingRequestStatus(false));
        dispatch(ParamsDataSuccess(params));
        loadForgetPop(false)(dispatch);
        loadFormPop(true)(dispatch);
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
        dispatch(submittingRequestStatus(false));
      });
  };
}

export function ChangeUserPassword(params) {
  return async (dispatch) => {
    dispatch(submittingRequestStatus(true));
    await agent
      .put(API.CHANGE_PASSWORD, params)
      .then((response) => {
        dispatch(
          toasterStatusAction({ open: true, message: response.data.message })
        );
        dispatch(submittingRequestStatus(false));
        dispatch(ChangeUserPasswordSuccess(params));
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
        dispatch(submittingRequestStatus(false));
      });
  };
}

export function resendOtpPassword(params) {
  return async (dispatch) => {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.FORGOT_PASSWORD, params)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
        dispatch(
          toasterStatusAction({ open: true, message: response.data.message })
        );
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        dispatch(loadToasterData(error.message, "error", true));
      });
  };
}

export function resetPassword(params) {
  return async (dispatch) => {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.RESET_PASSWORD, params)
      .then((response) => {
        dispatch(
          toasterStatusAction({ open: true, message: response.data.message })
        );
        dispatch(submittingRequestStatus(false));
        dispatch(loginDataSuccess({}));
        loadFormPop(false)(dispatch);
      })
      .catch((error) => {
        dispatch(loadToasterData(error.message, "error", true));
        dispatch(submittingRequestStatus(false));
      });
  };
}

export const userSocialLoginData = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    const checkUser = await agent.post(API.CHECK_USER, params);
    if (checkUser.data.data.isUser) {
      const response = await agent.post(API.SOCIAL_LOGIN, params);
      dispatch(toggleNetworkRequestStatus(false));
      if (response.data.status === 200) {
        dispatch(submittingRequestStatus(false));
        dispatch(loginDataSuccess(response.data.data));
        dispatch(loadUserAuth(true));
        localStorage.setItem("userToken", response.data.data.api_token);
        dispatch({
          type: loadToasterData,
        });
        loadLoginPop(false)(dispatch);
        loadRegisterPop(false)(dispatch);
        loadForgetPop(false)(dispatch);
        loadFormPop(false)(dispatch);
      }
    } else {
      dispatch(ParamsDataSuccess(params));
      dispatch(loadSocialParamsPop(true));
      dispatch(toggleNetworkRequestStatus(false));
    }
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(loadToasterData(error.message, "error", true));
  }
};

export const updateProfileData = (params) => async (dispatch) => {
  try {
    dispatch(submittingRequestStatus(true));
    const response = await agent.post(API.UPDATE_PROFILE, params);
    dispatch(submittingRequestStatus(false));
    dispatch(loadToasterData(response.data.message, "success", true));
    dispatch(loginDataSuccess(response.data.data));
  } catch (error) {
    dispatch(submittingRequestStatus(false));
    dispatch(loadToasterData(error.message, "error", true));
  }
};

export function resendOtpRegister(params) {
  return async (dispatch) => {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.RESEND_OTP_MOBILE, params)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
        dispatch(
          toasterStatusAction({ open: true, message: response.data.message })
        );
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        dispatch(loadToasterData(error.message, "error", true));
      });
  };
}

export function verifyOtpMobile(params) {
  return async (dispatch) => {
    dispatch(submittingRequestStatus(true));
    await agent
      .post(API.OTP_VERIFY_MOBILE, params)
      .then((response) => {
        dispatch(submittingRequestStatus(false));
        dispatch(
          toasterStatusAction({ open: true, message: response.data.message })
        );

        dispatch(loginDataSuccess(response.data.data));
        dispatch(loadUserAuth(true));
        localStorage.setItem("userToken", response.data.data.api_token);
        dispatch(loadRegisterVerify(false));
        dispatch(ParamsDataSuccess({}));
      })
      .catch((error) => {
        dispatch(submittingRequestStatus(false));
        dispatch(loadToasterData(error.message, "error", true));
      });
  };
}
export const facebookConnectAction = (request) => async (dispatch) => {
  try {
    const response = await agent.get(API.FACEBOOK_CONNECT, request);
    dispatch(submittingRequestStatus(false));
    dispatch(loginDataSuccess(response.data.data));
    dispatch(loadUserAuth(true));
    localStorage.setItem("userToken", response.data.data.api_token);
    if (request.remember_me) {
      dispatch(ParamsDataSuccess(request));
    } else {
      dispatch(ParamsDataSuccess({}));
    }
    dispatch(loadToasterData(response.data.message, "success", true));
  } catch (error) {
    dispatch(submittingRequestStatus(false));
    dispatch(loadToasterData(error.message, "error", true));
  }
};

export const userAccountSocialLoginData = (params) => async (dispatch) => {
  try {
    dispatch(toggleNetworkRequestStatus(true));
    await agent.post(API.SOCIAL_LOGIN, params);
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(ParamsDataSuccess({ mobile_number: params.mobile_number }));
    loadRegisterVerify(true)(dispatch);
    loadSocialParamsPop(false)(dispatch);
    loadLoginPop(false)(dispatch);
  } catch (error) {
    dispatch(toggleNetworkRequestStatus(false));
    dispatch(loadToasterData(error.message, "error", true));
  }
};
