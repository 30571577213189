import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  InfoWindow,
  Marker,
} from "react-google-maps";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React, { useEffect, useState, ScriptTag } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { loadDialog, loadToasterData } from "../../actions/baseActions";
import SubmitButton from "../../Component/Button";
import config from "../../config/index";
import { useTranslation } from "react-i18next";
const apiKey = "";
const apiUrl =
  "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAbf5BAe6gnaQ30XpKkKCry50bsu8_G5ko";
function ChooseLocationModal(props) {
  const { show, onHide } = props;
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const handleClose = (action) => {
    props.onHide(action);
  };
  const {
    register: register4,
    errors: errors4,
    handleSubmit: handleSubmit4,
    setValue: setValue4,
  } = useForm({});
  /* Map Code */
  const [address, setAddress] = useState();
  console.log(address);
  const [addressObj, setAddressObj] = useState("");
  const [gelatlang, setlatlang] = useState();
  const [showMap, setShowMap] = useState(false);

  const onSubmit4 = (data) => {
    if (addressObj) {
      localStorage.setItem(
        "userLat",
        gelatlang.results[0].geometry.location.lat
      );
      localStorage.setItem(
        "userLang",
        gelatlang.results[0].geometry.location.lng
      );
      if (addressObj && addressObj.city !== " ") {
        localStorage.setItem("userCity", addressObj.city);
      } else {
        localStorage.setItem("userCity", "Dubai");
      }
      window.location.reload();
    } else {
      dispatch(loadToasterData(t("PLEASE_SELECT_LOCATION"), "error", true));
    }
  };
  const getAddressObject = (address_components) => {
    // console.log("comp",address_components)
    const ShouldBeComponent = {
      street_number: ["street_number"],
      postal_code: ["postal_code"],
      street: ["street_address", "route"],
      province: [
        "administrative_area_level_1",
        "administrative_area_level_2",
        "administrative_area_level_3",
        "administrative_area_level_4",
        "administrative_area_level_5",
      ],
      city: [
        "locality",
        "sublocality",
        "sublocality_level_1",
        "sublocality_level_2",
        "sublocality_level_3",
        "sublocality_level_4",
      ],
      country: ["country"],
      landmark: ["landmark"],
      place: ["political", "sublocality", "sublocality_level_1"],
    };

    let address = {
      street_number: "",
      postal_code: "",
      street: "",
      province: "",
      city: "",
      country: "",
      landmark: "",
      place: "",
    };

    address_components.forEach((component) => {
      for (var shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === "country") {
            address[shouldBe] = component.long_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    // Fix the shape to match our schema
    address.address = address.street_number + " " + address.street;
    delete address.street_number;
    delete address.street;
    if (address.country === "US") {
      address.state = address.province;
      delete address.province;
    }
    return address;
  };

  useEffect(() => {
    const func = async () => {
      var addressSelected = "";
      if (address && address !== null) {
        addressSelected = address && address.value.description;
      } else {
        addressSelected =
          "Roop Vihar Colony, 6/78, Hari Marg, Near Kardhani Shopping Center, Block-B, Malviya Nagar, Jaipur, Rajasthan 302017, India";
      }

      const api_url =
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        addressSelected +
        "&key=AIzaSyAbf5BAe6gnaQ30XpKkKCry50bsu8_G5ko&sensor=false";

      // Defining async function
      async function getapi(url) {
        const response = await fetch(url);
        var data = await response.json();
        //console.log("dataset",data);
        setlatlang(data);
      }
      getapi(api_url);

      const geocodeObj =
        address &&
        address.value &&
        (await geocodeByPlaceId(address.value.place_id));
      const addressObject =
        geocodeObj && getAddressObject(geocodeObj[0].address_components);
      console.log("addressObject", geocodeObj);
      setAddressObj(addressObject);

      setShowMap(true);
    };
    func();
  }, [address]);

  /* Google Map */
  //console.log("LatLong", gelatlang);
  const MyMapComponent = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{
          lat: gelatlang.results[0].geometry.location.lat,
          lng: gelatlang.results[0].geometry.location.lng,
        }}
      >
        {props.isMarkerShown && (
          <Marker
            position={{
              lat: gelatlang.results[0].geometry.location.lat,
              lng: gelatlang.results[0].geometry.location.lng,
            }}
          />
        )}
      </GoogleMap>
    ))
  );

  /* Default */
  const MyMapComponentDefault = withScriptjs(
    withGoogleMap((props) => (
      <GoogleMap
        defaultZoom={15}
        defaultCenter={{
          lat: 26.8634705,
          lng: 75.81852,
        }}
      >
        {props.isMarkerShown && (
          <Marker
            position={{
              lat: 26.8634705,
              lng: 75.81852,
            }}
          />
        )}
      </GoogleMap>
    ))
  );
  /* Default */

  //console.log("adobj",addressObj);

  /* Map Code */
  return (
    <div className="location-modal">
      <Row>
        <div>
          <Dialog
            open={show}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            className="address_box"
          >
            <DialogTitle id="form-dialog-title">
              {t("SELECT_ADDRESS")}
            </DialogTitle>
            <Form
              onSubmit={handleSubmit4(onSubmit4)}
              noValidate
              autoComplete="off"
            >
              <DialogContent>
                <DialogContentText></DialogContentText>
                <div className="addressmap">
                  <GooglePlacesAutocomplete
                    apiKey="AIzaSyAbf5BAe6gnaQ30XpKkKCry50bsu8_G5ko"
                    // onClick={geoCodeLocation}

                    selectProps={{
                      isClearable: true,
                      value: address,
                      onChange: (val) => {
                        setAddress(val);
                      },
                    }}
                  />
                  <br />
                  {addressObj ? (
                    <MyMapComponent
                      isMarkerShown
                      googleMapURL={apiUrl}
                      loadingElement={<div style={{ height: `100%` }} />}
                      containerElement={<div style={{ height: `300px` }} />}
                      mapElement={<div style={{ height: `100%` }} />}
                    />
                  ) : (
                    <h3>{t("CHOOSE_ADDRESS")}</h3>
                  )}
                </div>
              </DialogContent>
              <DialogActions>
                <SubmitButton title={t("SUBMIT")} />

                <Button
                  onClick={handleClose}
                  className="cancel-button theme_btn"
                  variant="outlined"
                  color="secondary"
                >
                  {t("CANCEL")}
                </Button>
              </DialogActions>
            </Form>
          </Dialog>
        </div>
      </Row>
    </div>
  );
}

export default ChooseLocationModal;
