export const textTruncate = (string) => {
  if (string.length > 20) return string.substring(0, 20) + "...";
  else return string;
};

export const textTruncateMore = (string) => {
  if (string?.length > 37) return string.substring(0, 37) + "...";
  else return string;
};

export const textTruncateDescription = (string) => {
  if (string?.length > 45) return string.substring(0, 45) + "...";
  else return string;
};

export const textTruncateReport = (string) => {
  if (string?.length > 120) return string.substring(0, 120) + "...";
  else return string;
};
export const textTruncateAny = (string, length) => {
  if (string?.length > length) return string.substring(0, length) + "...";
  else return string;
};
